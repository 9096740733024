import "./matricula.css";
import { useState } from "react";
import Axios from "axios";
import NavBar from "../../components/NavBar";

function Matricula() {
  const [name, setName] = useState("");
  const [age, setAge] = useState(0);
  const [country, setCountry] = useState("");
  const [position, setPosition] = useState("");
  const [wage, setWage] = useState(0);

  const [newWage, setNewWage] = useState(0);

  const [employeeList, setEmployeeList] = useState([]);

  const addEmployee = () => {
  
      setEmployeeList([
        ...employeeList,
        {
          name: name,
          age: age,
          country: country,
          position: position,
          wage: wage,
        },
      ]);
 
  };

  const getEmployees = () => {
   
  };

  const updateEmployeeWage = (id) => {
    
        setEmployeeList(
          employeeList.map((val) => {
            return val.id == id
              ? {
                  id: val.id,
                  name: val.name,
                  country: val.country,
                  age: val.age,
                  position: val.position,
                  wage: newWage,
                }
              : val;
          })
        );
 
  };

  const deleteEmployee = (id) => {
  
      setEmployeeList(
        employeeList.filter((val) => {
          return val.id != id;
        })
      );
   
  };

  return (
    <div className="App">
      <NavBar navegando="MATRICULA"/>
      <div className="principal-aluno">
        <label>NOME: </label>
        <input
          type="text"
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <label>LOGIN
          :</label>
        <input
          type="text"
          onChange={(event) => {
            setAge(event.target.value);
          }}
        />
        <label>Country:</label>
        <input
          type="text"
          onChange={(event) => {
            setCountry(event.target.value);
          }}
        />
        <label>Position:</label>
        <input
          type="text"
          onChange={(event) => {
            setPosition(event.target.value);
          }}
        />
        <label>Wage (year):</label>
        <input
          type="number"
          onChange={(event) => {
            setWage(event.target.value);
          }}
        />
        <button onClick={addEmployee}>Add Employee</button>
      </div>
      <div className="employees">
      <button onClick={getEmployees}>Listar</button>


      </div>
    </div>
  );
}

export default Matricula;
