import React, { useEffect, useState } from 'react'
import "./index.css";
import fundo from '../components/imagens';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/nav';


const Inicio = () => {
    const navegar = useNavigate();
    const [info, setInfo] = useState([]);

    const Carregar = () => {

        let ini = localStorage.getItem('config');
      
        if (ini) {
           setInfo(JSON.parse(ini));
        }


    }

    useEffect(() => {
        Carregar();
        // console.log(info);

    }, []);
    return (
        <Container fluid className='img-fundo' style={{ backgroundImage: `url(${fundo.fundo})` }}>
            <NavBar navegando={"INICIO"} />
            <Row className='div-row'>
                <Col>
                    <div className='div-logo'>
                        <label className='label-texto-secretaria'>SECRETARIA ADB AMPARO</label>
                        <label className='label-texto'>ADB {info?.setor_nome?.toUpperCase()}</label>
                        <label className='label-texto'>{info?.congregacao_nome?.toUpperCase()}</label>
                        <img
                            src={fundo.logo}
                            alt="Logo"
                            className="logo-image"
                        />
                    </div>
                </Col>
                <Col>
                    <div className='div-button'>
                        {/* <button  className='btns btn-azul' onClick={()=>navegar("/membro/importar")} >
                 MEMBRO
                </button> */}
                  <button className='btns btn-laranja' onClick={() => navegar("/secretaria/calendario")}>
                            CARTERINHA
                        </button>
                        <button className='btns btn-azul' onClick={() => navegar("/secretaria/membro/cadastro")} >
                            CADASTRO
                        </button>
                        <button className='btns btn-verde' onClick={() => navegar("/batismo/cadastro")}>
                            BATISMO
                        </button >
                        {/* <button  className='btns btn-rosa' onClick={()=>navegar("/")}>
                 IGREJAS
                </button> */}
                        <button className='btns btn-laranja' onClick={() => navegar("/secretaria/calendario")}>
                            AGENDA
                        </button>
                      
                        {/* <button  className='btns btn-laranja' onClick={()=>navegar("/membro/chamada")}>
                 CHAMADA
                </button> */}
                        <button className='btns btn-gelo' onClick={() => navegar("/")}>
                            SAIR
                        </button>
                    </div>
                </Col>

            </Row>

        </Container>
    );
};


export default Inicio;