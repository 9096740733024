import 'bootstrap/dist/css/bootstrap.min.css'
import { createBrowserRouter } from 'react-router-dom';
import Home from '../pages/Home'

import Admin from '../pages/Administrador'
import Error from '../pages/Error'
import Upload from '../pages/Upload'
import CadastroMembro from '../pages/Cadastro/Membro'
import Private from '../routes/Private'
import Inicio from '../pages/Inicio'
import Igreja from '../pages/Cadastro/Igreja'
import Culto from '../pages/Culto'
import { Imprimir } from '../components/Imprimir';
import Administrador from '../pages/Administrador';
import Despesa from '../pages/Despesa';
import Dizimista from '../pages/Dizimista';
import Setor from '../pages/Cadastro/Setor';
import Teste from '../pages/Teste';
import TesourariaMensal from '../pages/Relatorios/TesourariaMensal';
import Professor from '../ebd/cadastro/professor';
import RelatorioGeral from '../pages/Relatorio/Geral';
import AtRelatorioGeral from '../pages/Alterar/RelatorioGeral';
import RelatorioGeralSetor from '../pages/Relatorio/Setor';
import RelatorioGeralCongregacao from '../pages/Relatorio/Congregacao';
import Licao from '../ebd/cadastro/licao';
import Aluno from '../ebd/cadastro/aluno';
import Matricula from '../ebd/matricula';
import RelatorioSetorial from '../pages/Relatorio/Setorial';
import RelatorioDizimistaMesal from '../pages/Relatorio/Dizimista';
import RelatorioSetorialCongregacao from '../pages/Relatorio/Congregacao/setorial';
import RelatorioTalao from '../pages/Relatorio/Talao';
import RelatorioGeralDizimistaMesal from '../pages/Relatorio/Dizimista/geral';
import DizimistaGeral from '../pages/Alterar/DizimistaGeral';
import RelatorioGeral2 from '../pages/Relatorio/Geral/geral';
import TesourariaMensal2 from '../pages/Relatorio/Geral/mensal';

import CadBatismo from '../batismo/pre_card';
import LoginBatismo from '../batismo/login';
import RelatorioBatimo from '../batismo/relatorio';
import SaldoSede from '../pages/Culto/saldo';
import TalaoLancado from '../pages/Relatorio/Geral/talao_lancado';
import Dizimista2 from '../Tesouraria/dizimista'
export const routesTesouraria = [
  {
    path:'/tesouraria/dizimista',
    element:<Dizimista2/>
  },
  {
    path:'/relatorio/geral/talao_lancado',
    element:<TalaoLancado/>
  },
  {
    path:'/tesouraria/culto/saldo/sede',
    element:<SaldoSede/>
  },
  {
    path:'/batismo/relatorio',
    element:<RelatorioBatimo/>
  },
  {
    path:'/batismo/login',
    element:<LoginBatismo/>
  },
  {
    path:'/batismo/cadastro',
    element:<CadBatismo/>
  },
 
  {
    path:'/geral/relatorio_mensal',
    element:<TesourariaMensal2/>
  },
  {
    path:'/relatorio/relatorio_geral2',
    element:<RelatorioGeral2/>
  },
  {
    path:'/alterar/relatorio_geral',
    element:<AtRelatorioGeral/>
  },
  {
    path:'/alterar/dizimista_geral',
    element:<DizimistaGeral/>
  },
  {
    path:'/relatorio/geral/dizimista_mensal',
    element:<RelatorioGeralDizimistaMesal/>
  },
  {
    path:'/relatorio/talao',
    element:<RelatorioTalao/>
  },
  {
    path:'/relatorio/setorial/congregacao',
    element:<RelatorioSetorialCongregacao/>
  },
  {
    path:'/relatorio/dizimista_mensal',
    element:<RelatorioDizimistaMesal/>
  },

  {
    path:'/relatorio/setorial',
    element:<RelatorioSetorial/>
  },

  {
    path:'/ebd/matricula',
    element:<Matricula/>
  },
  {
    path:'/ebd/cadastro_aluno',
    element:<Aluno/>
  },
  {
    path:'/ebd/cadastro_licao',
    element:<Licao/>
  },
  {
    path:'/ebd/cadastro_professor',
    element:<Professor/>
  },
  {
    path:'/relatorio/geral/congregacao',
    element:<RelatorioGeralCongregacao/>
  },
  {
    path:'/relatorio/geral/setor',
    element:<RelatorioGeralSetor/>
  },
  {
    path:'/relatorio/geral',
    element:<RelatorioGeral/>
  },
  
  {
    path:'/teste',
    element:<Teste/>
  },
  {
    path:'/relatorio/tesouraria_mensal',
    element:< TesourariaMensal/>
  },
  {
    path:'/setor',
    element:<Setor/>
  },
  {
    path:'/dizimista',
    element:<Dizimista/>
  },
  {
    path:'/despesa',
    element:<Despesa/>
  },
  {
    path:'/administrador',
    element:<Administrador/>
  },
  {
    path:'/imprimir',
    element:<Imprimir/>
  },
  {
    path:'/culto',
    element:<Culto/>
  },
  {
    path:'/cadastro_igreja',
    element:<Igreja/>
  },
  {
    path:'/inicio',
    element:<Inicio/>
  },
  {
    path:'/upload',
    element:<Upload/>
  },
  {
    path:'/home',
    element:<Home/>
  },

  {
    path:'/admin',
    element:<Admin/>
  },
  {
    path:'/cadastro_membro',
    element: <Private><CadastroMembro/></Private> 
  },
  {
    path:'*',
    element:<Error/>
  }
];

