import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import NavBar from "../../components/NavBar";
import "./culto.css";
import api from "../../services/api";
import { useState, useEffect,useRef } from "react";
import "../style.css";
import ListarDizimista from "../../components/Listar/ListarDizimista";
import converter from "../../Util/Converter";
import ListarTalao from "../../components/Listar/ListarTalao";
import { InfoUser } from "../../components/info/login";

function SaldoSede() {

  const [valorGeral,setValorGeral] = useState({dizimo:'0.00',oferta:'0.00',total:'0.00',saida:'0.00',saldo:'0,00'});


  function getRelatorio()
  {
    api.postGeralRelatorioMesal({
      info:info,
      setor:id_setor,
      congregacao:id_congregacao,
      mesano:mesano
    }).then(({data}) => {
     
     
      setValorGeral({
      dizimo:data[2][0].dizimo||'0.00',
      oferta:data[2][0].oferta||'0.00',
      total:data[2][0].total||'0.00',
      saida:data[3][0].valor||'0.00',
      saldo:converter.FSubtrairf( data[2][0].total,data[3][0].valor)
    }) ;
    console.log("setor: ",id_setor,"congregacao: ","Mes: ",mesano,id_congregacao,data);
    });
    
  }


const handleRegisterTalao= () => {
      api.postSetTalao({
        setor:1,
        congregacao:1,
        talao:numero, 
        dizimo: converter.ToFloat(dizimo==""?"0,00":dizimo),
        oferta: converter.ToFloat(oferta==""?"0,00":oferta),
        data:data,
        total: String(
          parseFloat(
            parseFloat(dizimo==""?0 : dizimo.replace('.','').replace(',','.')) +
            parseFloat(oferta==""?0: oferta.replace('.','').replace(',','.'))
            ).toFixed(2)
        ),
        info:info,
        mesano:mesano
      }).then(({data})=>{
        alert(data.msg);
       
      })
        
}
const inputRef = useRef(null);
////////////////////////////////////////////////////////////////////////////////
const [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
 const [id_setor, setIdSetor] = useState();
 const [id_congregacao, setIdCongregacao] = useState();
 const [id, setId] = useState(localStorage.getItem('id')||"Não Logado");
 const [setor, setSetor] = useState(localStorage.getItem('setor')||"Não Logado");
 const [congregacao, setCongregacao] = useState({
  bairro: "",
  cel_pastor:"",
  cep: "",
  cidade: "",
  complemento: "",
  congregacao: "",
  distrito: 0,
  fk_setor: 0,
  id_congregacao: 0,
  nome_pastor: " ",
  obs: "",
  rua: "",
  situacao: "",
  tipo: 0,
  uf: ""
 });
 const [user, setUser] = useState(localStorage.getItem('user')||"Não Logado!");
 const [geral,setGeral]=useState(localStorage.getItem('geral')||0);
 const [cargo,setCargo]=useState(localStorage.getItem('cargo')||"Não logado");
 ////////////////////////////////////////////////////////////////////////////////
 const [data, setData] = useState("");
 const [dataSistem, setDataSistem] = useState("");
 const [dia,setDia]=useState(0);
 const [mes,setMes]=useState(0);
 const [ano,setAno]=useState(0);
 const [mesano,setMesano] =useState("0");
 const [isData,setIsData]=useState("1");

 const [dataString,setDataString]=useState("1");
 const [log,setLog] =useState("log");
 //////////////////////////////////////////////////////////////////////////////
const [dizimo,setDizimo] = useState("");
const [oferta,setOferta] = useState("");
const [numero,setNumero] = useState("");
const [listSetor,setListSetor] =useState([]);
const [listCongragacao,setListCongregacao] =useState([]);
const [isSaldo,setIsSaldo]=useState(true);

const [transferir,setTransferir]=useState({
  setor:0,
  congregacao:0,
  transferir:0,
  data:0,
  mes:0,
  saldo:0,
  adiantamento:0
})

const [mesanoSaldo,setMesanoSaldo]=useState()

const nomeMes =["","JANEIRO","FEVEREIRO","MARÇO","ABRIL","MAIO","JUNHO","JULHO","AGOSTO","SETEMBRO","OUTUBRO","NOVEMBRO","DEZEMBRO"];



const addTalao=()=>{
 let valor= String(
  
    parseFloat(
        parseFloat(String(oferta===""?0: oferta).replace('.','').replace(',','.')) +
        parseFloat(String(dizimo===""?0: dizimo).replace('.','').replace(',','.'))
        )
     ).replace('.',',');
     const validar=parseInt(valor.replace(",","").replace(".","")) >0 ? true :false;
     if(numero ==="")
     return alert("Informe o numero do talão!")

    if(!validar)
    return alert("Informe a entrada do dizimo ou da orfeta corretamentamente !")
    
    

    handleRegisterTalao();
   
   
    setDizimo("");
    setOferta("");
    inputRef.current.focus();

    


}
const getData=()=>
{
    api.postGetDate({}).then(({ data }) => {
      if(data){
        const date =converter.Date(data);
        setDataSistem(date);
        setData(date.data);
        setDia(date.dia);
        setMes(date.mes);
        setAno(date.ano);
        setMesano(date.mes+""+date.ano);
        console.log(data);
      }
    });
}
  
 
useEffect(() => {
    getData();
   
    getSetor();

  }, []);

 
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  function handleData(e) {
   let date = e.target.value;
    setData(date);
    setMesano(converter.Dates(date).mes+""+converter.Dates(date).ano)
  }

  function handleLogin(e) {
    e.preventDefault();
  }

  function selectSetor(e){
    let v = e.target.value;
    setTransferir({setor:v})
    getCongragacao(v);
    setIdSetor(v);
   
 }
 function selectCongragacao(e){
  let v = e.target.value;
  const result = listCongragacao.filter(d => d.id_congregacao==v );
  setCongregacao(result[0]);
  console.log(v);
 
}
 function selTransferir(e){
  let v = e.target.value;
  if(v==1)
  setIsSaldo(true)
  else if(v==2)
  setIsSaldo(false)

}

const getSetor=()=>
{
     api.postGetSetor({info}).then(({ data }) => {
       if(data){
        setListSetor(data);
        
       }
     });
 }

 function onchangeListarData(ev) {
  let v = ev.target.value;
  setMesano(v);
 }
 
const getCongragacao=async(setor)=>
{
    const {data} = await api.postGetCongregacaoSetor({
        setor:setor,
        info:info,
      });
      console.log(data)
        if(data)
          if(setor==1){
              setListCongregacao(data);
              setCongregacao({
                bairro: "",
                cel_pastor:"",
                cep: "",
                cidade: "",
                complemento: "",
                congregacao: "",
                distrito: 0,
                fk_setor: 0,
                id_congregacao: 0,
                nome_pastor: " ",
                obs: "",
                rua: "",
                situacao: "",
                tipo: 0,
                uf: ""
               });
          }
          else
              {
                const result =  data.filter(d => d.tipo=="1" )
                setCongregacao(result[0]);
                setIdSetor(result[0].fk_id_setor);
                setIdCongregacao(result[0].id_congregacao);
              }

}

function carregar()
{
   
  getRelatorio();
}


  return (
    <div style={{backgroundColor:"#000"}}  className="container-culto">
      <NavBar navegando="TRANSFERENCIA DE SALDO " />
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
      <Form className="container-form" onSubmit={handleLogin}>
        <InfoUser info={info} data={dataSistem.date}/>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <div style={{minHeight:150}}>
        <Row //Nome da congragação
          className="mb-3"
        >
         
         
        <Form.Group className="tm" as={Col}>
        <Form.Label style={{color:"#FFF"}}>SETOR {congregacao.congregacao} {congregacao.id_congregacao}</Form.Label>
       
            <Form.Select
            style={{width:300}}
            onChange={selectSetor} 
             defaultValue="1">
               
              {    
                  listSetor.map((list,index)=>(
                  <option key={index} value={""+list.id_setor}>{list.nome_setor}</option>
                  ))
              }
            
            </Form.Select>
          </Form.Group>
          </Row>

          { transferir.setor==1?
            <Row>
            <Form.Group className="tm" as={Col}>
            <Form.Label style={{color:"#FFF"}}>CONGREGAÇÃO</Form.Label>
              <Form.Select 
                  style={{ maxWidth: 300 }}
                onChange={selectCongragacao}
                defaultValue="0"
              >
                
               {    
                     listCongragacao.map((list,index)=>(
                    <option key={index} value={""+list.id_congregacao}>{list.congregacao}</option>
                    ))
                }
              </Form.Select>
            </Form.Group>
            </Row>
          :
             ""

          }
          </div>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <div style={{minHeight:350}}>
         <Row //Nome da congragação
          className="mb-3"
        >
         
         
        <Form.Group className="tm" as={Col}>
        <Form.Label style={{color:"#FFF"}}>TRANSFERIR  {mesano}</Form.Label>
            <Form.Select
            style={{width:300}}
            onChange={selTransferir} 
             defaultValue="1">
            <option value="1">SALDO</option>
            <option value="2">ADIANTAMENTO</option>
               
            
            </Form.Select>
          </Form.Group>
          </Row>
       
           
            {
              isSaldo?
            <div >
            
                <Row className="mb-3" >
                <Form.Group className="tm" as={Col}>
                  <Form.Label style={{color:"#FFF"}}>SALDO P/ MÊS</Form.Label>
                  <Form.Select
                    ref={inputRef}
                    style={{ maxWidth: 300 }}
                    onChange={onchangeListarData} //(e) => setTalao(e.target.value)}
                    value={mesano}
                  >
                  <option value="012023">JANEIRO 2023</option>
                  <option value="022023">FEVEREIRO 2023</option>
                  <option value="032023">MARÇO 2023</option>
                  <option value="042023">ABRIL 2023</option>
                  <option value="052023">MAIO 2023</option>
                  <option value="062023">JUNHO 2023</option> 
                  <option value="072023">JULHO 2023</option>
                  <option value="082023">AGOSTO 2023</option>
                  <option value="092023">SETEMBRO 2023</option>
                  <option value="102023">OUTUBRO 2023</option>
                  <option value="112023">NOVEMBRO 2023</option>
                  <option value="122023">DEZEMBRO 2023</option>
                  </Form.Select>
                </Form.Group>
                </Row>
                <Row // DIZIMO E OFERTA 
                className="mb-3"
                >
              
                  <Form.Group as={Col}>
                  <Form.Label   style={{color:"white",fontSize:30}}>SALDO TOTAL R$ 
                    <span
                      style={{color:"red",fontSize:30}}
                    > {valorGeral.saldo}</span></Form.Label>
                  </Form.Group>
                  </Row>
                </div>
            :
            <div >
            <Row //Nome da congragação
                           className="mb-3"
                         >
                           <Form.Group as={Col}>
                             <Form.Label className="label-culto">DATA</Form.Label>
                             <Form.Control
                               style={{maxWidth:300}}
                               className="f"
                               type="date"
                               min="2023-01-01"
                               value={data}
                               onChange={handleData}
                             />
                           </Form.Group>
             </Row>
             <Row // DIZIMO E OFERTA 
             className="mb-3"
              >  
              <Form.Group as={Col}>
              <Form.Label className="label-culto">ADIANTAMENTO R$</Form.Label>
              <Form.Control
              style={{maxWidth:300}}
              className="f"
              type="text"
              placeholder="Digite o valor do Adiantamento"
              value={oferta}
              onChange={(e) =>setOferta(e.target.value)}
              /> 
              </Form.Group>
              </Row>
              
            </div>
  
            }
              
              </div>
    
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
        <Button
          style={{width:200,height:60,fontWeight:"bold",fontSize:24}}
           
            onClick={carregar}
          >
            CARREGAR
          </Button>
          <Button
          style={{width:200,height:60,fontWeight:"bold",fontSize:24}}
          
            onClick={addTalao}
          >
            TRANSFERIR
          </Button>
        </div>

        
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
    
      </Form>
     
    </div>
  );
}

const ElementSaldo = ({values}) => 
<div>
  element
</div>;
const ElementAdiantamento = ({values}) => 
<div>
  element
</div>;


export default SaldoSede;
