import { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Logo } from '../components/Logo';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import './login.css'
import { Table } from 'react-bootstrap';
import { Post } from '../Api';

function Login() {

    useEffect(() => {


    }, []);

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [erro, setErro] = useState("");
    const [btnAbilitar, setBtnAbilitar] = useState(true);

    function validarLogin(e) {
        setErro("");
        setEmail(e.target.value);
    }

    function validarSenha(e) {

        setErro("");
        setSenha(e.target.value);
    }

    function handleLogin() {

     
        Acessar();


        // 
        // alert(email);

    }
    async function Acessar() {
       
        let acesso = {};

        if (email.includes('@pglider')) {
            const { data } = await Post('/v2_login', {
                login: email,
                senha: senha,
                token: "Q2BuITXnw2KusoPATy8S9EHktMb4TMOwYbfX963cff4d65230e",
            })
                .catch((error) => {
                    return []
                });
            acesso = data;
            console.log("acesso ... ", data);
            if (acesso?.sucess) {
                localStorage.setItem('version',1);
                localStorage.setItem('ad@belem-amparo',JSON.stringify(acesso.info));
                localStorage.setItem('config',JSON.stringify(acesso.info));
                localStorage.setItem('token',acesso?.info?.token);
                navigate(acesso?.navega);
            }
            else {
                setErro("Login ou senha incorreto!");
             
            }
        }
        else
        if (email.includes('@secretaria')) {
            const { data } = await Post('/v2_login/secretaria', {
                login: email,
                senha: senha,
                token: "Q2BuITXnw2KusoPATy8S9EHktMb4TMOwYbfX963cff4d65230e",
            })
                .catch((error) => {
                    return []
                });
            acesso = data;
            console.log("acesso ... ", data);
            if (acesso?.sucess) {
                localStorage.setItem('version',1);
                localStorage.setItem('ad@belem-amparo',JSON.stringify(acesso.info));
                localStorage.setItem('config',JSON.stringify(acesso.info));
                localStorage.setItem('token',acesso?.info?.token);
                navigate(acesso?.navega);
            }
            else {
                setErro("Login ou senha incorreto!");
             
            }
        }
        else {

            const {data}= await Post('/login',{
                login:email, 
                senha:senha,
                token:"Q2BuITXnw2KusoPATy8S9EHktMb4TMOwYbfX963cff4d65230e",
              })
              .catch((error) =>{
                return []
            });

            console.log(data)
              if(data?.result.length)
                {
                  
        
                    const result = data.result[0]
                  
                    localStorage.setItem('config',JSON.stringify(result));
        
                    const link=  data.link;
                    localStorage.setItem('token',data?.token);
                    localStorage.setItem('link',JSON.stringify(link));
                    navigate("/inicio");
                }   
                else
                {
                  setErro("Login ou senha incorreto!");
               
                }

        }

      

        //  Keyboard.dismiss();

    }
    return (
      
        <div style={{backgroundColor:"#000"}} className='login-container'>
            <Logo className='login-logo'/>
            <Form className='form'>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className='label-text'>Login</Form.Label>
                    <Form.Control type="text" placeholder="Digite o email" 
                    value={email}
                    required
                    onChange={validarLogin}
                    />
                    <Form.Text className="text-muted">

                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className='label-text'>Senha</Form.Label>
                    <Form.Control type="password" placeholder="Digite a senha" autoComplete='on'
                     value={senha}
                     required
                     onChange={validarSenha}
                     />
                </Form.Group>
                <label style={{color:"red",height:40,fontSize:30,alignSelf:"center"}}>{erro}</label>
              
                    <Button className="btn_ativo" onClick={handleLogin}>
                        Entrar
                    </Button>
                 
            </Form>
       
        </div>
    );
}

export default Login;