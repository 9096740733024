import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "./mensal.css";
import React, { useRef,useEffect,useState } from "react";
import { useReactToPrint } from "react-to-print";
import imgLogo from "../../../imagens/adbelem.png";
import NavBar from "../../../components/NavBar";
import api from "../../../services/api";
import converter from "../../../Util/Converter";
import { ListarData } from "../../../hooks/datas";
function TesourariaMensal2() {
  function ConverterReal(valor) {
    return converter.ConverterReal(valor);  
  }
   
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      onAfterPrint: () => {
        return new Promise(() => {
          setShow(false);
        });
      }
    });
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
////////////////////////////////////////////////////////////////////////////////
const [id_setor, setIdSetor] = useState( localStorage.getItem('id_setor')||"0");
const [id_congregacao, setIdCongregacao] = useState(localStorage.getItem('id_congregacao')||"0");
const [id, setId] = useState(localStorage.getItem('id')||"Não Logado");
const [setor, setSetor] = useState(localStorage.getItem('setor')||"Não Logado");
const [congregacao, setCongregacao] = useState(localStorage.getItem('congregacao')||"Não Logado");
const [user, setUser] = useState(localStorage.getItem('user')||"Não Logado!");
const [geral,setGeral]=useState(localStorage.getItem('geral')||0);
const [cargo,setCargo]=useState(localStorage.getItem('cargo')||"Não logado");
////////////////////////////////////////////////////////////////////////////////
const [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
const [data, setData] = useState("");
 const [dia,setDia]=useState(0);
 const [mes,setMes]=useState(0);
 const [ano,setAno]=useState(0);
 const [mesano,setMesano] =useState("0");
 const [mesResumo,setMesResumo] =useState("");
 const [mesRelatorio,setMesRelatorio] = useState("");
 const [totalEntradas,setTotalEntradas] = useState("0,00");
 const [totalSaidas,setTtotalSaidas] = useState("0,00");
 const [saldo,setSaldo] =  useState("0,00");
 const [dizimo,setDizimo] = useState("0,00");
 const [oferta,setoferta] =  useState("0,00");
 const [listTalao,setListTalao] =  useState([]);
 const [listTalaoTransf,setListTalaoTransf] =  useState([]);
 const [listDespesa,setListDespesa] =  useState([]);
 const [entrada,setEntrada]= useState({dizimo:0,oferta:0,subtotal:0,total:0,saldo:0});
 const nomeMes =["","JANEIRO","FEVEREIRO","MARÇO","ABRIL","MAIO","JUNHO","JULHO","AGOSTO","SETEMBRO","OUTUBRO","NOVEMBRO","DEZEMBRO"];
 const rowsSaidas=["","","","","","","","","","","","","","","","","","","",""];
const getData=()=>
{
    api.postGetDate({info}).then(({ data }) => {
      if(data){
        let date =converter.Date(data)
        setData(date.data);
        setDia(date.dia);
        setMes(date.mes);
        setAno(date.ano);
        setMesano(date.mes+""+date.ano)
      }
    });
}
useEffect(() => {
    getData();

  }, []);
 function onchangeListarData(ev) {
    let v = ev.target.value;

    setTtotalSaidas("0,00");
    setListDespesa([]);
    setListTalao([]);
    
    const texto = ev.target.options[ev.target.selectedIndex].text;
    setMesResumo(texto);

     getRelatorioMensalTalao(v);
     getRelatorioMensalDespesa(v);
     getRelatorioMensalDizimo(v);
     getRelatorioMensalOferta(v);
     getRelatorioMensalDespesaTotal(v);

 }

  const getRelatorioMensalTalao = (valor) => {
    var d_mo=0;
    var o_ta=0;
    var t_d_o=0;
    var t_tal=0;
    var t_do=0;
    var talao_valor=[];
    var talao_transf=[];
    api
      .postGetRelatorioMensalTalao( {
        setor: id_setor,
        congregacao: id_congregacao,
        mesano:valor,
        info:info
      })
      .then(( {data} ) => {
        if (data.length > 0) {
            data.map((v)=>{
                
                if(parseInt( v.talao))
                {
                 d_mo = converter.FSomarf(v.dizimo,d_mo);
                 o_ta = converter.FSomarf(v.oferta,o_ta);
                 t_d_o = converter.FSomarf(v.total,t_d_o);

                 talao_valor.push(v);  
                }
                 else
                 {
                  t_do = converter.FSomarf(v.oferta,t_do);
                  t_tal = converter.FSomarf(v.total,t_tal);
                  talao_transf.push(v);
                 }
                 

            })

          setEntrada({
            dizimo:d_mo,
            oferta: o_ta,
            subtotal: t_d_o,
            total: converter.FSomarf(t_d_o,t_tal),
            saldo:t_tal
          });
          
          setListTalao(talao_valor);
          setListTalaoTransf(talao_transf);
        }
      });
  };
  const getRelatorioMensalDizimo = (valor) => {
    api
      .postGetRelatorioMensalDizimo({
        setor: id_setor,
        congregacao: id_congregacao,
        mesano:valor,
        info:info,
      })
      .then(( {data} ) => {
        if (data.length > 0) {
      
          setDizimo(data[0].dizimo == null ? "0.00" :data[0].dizimo);
        }
      });
  };
  const getRelatorioMensalOferta = (valor) => {
    api
      .post("/select_relatorio_mensal_orfeta", {
        setor: id_setor,
        congregacao: id_congregacao,
        mesano:valor,
        info:info
      })
      .then(( {data} ) => {
        if (data.length > 0) {
    
          setoferta(data[0].oferta == null ? "0.00" :data[0].oferta );
    
        }
      });
  };
  const getRelatorioMensalDespesa = (valor) => {
    api
      .postGetRelatorioMensalDespesa( {
        setor: id_setor,
        congregacao: id_congregacao,
        mesano:valor,
        info:info
      })
      .then(( {data} ) => {
        if (data.length > 0) {
       
          setListDespesa(data);

        }
      });
  };
  const getRelatorioMensalDespesaTotal = (valor) => {
    api
      .postGetRelatorioMensalDespesaTotal({
        setor: id_setor,
        congregacao: id_congregacao,
        mesano:valor,
        info:info
      })
      .then(( {data} ) => {
        if (data.length > 0) {
 
          setTtotalSaidas(data[0].despesa == null ? "0.00" :data[0].despesa)
          
        }
      });
  };
  return (
<   div style={{ backgroundColor:"#000"}}>
        <NavBar navegando={"Relatório Mensal"} />
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
        <div //Cabeçario
        style={{
            display:"flex",flexDirection:"column",borderRadius:15,backgroundColor:"#fff",
            width:"100%",marginLeft:10
            }}>
        <label>DATA DO SISTEMA: {data}</label>
        <label>SETOR: {info.nome_setor} </label>
        <label>CONGREGAÇÃO: {info.congregacao} </label>
        <label>{info.nome_cargo}: {info.nome} </label>

        </div>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
        <div style={{marginLeft:10}}>
        
        <Row className="mb-3">
          <Form.Group className="tm" as={Col}>
            <Form.Label style={{color:"#FFF"}}>RELATÓRIO DO MÊS</Form.Label>
            <Form.Select
              style={{ maxWidth: 300 }}
              onChange={onchangeListarData} //(e) => setTalao(e.target.value)}
              defaultValue=""
            >
            <option value=""></option>
            {   
           ListarData().map((dta)=>(
           <option value={dta.mesano}>{dta.mesnome} DE {dta.ano}</option>
           ))
           }
            </Form.Select>
          </Form.Group>
        </Row>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <div style={{color:"#FFF",display:"flex",flexDirection:"column",marginLeft:10}}>
            <p>RESUMO DO MÊS {mesResumo}</p>
            <p>DIZIMO:___________ R$ {ConverterReal(dizimo)}</p>
            <p>OFERTA:___________ R$ {ConverterReal(entrada.oferta)}</p>
            <p>SALDOS:___________ R$ {ConverterReal(entrada.saldo)}</p>
            <p>ENTRADAS:_______ R$   {converter.SomarFloat(dizimo,oferta)}</p>
            <p>SAIDAS:___________ R$ {ConverterReal(totalSaidas)}</p>
              <p>SALDO EM CAIXA R$    {converter.SomarFloatTransf(dizimo,oferta,totalSaidas)}</p>
           
        </div>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
          <div >
            {/* <Button
              style={{height:60,width:200,borderRadius:5}}
              onClick={handlePrint}
              >IMPRIMIR
            </Button> */}
            <Button
                style={{height:60,width:200,borderRadius:5}}
                onClick={() => handleShow(true)}
              >VISUALIZAR
            </Button>
          </div>
     </div>
     <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
 
         <Modal //modal de pesquisa
         show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
            <Button
              style={{height:60,width:200,borderRadius:5}}
              onClick={handlePrint}
              >IMPRIMIR
            </Button>
           </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div //////////////////////////Impresãoooooooooo
              style={{flexDirection:"column",width:1133,height:"100%",background:"#FFF"}} >
                  <style type="text/css" media="print">{"@page { size: portrait; } @media print{#noprint{display:none}}"}</style>
                  <div style={{display:"flex",flexDirection:"column", width:1333,height:"100%",background:"#FFF"}} ref={componentRef}>
                          <div
                              style={{
                              display: "flex",
                              width: "100%",
                              height: 100,
                              backgroundColor: "#FFF",
                          }}
                          >
                          
                          <div
                              style={{
                              display: "flex",
                              width: 220,
                              height: 200,
                              backgroundColor: "#fff",
                              justifyContent: "center",
                              }}
                          >
                          <img src={imgLogo} width="200" height="180" />
                          </div>

                  <div
                      style={{ width: "100%", height: "100%", backgroundColor: "#FFF" }}
                  >
                      <div style={{ width: "100%", height: 40, backgroundColor: "#FFF" }}>
                      <label
                          style={{
                          width: 550,
                          fontSize: 28,
                          fontWeight: "bold",
                          textAlign: "center",
                          }}
                      >
                          RELATÓRIO FINANCEIRO
                      </label>

                      <label style={{ fontSize: 26 }}>folha n° 01</label>
                      </div>
                      <div style={{ width: "100%", height: 50, backgroundColor: "#FFF" }}>
                      <label
                          style={{
                          width: 550,
                          fontSize: 20,
                          textAlign: "center",
                          fontWeight: "bold",
                          }}
                      >
                          IGREJA EVANGÉLICA ASSEMBLÉIA DE DEUS
                      </label>
                      <label
                          style={{ fontSize: 20, fontWeight: "bold", marginRight: 5 }}
                      >
                          SETOR:
                      </label>
                      <label style={{ fontSize: 20, fontWeight: "bold" }}>{info.nome_setor}</label>
                      </div>
                      <div style={{ width: "100%", height: 40, backgroundColor: "#FFF" }}>
                      <label style={{ width: 500, textAlign: "center", fontSize: 18 }}>
                          MINISTÉRIO DE BELÉM - AMPARO - SP
                      </label>
                      <label
                          style={{ marginRight: 10, fontSize: 16, fontWeight: "bold" }}
                      >
                          CONGREGAÇÃO:
                      </label>
                      <label style={{ textAlign: "center", fontSize: 16 }}>
                          {info.congregacao}
                      </label>
                      </div>
                      <div style={{ width: "100%", height: 70, backgroundColor: "#FFF" }}>
                      <label style={{ width: 500, backgroundColor: "#FFF" }}></label>
                      <label style={{ fontWeight: "bold", fontSize: 17 }}>
                          REFERENTE AO MÊS DE {mesResumo}
                      </label>
                      </div>
                    
                  </div>
                
                  </div>
                      <div style={{marginTop:100,backgroundColor:"#FFF",display:"flex",flexDirection:"row"}}>
                          <div style={{marginLeft:15}}>
                            <table style={{fontSize:16}}>
                              <tr>
                                  {/* <td style={{width:0,height:20,textAlign:"center"}}>.</td> */}
                                  <td style={{width:100,height:20,textAlign:"center",fontWeight:"bold"}}>DATA</td>
                                  <td style={{width:80,height:20,textAlign:"center",fontWeight:"bold"}}>TALÃO</td>
                                  <td style={{width:100,height:20,textAlign:"center",fontWeight:"bold"}}>DIZIMO</td>
                                  <td style={{width:100,height:20,textAlign:"center",fontWeight:"bold"}}>OFERTA</td>
                                  <td style={{width:100,height:20,textAlign:"center",fontWeight:"bold"}}>TOTAL</td>
                      
                              </tr>
                            
                              {
                              listTalao.map((list,index)=>(
                                  
                              <tr  key={index} >
                                  {/* <td style={{width:0,height:20,textAlign:"center"}}>.{index}</td> */}
                                  <td style={{width:100,height:20,textAlign:"center"}}>{converter.Dates(list.data).date}</td>
                                  <td style={{width:80,height:20,textAlign:"center"}}>{list.talao}</td>
                                  <td style={{width:100,height:20}}>R${" "}<span style={{float:"right",textAlign:"right",marginRight:2}}>{converter.ConverterReal(list.dizimo)}</span></td>
                                  <td style={{width:100,height:20}}>R${" "}<span style={{float:"right",textAlign:"right",marginRight:2}}>{converter.ConverterReal(list.oferta)}</span></td>
                                  <td style={{width:100,height:20}}>R${" "}<span style={{float:"right",textAlign:"right",marginRight:2}}>{converter.ConverterReal(list.total)}</span></td>
                              </tr>
                              ))
                              }
                              <tr>
                                <td style={{width:100,height:30,textAlign:"center"}}>-</td>
                                <td style={{width:80,height:30,textAlign:"center",fontSize:16,fontWeight:"bold"}}>TOTAL</td>
                                  <td style={{width:10,height:30}}>R${" "}<span style={{color:"#191970",float:"right",textAlign:"right",marginRight:2,fontSize:16,fontWeight:"bold"}}>{converter.ConverterReal(entrada.dizimo)}</span></td>
                                  <td style={{width:100,height:30}}>R${" "}<span style={{color:"#191970",float:"right",textAlign:"right",marginRight:2,fontSize:16,fontWeight:"bold"}}>{converter.ConverterReal(entrada.oferta)}</span></td>
                                  <td style={{width:100,height:30}}>R${" "}<span style={{color:"#191970",float:"right",textAlign:"right",marginRight:2,fontSize:16,fontWeight:"bold"}}>{converter.ConverterReal(entrada.subtotal)}</span></td>
                              </tr>
                            
                            </table>
                            <div style={{alignItems:"center",display:"flex",flexDirection:"column"}}>
                                <label>RESUMO</label>
                                <div style={{width:"100%",fontSize:14,color:"#191970",fontWeight:"bold"}}>
                                    <p>DIZIMO:___________ R$ {ConverterReal(dizimo)}</p>
                                    <p>OFERTA:___________ R$ {ConverterReal(entrada.oferta)}</p>
                                    
                                    <p>SALDO:_________ ___R$ {ConverterReal(entrada.saldo)}</p>
                                 
                                </div>
                                <label style={{fontWeight:"bold"}}>SALDOS DAS CONGREGAÇÕES</label>
                                {
                                    listTalaoTransf.map((list,index)=>(    
                                    <div  key={index} style={{display:"flex",flexDirection:"row",backgroundColor:"#fff",width:"100%"}}>
                                        {/* <td style={{width:0,height:20,textAlign:"center"}}>.{index}</td> */}
                                        <label style={{width:"100%"}}>{converter.Dates(list.data).date} - 
                                        {list.talao}{" "} </label>
                                        <label style={{width:140}}>R$:
                                        <span style={{float:"right",width:80,textAlign:"right",marginRight:5}}>{converter.ConverterReal(list.total)}</span>
                                        </label>
                                    </div>
                                    ))  
                                }
                                  <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                                    <label style={{width:"100%",textAlign:"right",paddingRight:20,fontWeight:"bold"}}>SALDO TOTAL TRANSFERIDO</label>
                                    <label style={{width:140}}>R$:
                                          <span style={{color:"#191970",float:"right",width:80,textAlign:"right",marginRight:5,fontWeight:"bold"}}>{converter.ConverterReal(entrada.saldo)}</span>
                                    </label>
                                  </div> 
                                  
                                 <label style={{width:"100%",textAlign:"right",marginTop:20,marginRight:80,fontSize:20,fontWeight:"bold"}}>TOTAL DE ENTRADAS R$: {" "}
                                        <span style={{float:"right",width:90,textAlign:"right",marginRight:5}}>{converter.ConverterReal(entrada.total)}</span>
                                </label>
                                <label style={{width:"100%",textAlign:"right",marginTop:10,marginRight:80,fontSize:20,fontWeight:"bold",color:"red"}}>DESPESAS PAGAS R$: {" "}
                                        <span style={{float:"right",width:90,textAlign:"right",marginRight:5,color:"red"}}>{converter.ConverterReal(totalSaidas)}</span>
                                </label>
                                <label style={{width:"100%",textAlign:"right",marginTop:10,marginRight:80,fontSize:20,fontWeight:"bold",color:"#191970"}}>SALDO EM CAIXA R$: {" "}
                                        <span style={{float:"right",width:90,textAlign:"right",marginRight:5,color:"#191970"}}> {converter.SomarFloatTransf(dizimo,oferta,totalSaidas)}</span>
                                </label>
                              
                            </div>
                          </div>

                          <div>
                          <table style={{fontSize:16}}>
                              <tr>
                                  <td style={{width:100,height:20,textAlign:"center",fontWeight:"bold"}}>DATA</td>
                                  <td style={{width:350,height:20,textAlign:"center",fontWeight:"bold"}}>SAIDAS</td>
                                  <td style={{width:120,height:20,textAlign:"center",fontWeight:"bold"}}>VALOR</td>
                                  {/* <td style={{width:0,height:20,textAlign:"center"}}>.</td> */}
                              </tr>
                            {
                              listDespesa.map((list,index)=>(
                              <tr key={index}>
                                  
                                  <td  style={{width:100,height:20,textAlign:"center"}}>{converter.Dates(list.data).date}</td>
                                  <td style={{width:350,height:20}}><span style={{marginLeft:5}}>{list.descricao}</span></td>
                                  <td style={{width:100,height:20,color:"red"}}>R${" "}<span style={{color:"red",float:"right",textAlign:"right",marginRight:2}}>{converter.ConverterReal(list.valor)}</span></td>
                                  {/* <td style={{width:0,height:20,textAlign:"center"}}>.{index}</td> */}
                              </tr>
                              ))
                              
                                
                            }
                              <tr>    
                                  <td style={{width:100,height:30,textAlign:"center"}}>-</td>
                                  <td style={{width:350,height:30,}}><span style={{fontSize:16,colr:"red",float:"right",textAlign:"right",fontWeight:"bold",color:"red",marginRight:2}}>DESPESAS PAGAS</span></td>
                                  <td style={{width:100,height:30,color:"red"}}>R${" "}<span style={{color:"red",float:"right",textAlign:"right",marginRight:2,fontSize:16,fontWeight:"bold"}}>{converter.ConverterReal(totalSaidas)}</span></td>
                                  {/* <td style={{width:0,height:20,textAlign:"center"}}>.{index}</td> */}
                              </tr>
                           
                                
                            
                          </table>
                        
                          </div>
                          
                        </div>
                
                      
                      <div style={{backgroundColor:"#fff",marginTop:15}}>

                        <div style={{display:"flex",flexDirection:"row",backgroundColor:"#fff",width:1100}}>
                        <label >RECEBI EM: _____/______/{ano}</label>
                        
                        </div>
                  
                          
                        
                        
                          <div style={{
                            display:"flex",flexDirection:"row",backgroundColor:"#FFF",width:1310,marginTop:25}}>
                              <label style={{width:260,textAlign:"center"}}>PASTOR PRESIDENTE</label>
                              <label style={{width:260,textAlign:"center"}}>TESOURARIA CAMPO</label>
                              <label style={{width:260,textAlign:"center"}}>DIRIGENTE LOCAL</label>
                              <label style={{width:260,textAlign:"center"}}>TESOURARIA LOCAL</label>
                          </div>
                      </div>

                  </div>
              </div>
          </Modal.Body>
        </Modal>
        </div>
  );
}

export default TesourariaMensal2;
