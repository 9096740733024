import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import NavBar from "../../../components/NavBar";
import "./membro.css";
import api from "../../../services/api";
import {useState, useEffect, useRef, useLayoutEffect} from "react";
import { useInsertionEffect } from "react";

function CadastroMembro() {
  const ref = useRef(null);
  const [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
  ////////////////////////////////////////////////
  const [width,setWidth] = useState("");
  const [heigth,setHeight]=useState("");
  ///////

  ////////////////////////////////////////////////
  const [id, setId] = useState("");
  const [nome, setNome] = useState("");
  const [pai, setPai] = useState("");
  const [mae, setMae] = useState("");
  const [natural, setNatural] = useState("");
  const [estado, setEstado] = useState("");
  const [nacionalidade, setNacionalidade] = useState("");
  const [batizado, setBatizado] = useState("");
  const [cargo, setCargo] = useState("");
  const [setor, setSetor] = useState("");
  const [congregacao, setCongregacao] = useState("");
  const [civil, setCivil] = useState("");
  const [rg, setRg] = useState("");
  const [cpf, setCpf] = useState("");
  const [cel, setCel] = useState("");
  const [email, setEmail] = useState("");
  const [rua, setRua] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [cep, setCep] = useState("");
  const [teologia, setTeologia] = useState("");
  const [efo, setEfo] = useState("");
  const [carteirinha, setCarteiria] = useState("");
  const [acesso, setAcesso] = useState("");
  const [dt_batismo, setDataBatismo] = useState("");
  const [dt_nascimento, setDataNascimento] = useState("");
  const [registrador, setRegistrador] = useState("");
  const [alterador, setAlterador] = useState("");
  const [foto, setFoto] = useState("");
  const [senha, setSenha] = useState("");
  const [sexo, setSexo] = useState("");
  const [capelania, setCapelania] = useState("");
  const[listCargo,setListCargo]=useState([]);

  const getCargo=()=>
  {
      api.postGetCargo({info}).then(({ data }) => {
        if(data){
         setListCargo(data);
        }
      });
  }
  useEffect(() => {
    getCargo();
    
  }, []);
  
  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, []);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  function handleLogin(e) {
    e.preventDefault();
  }
 
  function Salvar(){
   
    

  }

  const insert_membro = () => {
    api
      .postSetMembro({
        nome:nome, 
        sexo:sexo, 
        pai:pai, 
        mae:mae, 
        natural:natural, 
        estado:estado, 
        nacionalidade:nacionalidade, 
        batizado:batizado, 
        cargo:cargo, 
        setor:setor, 
        congregacao:congregacao, 
        civil:civil, 
        rg:rg, 
        cpf:cpf, 
        cel:cel, 
        email:email, 
        rua:rua, 
        bairro:bairro, 
        cidade:cidade, 
        uf:uf, 
        cep:cep, 
        teologia:teologia, 
        efo:efo, 
        capelania:capelania, 
        carteirinha:carteirinha, 
        acesso:acesso, 
        dt_batismo:dt_batismo, 
        dt_nascimento:dt_batismo, 
        registrador:registrador, 
        alterador:alterador, 
        foto:foto,
        info:info
              
      })
      .then(({data}) => {
        alert(data);
      });
  };
 

  return (
    <div style={{backgroundColor:"#000"}} ref={ref} className="container-membro">
      <NavBar navegando="Cadastro de Membro " />
      <div className="imagem">
        <div className="quadro">
          <div className="foto">
            {foto ? (
              <img width="150" height="150" src={URL.createObjectURL(foto)} />
            ) : (
              <img width="150" height="150" src="./usuario.png" />
            )}
          </div>
          <label className="arquivo" htmlFor="arquivo">
            Escolher foto
          </label>
          <input
            type="file"
            name="imagem"
            id="arquivo"
            style={{ display: "none" }}
            onChange={(e) => setFoto(e.target.files[0])}
          ></input>
        </div>
      </div>
      <p style={{color:"#fff"}}>{width} <span> {heigth}</span></p>
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
      <Form className="container-form" onChange={handleLogin}>
        <Row //SETOR, CONGRAGACAO, PASTOR
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label  className="label-membro">CARGO</Form.Label>
            <Form.Select 
            defaultValue="1">
          
             {    
                  listCargo.map((list,index)=>(
                  <option key={index} value={""+list.id_cargo}>{list.nome_cargo}</option>
                  ))
              }
            </Form.Select>
          </Form.Group>
        </Row>

        <Row //Nome do membro, data de batismo
          className="mb-3"
        >
          <Form.Group as={Col}>

            <Form.Label className="label-membro">NOME DO MEMBRO</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o nome completo"
              value={nome}
              onChange={(e) => setNome(e.target.value.toUpperCase())}
            />
          </Form.Group>
    
        </Row>

          <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-membro">DATA DE BATISMO</Form.Label>
            <Form.Control
              type="date"
              value={dt_batismo}
              onChange={(e) => setDataBatismo(e.target.value)}
            />
          </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-membro">CELULAR</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o numero do celular"
              value={cel}
              onChange={(e) => setCel(e.target.value)}
            />
          </Form.Group>
          </Row>

         <Row //sexo, data nascimento, civil
          className="mb-3"
        > 
          <Form.Group as={Col}>
            <Form.Label className="label-membro">DATA NASCIMENTO</Form.Label>
            <Form.Control
              value={dt_nascimento}
              type="date"
              onChange={(e) => setDataNascimento(e.target.value)}
            />
          </Form.Group>
          </Row>

          <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-membro">ESTADO CIVIL</Form.Label>
            <Form.Select
            onc
             defaultValue="1">
              
              <option value="SOLTEIRO">SOLTEIRO(a)</option>
              <option value="CASADO">CASADO(a)</option>
              <option value="VIUVO">VIUVA(a)</option>
              <option value="DIVORCIADO">DEVORCIADO(a)</option>
              <option value="OUTROS">OUTROS</option>
            </Form.Select>
          </Form.Group>
          </Row>
          <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-membro">SEXO</Form.Label>
            <Form.Select defaultValue="M">
              <option value="M">MASCULINO</option>
              <option value="F">FERMININO</option>
            </Form.Select>
          </Form.Group>
          </Row>
        <Row //RG, UF, CPF
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-membro">RG</Form.Label>
            <Form.Control
              value={rg}
              placeholder="Digite o RG"
              onChange={(e) => setRg(e.target.value)}
            />
          </Form.Group>
          </Row>
          <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-membro">CPF</Form.Label>
            <Form.Control
              value={cep}
              placeholder="Digite o CPF"
              onChange={(e) => setCep(e.target.value)}
            />
          </Form.Group>
         </Row>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <Row //NATURALIDADE, UF, NACIONALIDADE
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-membro">NATURALIDADE</Form.Label>
            <Form.Control
              placeholder="Digite a naturalidade"
              value={rua}
              onChange={(e) => setRua(e.target.value.toUpperCase())}
            />
          </Form.Group>
         </Row>
         <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-membro">UF</Form.Label>
            <Form.Control
              placeholder="Digite o estado"
              value={uf}
              onChange={(e) => setUf(e.target.value.toUpperCase())}
            />
            
          </Form.Group>
          </Row>
          <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-membro">NACIONALIDADE</Form.Label>
            <Form.Control
              placeholder="Digite a nacionalidade"
              value={nacionalidade}
              onChange={(e) => setNacionalidade(e.target.value.toUpperCase())}
            />
          </Form.Group>
        </Row>
       
        <Row //Nome do pai, nome da mae
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-membro">NOME DO PAI</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o nome do pai"
              value={nome}
              onChange={(e) => setNome(e.target.value.toUpperCase())}
            />
          </Form.Group>
          </Row>
          <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-membro">NOME DA MÃE</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o nome da mãe"
              value={mae}
              onChange={(e) => setMae(e.target.value.toUpperCase())}
            />
          </Form.Group>
        </Row>

        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>

        <Row // rua, complemento
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-membro">NOME DA RUA</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o endereço"
              value={rua}
              onChange={(e) => setRua(e.target.value.toUpperCase())}
            />
          </Form.Group>
        </Row>
        <Row // bairro, cidade, uf, cep
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-membro">BAIRRO</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o bairro"
              value={bairro}
              onChange={(e) => setBairro(e.target.value.toUpperCase())}
            />
          </Form.Group>
          </Row>
          <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-membro">CIDADE</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite a cidade"
              value={cidade}
              onChange={(e) => setCidade(e.target.value.toUpperCase())}
            />
          </Form.Group>
          </Row>
          <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-membro">UF</Form.Label>
            <Form.Select defaultValue="sp">
              <option value="ac">Acre</option>
              <option value="al">Alagoas</option>
              <option value="am">Amazonas</option>
              <option value="ap">Amapá</option>
              <option value="ba">Bahia</option>
              <option value="ce">Ceará</option>
              <option value="df">Distrito Federal</option>
              <option value="es">Espírito Santo</option>
              <option value="go">Goiás</option>
              <option value="ma">Maranhão</option>
              <option value="mt">Mato Grosso</option>
              <option value="ms">Mato Grosso do Sul</option>
              <option value="mg">Minas Gerais</option>
              <option value="pa">Pará</option>
              <option value="pb">Paraíba</option>
              <option value="pr">Paraná</option>
              <option value="pe">Pernambuco</option>
              <option value="pi">Piauí</option>
              <option value="rj">Rio de Janeiro</option>
              <option value="rn">Rio Grande do Norte</option>
              <option value="ro">Rondônia</option>
              <option value="rs">Rio Grande do Sul</option>
              <option value="rr">Roraima</option>
              <option value="sc">Santa Catarina</option>
              <option value="se">Sergipe</option>
              <option value="sp">São Paulo</option>
              <option value="to">Tocantins</option>
            </Form.Select>
          </Form.Group>
          </Row>
          <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-membro">CEP</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o cep"
              value={cep}
              onChange={(e) => setCep(e.target.value)}
            />
          </Form.Group>
        </Row>
       
        <div className="button-membro">
          <Button
            className="button-membro-salvar"
            variant="primary"
            onClick={Salvar}
          >
            Salvar

            
          </Button>
          <Button
            className="button-membro-cancelar"
            onClick={() => handleShow(true)}
          >
            Cancelar
          </Button>
        </div>
      </Form>

      <Modal //modal de pesquisa
       show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
          <Row //Nome do membro, data de batismo
          className="mb-3"
        >
            <Form.Group as={Col}>
              <Form.Label className="label-membro">PESQUISAR MEMBRO</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome"
                style={{ width: 400 }}
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="label-membro">SETOR</Form.Label>
              <Form.Select defaultValue="1">
                <option value="1">AMPARO</option>
                <option value="2">MONTE ALEGRE DO SUL</option>
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label className="label-membro">CONGREGAÇÃO</Form.Label>
              <Form.Select defaultValue="1">
                <option value="1">TRES PONTES</option>
                <option value="2">SAO DIMAS</option>
              </Form.Select>
            </Form.Group>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Lista de Membro</Modal.Body>
      </Modal>
    </div>
  );
}

export default CadastroMembro;
