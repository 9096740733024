
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './style.css';
import { Button, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import { Get, Post, Put } from '../Api';
import { useNavigate } from 'react-router-dom';
import { TK } from '../Api/env';
import { DiaSemana } from './hook'
import converter from '../Util/Converter';
import api from '../services/api';
function Chamada() {
    const [p_grupo_id, set_p_grupo_id] = useState("");
    const [p_membro_id, set_p_membro_id] = useState("");
    const [p_data, set_p_data] = useState("");

    const navegar = useNavigate();
    const [listar_tipo, set_listar_tipo] = useState(["Tipo de Membro", "Visitante", "F.Assiduo", "M.Igreja", "Anfritrião", "L. Treinamento", "Lider"])
    const [m_congregacao_id, set_m_congregacao_id] = useState(TK().congregracao_id);
    const [m_grupo_id, set_m_grupo_id] = useState("");
    const [m_membro, set_m_membro] = useState("");
    const [m_tipo, set_m_tipo] = useState(0);
    const [m_tel, set_m_tel] = useState("");
    const [m_id, set_m_id] = useState("");
    const [showListar, setShowListar] = useState(false);
    const [showListarGrupo, setShowListarGrupo] = useState(false);
    const [ativo, set_ativo] = useState(1);
    const [is_alterar, set_is_alterar] = useState(false);
    const [lista, set_lista] = useState([]);
    const [lista_grupo, set_lista_grupo] = useState([]);
    const [grupo_nome, set_grupo_nome] = useState("");
    const [dia, set_dia] = useState("");
    const [semana, set_semana] = useState("");

    const CarregarMembro = async (grupo_id,date) => {

        const { data } = await Get(`/pg/presenca?m_grupo_id=${grupo_id}&p_data=${converter.Dates(date).data}`);
        if (data.sucess) {
            console.log('lista membro', data, "Id", grupo_id);
            set_lista(data?.data);
        }
    }

    const Listar = () => {

        setShowListar(true)
    }

    const ListarGrupo = async () => {
        const { data } = await Get(`/pg/grupo/listar?g_lider_id=${TK().id}`);
        //const { data } = await Get(`/pg/membro/listar/grupo?g_lider_id=${TK()?.id}`);
        console.log("lista ", data, "id_lider", TK()?.id);
        if (data?.data) {
            set_lista_grupo(data?.data)
            setShowListarGrupo(true);
        }

    }


    const Salvar = async (valor) => {




    }

    const Cancelar = () => {
        set_is_alterar(false);

    }



    const SelecionarGrupo = async(grupo) => {
        set_lista([]);
        if (!grupo.g_id) {
            alert('Selecione um grupo!');
            return false;
        }
       // getData(grupo.g_dia);
        set_grupo_nome(grupo.g_nome);
        set_m_grupo_id(grupo.g_id);
        setShowListarGrupo(false);
        CarregarMembro(grupo.g_id,await getData(grupo.g_dia));

        return true;

    }
    const SelecioneMembro = async (value) => {
       
        const { data } = await Post('/pg/presenca/', {
            m_grupo_id,
            m_membro_id: value.m_id,
            m_data: converter.Dates(dia).data,
            m_dia: semana

        });

        if (data.sucess) {
            console.log('confirmado presenca membro', data);
            alert('Presença confirmada com sucesso!');
            CarregarMembro(m_grupo_id, dia);
        }


    }
    const onHideShowListarGrupo = () => {
        if (SelecionarGrupo(false))
            setShowListarGrupo(false);

    }
    const getData = async (semana) => {

        const { data } = await api.postGetDate();

        if (data) {

            const date = converter.Date(data).data;


            const d = new Date(date);
            d.setDate(d.getDate() + 1)

            //  d.setDate(d.getDate());
            while (d.getDay() != semana) {
                console.log("semana: ", semana, "log dia " + d.getDay(), d.toDateString());
                d.setDate(d.getDate() - 1);


            }
            set_semana(d.getDay())
            d.setDate(d.getDate() - 1);
            console.log("log dia..", d.getDate(), d.getDay());




            set_dia(d.toISOString());
            return await d.toISOString();

        }


    }

    useEffect(() => {

        ListarGrupo();

        set_m_congregacao_id(TK().congregracao_id);



    }, []);



    return (
        <div>


            <div className='lider-body'>


                {/* Adição de Novo Membro */}
                <div className='lider-form'>

                    <Button onClick={() => navegar('/pg/inicio')}>Voltar</Button>

                    <label className='lider-cabecario'>Confimar Presença</label>
                    <label className='membro-label-grupo hover' onClick={() => ListarGrupo()}>{grupo_nome}</label>
                    <label className='lider-label-dados'>{converter.Dates(dia).date}</label>

                    <div>

                        <Table hover striped>
                            <thead>
                                <tr>
                                    <th>
                                        Membro
                                    </th>
                                    <th>
                                        Tipo
                                    </th>
                                    <th>
                                        Chamada
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {lista.map((value) => (
                                     
                                   
                                    <tr >
                                        <td>
                                            {value.m_membro}
                                        </td>
                                        <td>
                                            {listar_tipo[value.m_tipo]}
                                        </td>
                                        <td align='center'>
                                            {value.p_id ?
                                                <label>
                                                    Presente
                                                </label>
                                                :
                                                <Button onClick={() => SelecioneMembro(value)} variant='danger' style={{ width: 100, height: 40 }}>Confimar</Button>
                                            }
                                        </td>
                                    </tr> 
                                ))}
                            </tbody>
                        </Table>
                    </div>




                </div>
                <Modal //Show Grupo
                    show={showListarGrupo} fullscreen={false} onHide={() => onHideShowListarGrupo()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <label>Lista dos Grupos</label> <button style={{ width: 80, backgroundColor: 'lightseagreen' }} onClick={() => navegar('/pg/inicio')}>Voltar</button>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table hover striped>
                            <thead>
                                <th>
                                    <tr>Nome do Grupo</tr>
                                </th>
                                <th>
                                    <tr>Dia</tr>
                                </th>
                                <th>
                                    <tr>horario</tr>
                                </th>
                             
                            </thead>
                            <tbody>
                                {lista_grupo.map((value) => (
                                     !value?.motivo?
                                    <tr className="hover" onClick={() => SelecionarGrupo(value)}>
                                        <td>
                                            {value.g_nome}
                                        </td>
                                        <td>
                                            {DiaSemana(value.g_dia)}
                                        </td>
                                        <td>
                                            {value.g_hora}
                                        </td>
                                      
                                    </tr>:""))
                                }
                            </tbody>
                        </Table>

                    </Modal.Body>
                </Modal>


                <Modal //modal Despesa
                    show={showListar} fullscreen={false} onHide={() => setShowListar(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <label>PG - {grupo_nome} </label>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Lista de Membro </label>
                    </Modal.Body>
                </Modal>

            </div>
        </div>
    );
};


export default Chamada;