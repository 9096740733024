import React from "react";
import converter from "../../Util/Converter";

function ListarDespesa({listar}) {
    const [total,setTotal] = React.useState(0);
    function ToReal(valor) {
        return String(parseFloat(valor).toFixed(2)).replace('.',',');
      }

        return (
            <>
              <div style={{width:"100%",display:"flex",flexDirection:"row",backgroundColor:"#1e222b"}}>  
                <label style={{width:"100%",textAlign:"center",fontSize:24,textDecorationLine:"underline",textDecorationColor:"#fff"}}>Lista de despesa</label>
              </div>
            {
                listar.map((list,index)=>(
                    index % 2 ===0 ?
                    <div key={index} style={{backgroundColor:"#FFF",width:"100%", display:"flex",flexDirection:"row",borderBottomStyle:"solid",borderBlockColor:"#1e222b"}}>
                    <label style={{color:"#000",fontSize:18,width:300}}>{converter.Dates( list.data).date}</label>
                    <label style={{color:"#000",width:"100%",fontSize:20}}> | {list.descricao}</label>
                    <div style= {{display:"flex",flexDirection:"row-reverse",width:"100%"}}>
                        <label style={{color:"#000",fontSize:20,width:100,textAlign:"right"}}>{converter.ConverterReal( list.valor)}</label>
                        <label style={{color:"#000",fontSize:20}}>R$</label>
                    </div>
                    </div>
                    :
                    <div key={index} style={{width:"100%", display:"flex",flexDirection:"row",borderBottomStyle:"solid",borderBlockColor:"black"}}>
                    <label style={{color:"#000",fontSize:18,backgroundColor:"#c9c9c9",width:300}}>{converter.Dates(list.data).date}</label>
                    <label style={{color:"#000",width:"100%",fontSize:20,backgroundColor:"#c9c9c9"}}> | {list.descricao}</label>
                    <div style= {{display:"flex",flexDirection:"row-reverse",width:"100%",backgroundColor:"#c9c9c9"}}>
                        <label style={{color:"#000",fontSize:20,width:100,backgroundColor:"#c9c9c9",textAlign:"right"}}>{converter.ConverterReal( list.valor)}</label>
                        <label style={{color:"#000",fontSize:20,backgroundColor:"#c9c9c9"}}>R$</label>
                    </div>
                    </div>
                  
                    
                ))

            }
         
            </>
        )
    }
export default ListarDespesa;