import React from "react";
import converter from "../../Util/Converter";

function ListarTalao({listar}) {
    const [total,setTotal] = React.useState(0);
    function ToReal(valor) {
        return String(parseFloat(valor).toFixed(2)).replace('.',',');
      }

        return (
            <>
              <div style={{width:"100%",display:"flex",flexDirection:"row",backgroundColor:"#1e222b"}}>  
                <label style={{width:"100%",textAlign:"center",fontSize:24,textDecorationLine:"underline",textDecorationColor:"#fff"}}>ULTIMO TALÃO</label>
              </div>
            {
                listar.map((list,index)=>(
                index<1?
                    <div key={index} style={{fontSize:26,backgroundColor:"#FFF",width:"100%", display:"flex",flexDirection:"column",borderBottomStyle:"solid",borderBlockColor:"#1e222b"}}>
                        <label style={{color:"#000"}}>{converter.Dates( list.data).date} -<span style={{fontWeight:"bold"}}>  Nº  {list.talao}</span></label>
                        <label style={{color:"#000"}}>DIZIMO <span style={{fontWeight:"bold"}}>R$: {converter.ConverterReal( list.dizimo)} </span></label>
                        <label style={{color:"#000"}}>OFERTA <span style={{fontWeight:"bold"}}>R$: {converter.ConverterReal( list.oferta)}</span></label>
                        <label style={{color:"#000"}}>TOTAL <span style={{fontWeight:"bold"}}>R$: {converter.ConverterReal( list.total)}</span></label>
                    </div>:""
                ))

            }
         
            </>
        )
    }
export default ListarTalao;