import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Inicio(){
    const navegar = useNavigate();
    const[senha,set_senha]=useState("");
    const [info,setInfo]=useState([]);
    const Inicio = () =>{

        const _info= localStorage.getItem('config');
        if(_info)
           setInfo(JSON.parse( _info));
            
     
       }
     
       useEffect(()=>{
         Inicio();
       },[])
     
    
    return(
      
   <div className="principal-pg-inicio">
    <div className="div-pg-inicio">
    <div  style={{display:'flex',flexDirection:'column',fontWeight:'bold'}}>
    <button style={{width:100}} onClick={()=>navegar('/')}>Sair</button>
    <label>{info.setor_nome}</label>
    <label>{info.congregacao_nome}</label>
    <label>{info.nome}</label>
    </div>
    <hr />
    <label style={{width:"100%",textAlign:'center',fontWeight:"bold",fontSize:20}}>Pequenos Grupos</label>
    <div style={{display:'flex',flexDirection:'column',alignSelf:"center",alignItems:'center',borderStyle:"groove",padding:20,width:300,borderRadius:10,backgroundColor:"#f88d3f"}}>
        <button className="button-pg-inicio"  onClick={()=>navegar('/pg/grupo')}>Grupo</button>
        <button className="button-pg-inicio"  onClick={()=>navegar('/pg/membro')}>Membro</button>
        <button className="button-pg-inicio"  onClick={()=>navegar('/pg/chamada')}>Chamada</button>
        <button className="button-pg-inicio"  onClick={()=>navegar('/pg/serie')}>Serie</button>
    </div>
</div>

   </div>
   );
}
export default Inicio;