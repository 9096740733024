import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import React, { useRef,useEffect,useState } from "react";
import { useReactToPrint } from "react-to-print";
import imgLogo from "../../../imagens/ad.jpg";
import NavBar from "../../../components/NavBar";
import api from "../../../services/api";
import converter from "../../../Util/Converter";
import './setorial.css';
import { ListarData } from "../../../hooks/datas";
function RelatorioSetorial() {


const  [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
const  [data, setData] = useState("");
 const [dia,setDia]=useState(0);
 const [mes,setMes]=useState(0);
 const [ano,setAno]=useState(0);
 const [mesano,setMesano] =useState("0");
 const [mesResumo,setMesResumo] =useState("");
 const [mesRelatorio,setMesRelatorio] = useState("");
 const [totalEntradas,setTotalEntradas] = useState("0,00");
 const [totalSaidas,setTtotalSaidas] = useState("0,00");
 const [saldo,setSaldo] =  useState("0,00");
 const [dizimo,setDizimo] = useState("0,00");
 const [oferta,setoferta] =  useState("0,00");
 const [listTalao,setListTalao] =  useState([]);
 const [listDespesa,setListDespesa] =  useState([]);
 const nomeMes =["","JANEIRO","FEVEREIRO","MARÇO","ABRIL","MAIO","JUNHO","JULHO","AGOSTO","SETEMBRO","OUTUBRO","NOVEMBRO","DEZEMBRO"];
 const rowsSaidas=["","","","","","","","","","","","","","","","","","","",""];
//-----------geral
const [setor, setSetor] = useState("0");
const [listSetor,setListSetor] =useState([]);
const [listCongregacao,setListCongregacao]= useState([{congregacao:"vazio",dizimo:'0.00',oferta:'0.00',total:'0.00',saida:'0.00',saldo:'0,00'}]);
const [listGeralCongregacao,setListGeralCongregacao]= useState([{}]);
const [valorGeral,setValorGeral] = useState({dizimo:'0.00',oferta:'0.00',total:'0.00',saida:'0.00',saldo:'0,00',sede:'0.00'});
const [valorTotal,setValorTotal] = useState({dizimo:'0.00',oferta:'0.00',total:'0.00',saida:'0.00',saldo:'0,00'});
const [listar,setListar] = useState([{},{},{},{},{},{},{}]);

const [subSede,setSubsede] = useState({ 
  congregacao: "SUB SEDE",
  dizimo: "0.00",
  oferta: "0.00" ,
  total:"0.00",
  despesa:"0.00",
  saldo:"0.00"});



async function getlistSetor(m){
  
  const list =await api.postGetRelatorioGeralSetor({
    info:info,
    setor:setor,
    group:1,
    mesano:m
  });
  console.log(list);
  if(list){
    const conEn = list.data[0];
    const conEnTotal = list.data[1];
    const conSaida = list.data[2];
    const conSaidaTotal = list.data[3];
    const subEn = list.data[5];
    const subSaida = list.data[6];
    const congToda = list.data[7];

    setValorGeral({
      dizimo:conEnTotal[0].dizimo,
      oferta:conEnTotal[0].oferta,
      total:conEnTotal[0].total,
      saida:conSaidaTotal[0].valor,
      saldo:converter.FSubtrairf(conEnTotal[0].total,conSaidaTotal[0].valor),
      sede:'0.00'});
   
    setSubsede({ 
      congregacao: "SUB SEDE",
      dizimo: subEn[0].dizimo,
      oferta: subEn[0].oferta ,
      total:  subEn[0].total,
      despesa:subSaida[0].valor,
      saldo:converter.FSubtrairf(subEn[0].total,subSaida[0].valor)
    });

    let nome="vazio";
    let entr={dizimo:"0.00",oferta:"0.00",total:"0.00"};
    let said={valor:"0.00"};
    let listCong=[];
   
    congToda.map((cong)=>{
       nome=cong.congregacao;
       entr={dizimo:"0.00",oferta:"0.00",total:"0.00"};
       said={valor:"0.00"};
       conEn.map((encong)=>{
       if(encong.congregacao === nome){
           entr={dizimo:encong.dizimo,oferta:encong.oferta,total:encong.total};
       }
      });
      conSaida.map((saidacong)=>{
        if(saidacong.congregacao == nome){
          said={valor:saidacong.valor};
        }
      });
      
      listCong.push({
        congregacao:nome,dizimo:entr.dizimo,oferta:entr.oferta,total:entr.total,saida:said.valor,saldo:converter.FSubtrairf(entr.total,said.valor)
      });
    });
    setListar(list);
    setListCongregacao(listCong);
    console.log("Setorial: ",subEn[0].dizimo);
  }
}


   
   const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      onAfterPrint: () => {
        return new Promise(() => {
          setShow(false);
        });
      }
    });
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }


const getData=()=>
{
    api.postGetDate({info}).then(({ data }) => {
      if(data){
     const date = converter.Date(data);
        setData(date.date);
        setDia(date.dia);
        setMes(date.mes);
        setAno(date.ano);
        setMesano(date.mes+""+date.ano)
      }
    });
}

useEffect(() => {
    getData();
    
  }, []);
 function onchangeListarData(ev) {
    let v = ev.target.value;

    setTtotalSaidas("0,00");
    setListDespesa([]);
    setListTalao([]);
    const texto = ev.target.options[ev.target.selectedIndex].text;
    setMesResumo(texto);
     getlistSetor(v);
   

 }

  return (
<   div style={{ backgroundColor:"#000"}}>
        <NavBar navegando={"Relatório Geral Setor"} />
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
        <div //Cabeçario
        style={{
            display:"flex",flexDirection:"column",borderRadius:15,backgroundColor:"#fff",
            width:"100%",marginLeft:10
            }}>
        <label>DATA DO SISTEMA: {data}</label>
        <label>SETOR: {info.nome_setor} </label>
        <label>CONGREGAÇÃO: {info.congregacao} </label>
        <label>{info.nome_cargo}: {info.nome} </label>

        </div>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
        <div style={{marginLeft:10}}>
        
        <Row className="mb-3">
          <Form.Group className="tm" as={Col}>
            <Form.Label style={{color:"#FFF"}}>RELATÓRIO DO MÊS</Form.Label>
            <Form.Select
              style={{ maxWidth: 300 }}
              onChange={onchangeListarData} //(e) => setTalao(e.target.value)}
              defaultValue=""
            >

            <option value=""></option>
          
{   
           ListarData().map((dta)=>(
           <option value={dta.mesano}>{dta.mesnome} DE {dta.ano}</option>
           ))
           }
            </Form.Select>
          </Form.Group>

        </Row>
        <Row
         className="mb-3"
        >
       
        </Row>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <div style={{color:"#FFF",display:"flex",flexDirection:"column",marginLeft:10}}>
            <p>RESUMO DO MÊS {mesResumo}</p>
            <p>DIZIMO:___________  {converter.Br( valorGeral.dizimo)}</p>
            <p>OFERTA:___________  {converter.Br( valorGeral.oferta)}</p>
            <p>ENTRADAS:_______    {converter.Br( valorGeral.total)}</p>
            <p>SAIDAS:___________ {converter.Br( valorGeral.saida)}</p>
            <p>SALDO TRANSF___     {converter.Br(valorGeral.saldo)}</p>
        </div>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
          <div >
            {/* <Button
              style={{height:60,width:200,borderRadius:5}}
              onClick={handlePrint}
              >IMPRIMIR
            </Button> */}
            <Button
                style={{height:60,width:200,borderRadius:5}}
                onClick={() => handleShow(true)}
              >VISUALIZAR
            </Button>
          </div>
        </div>
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
 
 <Modal //modal de pesquisa
         show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
            <Button
              style={{height:60,width:200,borderRadius:5}}
              onClick={handlePrint}
              >IMPRIMIR
            </Button>
           </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div //////////////////////////Impresãoooooooooo
              style={{flexDirection:"column",width:1133,height:"100%",background:"#FFF"}} >
                  <style type="text/css" media="print">{"@page { size: portrait; } @media print{#noprint{display:none}}"}</style>
                  <div style={{display:"flex",flexDirection:"column", width:1333,height:"100%",background:"#FFF"}} ref={componentRef}>
                      <div style={{display:"flex",flexDirection:"rows" }}>
                          <div style={{marginLeft:70}}> <img src={imgLogo} width="100" height="90" /></div>
                          <div style={{marginLeft:20}} className="geral-cabecario-text">
                            <label style={{fontSize:24,fontWeight:"bold"}} className="ft-c">IGREJA EVANGELICA  ASSEMBLÉIA DE DEUS MINISTÉRIO DO BELÉM  </label>
                            <label style={{fontSize:20,fontWeight:"bold"}} className="ft-c">Campo Circuito das Águas Amparo/SP</label>
                            <label style={{fontSize:20,fontWeight:"bold"}} className="ft-c">CNPJ: 01.139.613/0001-77  Inscrição Estadual ( INSENTA)</label>
                            <label style={{fontSize:19,fontWeight:"bold",color:"blue"}} className="ft-c">Presidente - Pr. Nilson Marcos Lima </label>
                            <label style={{fontSize:18,fontWeight:"bold"}} className="ftc"> Extrato Financeiro Setorial Referente ao Mês de {mesResumo} </label>  
                          </div>
                      </div> 
                <hr></hr> 
                
                <div style={{width:1024,backgroundColor:"whitesmoke"}}> 
                <label style={{width:1024,height:40,textAlign:"center",fontWeight:"bold"}}>SETOR DE {info.nome_setor}</label>  
                     <table>
                      <tr style={{backgroundColor:"#D9E1F2"}}>
                                  {/* <td style={{width:0,height:20,textAlign:"center"}}>.</td> */}
                                  <td style={{width:150,height:20,textAlign:"center",fontWeight:"bold"}}>CONGREGAÇÃO</td>
                                  <td style={{width:120,height:20,textAlign:"center",fontWeight:"bold"}}>ENTRADAS</td>
                                  <td style={{width:120,height:20,textAlign:"center",fontWeight:"bold"}}>SAÍDAS</td>
                                  <td style={{width:120,height:20,textAlign:"center",fontWeight:"bold"}}>SALDO/SUBSEDE</td>
                                  <td style={{width:120,height:20,textAlign:"center",fontWeight:"bold"}}>SALDO/SEDE</td>
                                  <td style={{width:120,height:20,textAlign:"center",fontWeight:"bold"}}>SALDO</td>
                                  <td style={{width:100,height:20,textAlign:"center",fontWeight:"bold"}}>% SALDO</td>
                      
                       </tr>
                      { 
                            listCongregacao.map((listMap) =>(
                          <tr>
                                  <td style={{width:150,height:20,fontWeight:"bold"}}>{listMap.congregacao}</td>
                                  <td style={{width:120,height:20,fontWeight:"bold"}}>R$<span style={{float:"right",paddingRight:2,color:"blue"}}> {converter.ConverterReal(listMap.total)}</span></td>
                                  <td style={{width:120,height:20,fontWeight:"bold"}}>R$<span style={{float:"right",paddingRight:2,color:"red"}}>  {converter.ConverterReal(listMap.saida)}</span></td>
                                  <td style={{width:120,height:20,fontWeight:"bold"}}>R$<span style={{float:"right",paddingRight:2,color:"blue"}}>  {converter.ConverterReal(listMap.saldo)}</span></td>
                                  <td style={{width:120,height:20,fontWeight:"bold"}}></td>
                                  <td style={{width:120,height:20,fontWeight:"bold"}}></td>
                                  <td style={{width:100,height:20,fontWeight:"bold"}}>%</td>
                          </tr>
                        ))
                        
                      }
                      <tr style={{backgroundColor:"blanchedalmond"}}>
                       <td style={{width:150,height:20,fontWeight:"bold"}}>{subSede.congregacao}</td>
                                  <td style={{width:120,height:20,fontWeight:"bold"}}>R$<span style={{float:"right",paddingRight:2,color:"blue"}}>  {converter.ConverterReal(subSede.total)}</span></td>
                                  <td style={{width:120,height:20,fontWeight:"bold"}}>R$<span style={{float:"right",paddingRight:2,color:"red"}}>  {converter.ConverterReal(subSede.despesa)}</span></td>
                                  <td style={{width:120,height:20,fontWeight:"bold"}}>R$<span style={{float:"right",paddingRight:2,color:"blue"}}>  {converter.ConverterReal(subSede.saldo)}</span></td>
                                  <td style={{width:120,height:20,fontWeight:"bold"}}></td>
                                  <td style={{width:120,height:20,fontWeight:"bold"}}></td>
                                  <td style={{width:120,height:20,fontWeight:"bold"}}></td>
                      </tr>
                     </table>
                     </div>

                  </div>
             </div>
          </Modal.Body>
        </Modal>
        </div>
  );
}

export default RelatorioSetorial;
