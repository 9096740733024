import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import imgLogo from "../../../imagens/ad.jpg";
import NavBar from "../../../components/NavBar";
import api from "../../../services/api";
import converter from "../../../Util/Converter";
import './geral.css';
import { Table } from "react-bootstrap";
import { ListarData } from "../../../hooks/datas";
function RelatorioGeral2() {

  //-----------geral
  const [listGeralCongregacao, setListGeralCongregacao] = useState([{}]);
  const [valorGeral, setValorGeral] = useState({ dizimo: '0.00', oferta: '0.00', total: '0.00', saida: '0.00', saldo: '0,00' });
  const [listGeralSetor, setListGeralSetor] = useState([]);
  const [listValor, setListValor] = useState([]);



  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      return new Promise(() => {
        setShow(false);
      });
    }
  });
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  ////////////////////////////////////////////////////////////////////////////////
  const [id_setor, setIdSetor] = useState(localStorage.getItem('id_setor') || "0");
  const [id_congregacao, setIdCongregacao] = useState(localStorage.getItem('id_congregacao') || "0");

  ////////////////////////////////////////////////////////////////////////////////
  const [info, setInfo] = useState(JSON.parse(localStorage.getItem('config')) || []);
  const [data, setData] = useState("");
  const [dia, setDia] = useState(0);
  const [mes, setMes] = useState(0);
  const [ano, setAno] = useState(0);
  const [mesano, setMesano] = useState("0");
  const [mesResumo, setMesResumo] = useState("");
  const [mesRelatorio, setMesRelatorio] = useState("");
  const [totalEntradas, setTotalEntradas] = useState("0,00");
  const [totalSaidas, setTtotalSaidas] = useState("0,00");
  const [saldo, setSaldo] = useState("0,00");
  const [dizimo, setDizimo] = useState("0,00");
  const [oferta, setoferta] = useState("0,00");
  const [listTalao, setListTalao] = useState([]);
  const [listDespesa, setListDespesa] = useState([]);
  const nomeMes = ["", "JANEIRO", "FEVEREIRO", "MARÇO", "ABRIL", "MAIO", "JUNHO", "JULHO", "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"];
  const rowsSaidas = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];



  const getData = () => {
    api.postGetDate({ info }).then(({ data }) => {
      if (data) {
        const date = converter.Date(data);
        setData(date.date);
        setDia(date.dia);
        setMes(date.mes);
        setAno(date.ano);
        setMesano(date.mes + "" + date.ano)
      }
    });
  }
  useEffect(() => {
    getData();


  }, []);

  function onchangeListarData(ev) {
    let v = ev.target.value;

    setTtotalSaidas("0,00");
    setListDespesa([]);
    setListTalao([]);
    
              
 const texto = ev.target.options[ev.target.selectedIndex].text;
 setMesResumo(texto);
     

    Listar(v);
    //geral

  }


  async function Listar(mes) {

    const mes_ano = mes;

    let lista = [];
    const { data } = await api.post("/relatorio_geral_mesal_v2", {
      mes_ano, info
    });
    const _entrada_total = data?.entrada_total[0]?.total || 0.00;
    const _despesa_total = data?.despesa_total[0]?.valor || 0.00;
    const _saldo_total = _entrada_total - _despesa_total;
    var _setor = "";
    var _setor_id = "";
    var _congregacao = "";
    var _dizimo = 0.00;
    var _oferta = 0.00;
    var _total = 0.00;
    var _valor = 0.00;
    var _porcentagem = 0.00;
    var _saldo = 0.00;

    for (var i = 0; i < 150; i++) {
      _setor = "";
      _setor_id = "";
      _congregacao = "";
      _dizimo = 0.00;
      _oferta = 0.00;
      _total = 0.00;
      _valor = 0.00;
      _porcentagem = 0.00;
      _saldo = 0.00;
      const _entrada = data?.entrada.filter(f => f.id_congregacao == i);
      const _despesa = data?.despesa.filter(f => f.congregacao == i);

      if (_entrada[0]?.nome_setor) {
        _setor = _entrada[0]?.nome_setor;
        _setor_id = _entrada[0]?.id_setor;

      }


      if (_entrada[0]?.congregacao)
        _congregacao = _entrada[0]?.congregacao;

      if (_entrada[0]?.dizimo)
        _dizimo = _entrada[0]?.dizimo;

      if (_entrada[0]?.oferta)
        _oferta = _entrada[0]?.oferta;

      if (_entrada[0]?.total)
        _total = _entrada[0]?.total;


      if (_despesa?.nome_setor) {
        _setor = _despesa[0]?.nome_setor;
        _setor_id = _despesa[0]?.fk_setor;
      }
      if (_despesa[0]?.congregacao)
        _congregacao = _despesa[0]?.congregacao;

      if (_despesa[0]?.valor)
        _valor = _despesa[0]?.valor;


      _saldo = _total - _valor;

      _porcentagem = (_saldo / _saldo_total) * 10;
      if (_setor != "")
        lista.push({
          setor: _setor,
          setor_id: _setor_id,
          congregacao: _congregacao,
          dizimo: _dizimo,
          oferta: _oferta,
          total: _total,
          despesa: _valor,
          saldo: _saldo,
          porcentagem: _porcentagem
        })
    }
    const _list = [];

    for (var i = 0; i < 50; i++) {
      const l = lista.filter(f => f.setor_id == i);
      if (l[0]?.setor_id)
        _list.push(l)
    }

    setListValor(_list);
    //console.log("total", _saldo_total, "Entrada", _list);
  }

 function trValor(valor){
  console.log(valor)
   valor.map((v)=>{
     console.log("td ... ",v);
    
          <tr><td>!</td></tr>
    
     });

 }
  return (
    <   div style={{ backgroundColor: "#000" }}>
      <NavBar navegando={"Relatório Geral"} />
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
      <div //Cabeçario
        style={{
          display: "flex", flexDirection: "column", borderRadius: 15, backgroundColor: "#fff",
          width: "100%", marginLeft: 10
        }}>
        <label>DATA DO SISTEMA: {data}</label>
        <label>SETOR: {info.nome_setor} </label>
        <label>CONGREGAÇÃO: {info.congregacao} </label>
        <label>{info.nome_cargo}: {info.nome} </label>

      </div>
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
      <div style={{ marginLeft: 10 }}>

        <Row className="mb-3">
          <Form.Group className="tm" as={Col}>
            <Form.Label style={{ color: "#FFF" }}>RELATÓRIO DO MÊS</Form.Label>
            <Form.Select
              style={{ maxWidth: 300 }}
              onChange={onchangeListarData} //(e) => setTalao(e.target.value)}
              defaultValue=""
            >
              <option value=""></option>
              {   
           ListarData().map((dta)=>(
           <option value={dta.mesano}>{dta.mesnome} DE {dta.ano}</option>
           ))
           }
            </Form.Select>
          </Form.Group>
        </Row>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <div style={{ color: "#FFF", display: "flex", flexDirection: "column", marginLeft: 10 }}>
          <p>RESUMO DO MÊS {mesResumo}</p>
          <p>DIZIMO:___________ R$ {converter.ConverterReal(valorGeral.dizimo)}</p>
          <p>OFERTA:___________ R$ {converter.ConverterReal(valorGeral.oferta)}</p>
          <p>ENTRADAS:_______ R$   {converter.ConverterReal(valorGeral.total)}</p>
          <p>SAIDAS:___________ R$ {converter.ConverterReal(valorGeral.saida)}</p>
          <p>SALDO EM CAIXA ___ R$    {valorGeral.saldo}</p>
        </div>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <div >
          <Button
            style={{ height: 60, width: 200, borderRadius: 5 }}
            onClick={() => handleShow(true)}
          >VISUALIZAR
          </Button>
        </div>
      </div>
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>

      <Modal //modal de pesquisa
        show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Button
              style={{ height: 60, width: 200, borderRadius: 5 }}
              onClick={handlePrint}
            >IMPRIMIR
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div //////////////////////////Impresãoooooooooo
            style={{ flexDirection: "column", width: 1133, height: "100%", background: "#FFF" }} >
            <style type="text/css" media="print">{"@page { size: portrait; } @media print{#noprint{display:none}}"}</style>
            <div style={{ display: "flex", flexDirection: "column", width: 1333, height: "100%", background: "#FFF" }} ref={componentRef}>
              <div style={{ display: "flex", flexDirection: "rows" }}>
                <div style={{ marginLeft: 70 }}> <img src={imgLogo} width="100" height="90" /></div>
                <div style={{ marginLeft: 20 }} className="geral-cabecario-text">
                  <label style={{ fontSize: 24, fontWeight: "bold" }} className="ft-c">IGREJA EVANGELICA  ASSEMBLÉIA DE DEUS MINISTÉRIO DO BELÉM  </label>
                  <label style={{ fontSize: 20, fontWeight: "bold" }} className="ft-c">Campo Circuito das Águas Amparo/SP</label>
                  <label style={{ fontSize: 20, fontWeight: "bold" }} className="ft-c">CNPJ: 01.139.613/0001-77  Inscrição Estadual ( INSENTA)</label>
                  <label style={{ fontSize: 19, fontWeight: "bold", color: "blue" }} className="ft-c">Presidente - Pr. Nilson Marcos Lima </label>
                  <label style={{ fontSize: 18, fontWeight: "bold" }} className="ftc"> Extrato Financeiro Geral Referente ao Mês de {mesResumo} </label>
                </div>
              </div>
              <hr></hr>
              <div>
                <Table>
                  <tr style={{ backgroundColor: "#D9E1F2" }}>
                    {/* <td style={{width:0,height:20,textAlign:"center"}}>.</td> */}
                    <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>SETOR</td>
                    <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>CONGREGAÇÃO</td>
                    <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>ENTRADAS</td>
                    <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>SAÍDAS</td>
                    <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>SALDO/SUBSEDE</td>
                    <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>SALDO/SEDE</td>
                    <td style={{ width: 150, height: 20, textAlign: "center", fontWeight: "bold" }}>SALDO EM CAIXA</td>
                    <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>% SALDO</td>

                  </tr>
                  <tbody>
                    {
                       listValor.map((valor)=>(
                          valor.map((c)=>(
                            <tr>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{c.setor}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{c.congregacao}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{c.total}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{c.despesa}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{c.saldo}</td>
                            <td style={{ width: 150, height: 20, textAlign: "center", fontWeight: "bold" }}>{}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{c.porcentagem}</td>
                          </tr>
                          ))          
                       ))
                    }
                  </tbody>
                </Table>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RelatorioGeral2;
