import moment from "moment/moment";

class Converter
{
    constructor()
    {

    }
    Br(floatNumero)
    {
      var valor=parseFloat(floatNumero);
      return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    }

    Us(RealNumero)
    {
      var valor=parseFloat(RealNumero);
      return valor.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    FSomarf(num1,num2)
    {
      return parseFloat( parseFloat(num1||"0.00") + parseFloat(num2||"0.00")).toFixed(2)
       
    }
    FSubtrairf(num1,num2)
    {
      return parseFloat( parseFloat(num1||"0.00") - parseFloat(num2||"0.00")).toFixed(2)
       
    }
    SomarFloatTransf(num1,num2,num3)
    {
      return this.ConverterReal(  parseFloat(
        parseFloat(num1) + parseFloat(num2)-parseFloat(num3)
        ).toFixed(2));
       
    }
    SomarFloatTransfSaida(num1,num2)
    {
      return this.ConverterReal(  parseFloat(
        parseFloat(num1)-parseFloat(num2)
        ).toFixed(2));
       
    }
    SomarFloat(num1,num2)
    {
      return this.ConverterReal(  parseFloat (parseFloat(num1) + parseFloat(num2)).toFixed(2));
       
    }
    Somar(num1,num2)
    {
      return parseFloat(num1) + parseFloat(num2);
       
    }
    SubTrair(num1,num2)
    {
      return parseFloat(num1) - parseFloat(num2);
       
    }
    ConverterReal(valor) {
      var t =this.ToReal(valor);
      if(t==null)
       t=0;
      try{
      t= t.replace(",","");
      }catch{}

      var resposta="";
      for (var i = t.length - 1; i >= 0; i--) {
        var j = t.length - i;
  
        // Adiciona o caractere na resposta.
        var resposta = t.charAt(i) + resposta;
  
        // Colocar uma vírgula ou um ponto se for o caso.
       
            if (j == 2) {
                resposta = "," + resposta;
            } else if (j % 3 == 2 && i != 0) {
                resposta = "." + resposta;     
            }
        
      }
      return resposta;
    }
    ToReal(valor) {
      try
      {
        valor= valor.replace(".",",")
      }
      catch
      {}
      return valor;
    }
    ToFloat(valor) {
     
      try
      {
        valor= valor.replace(".","")
      }
      catch
      {}
      try
      {
        valor= valor.replace(",",".")
      }
      catch
      {}
      return valor;
       
    }
    DateSort(date)
    {
      let dt=date; 
      dt= dt.split('/');
      let d=dt[0];
      let m =dt[1];
      let a =dt[2];
      return {date:d+"/"+m+"/"+a,data:a+"-"+m+"-"+d, dia:d, mes:m, ano:a}
    }
    Date(date)
    {
     
      let dt =date;
      dt= dt.split('/');
      let d=dt[0];
      let m =dt[1];
      let a =dt[2];
      return {date:d+"/"+m+"/"+a,data:a+"-"+m+"-"+d, dia:d, mes:m, ano:a}
    }
    Dates(date)
    {
      let dt=date;
      try
      {
      dt =date.split('T')[0];
      dt= dt.split('-');
      }catch{ dt=[

      ]}
      let d=dt[2];
      let m =dt[1];
      let a =dt[0];
      return {date:d+"/"+m+"/"+a,data:a+"-"+m+"-"+d, dia:d, mes:m, ano:a}
    }
    
}
const converter = new Converter()
export default converter