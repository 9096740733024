import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import NavBar from "../../components/NavBar";
import api from "../../services/api";
import { useState, useEffect, useRef } from "react";
import "./dizimista.css";
import { useReactToPrint } from "react-to-print";
import ReactToPrint from 'react-to-print';
import { Link } from "react-router-dom";
import imgLogo from "../../imagens/adbelem.png";
import converter from "../../Util/Converter";
import { ListarData } from "../../hooks/datas";


function DizimistaGeral() {
    const [setor, setSetor] = useState(0);
    const [congregacao, setCongregacao] = useState(0);
    const [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
    const [dia,setDia]=useState(0);
    const [mes,setMes]=useState(0);
    const [ano,setAno]=useState(0);
    
    const [dizimo,setDizimo] = useState("0,00");
    
    const [listCongragacao,setListCongregacao] =useState([]);
    const [listSetor,setListSetor] =useState([]);
    const [dizimista, setDizimista] = useState("");
    const [id, setId] = useState(0);
    const [talao, setTalao] = useState(0);
    const [dataDizimo, setDataDizimo] = useState("");
    const [mesano, setMesano] = useState(0);

    function alterarDizmista (){
        api
          .UpdateDizimistaGeral( {
            id: id,
            dizimista:dizimista,
            dizimo: converter.ToFloat(dizimo),
            info:info,
         
          })
          .then(({ data }) => {
            if (data) {
              alert("Dizimista alterado com sucesso!");
              getDizmistaMensal(mesano,congregacao);
              setShow(false);
            }
          });
      };
    function excluir()
      {
        api
        .ExcluirDizimistaGeral( {
          id:id,
          info:info,
        })
        .then(({ data }) => {
          if (data) {
            alert("Dizimista Excluido com sucesso!"); 
            getDizmistaMensal(mesano,congregacao); 
            setShow(false);
          }
        });
      }
    function exibir(lista)
    { 
        setDataDizimo(lista.data)
        setTalao(lista.talao);
        setId(lista.id);
        setDizimista(lista.membro);
        setDizimo(converter.ConverterReal(lista.dizimo));
        handleShow(true);
       
    }
    function alterar()
    { 
        alterarDizmista();
        setShow(false);
    }

  
  //////////////////////////////////////////////////////////////////////////////
  
  /////////////////////////////////////////////////////////////////////////////
  
  const [listDizimista, setListDizimista] = useState([]);  
  const [valorTotal, setValorTotal] = useState("0,00");

  function selectSetor(ev) {
    let v = ev.target.value;
   
    setSetor(v);
    if(v>0)
    {
        getCongragacao(v);
       
    }   
    //inputRef.current.value='';
  }
  function selectCongregacao(ev) {
    let v = ev.target.value;
    setCongregacao(v);
    getDizmistaMensal(mesano,v)
    //inputRef.current.value='';
  }
  const getCongragacao=(val)=>
  {
      api.postGetCongregacaoSetor({
        setor:val,
        info:info,
      }).then(({ data }) => {
        if(data){
         setListCongregacao(data);
        }
      });
  }
  const getSetor=()=>
  {
      api.postGetSetor({info}).then(({ data }) => {
        if(data){
         setListSetor(data);
        }
      });
  }
  const getDizmistaMensal = (m,c) => {
    api
      .postGetDizimistaMensal( {
        setor: setor,
        congregacao: c,
        info:info,
        mesano:m,
        grupo:2
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setListDizimista(data[2]); 
          setValorTotal(data[1][0].dizimo) ; 
        }
      });
  };
 
  const getData = () => {
    api.postGetDate({info}).then(({ data }) => {
      if (data) {
        let date =converter.Date(data);
        setDia(date.dia);
        setMes(date.mes);
        setAno( date.ano);
      }
    });
  };

  useEffect(() => {
    getData();
    getSetor();
   
  }, []);

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(false);
    setShow(true);
  }


  function handleLogin(e) {
    e.preventDefault();
  }

  function onchangeListarData(ev) {
    let v = ev.target.value;
     setListDizimista([{}])
     setMesano(v);
     getDizmistaMensal(v,congregacao);

 }


  return (

    <div style={{backgroundColor:"#000"}} className="container-culto">      
    <div>
        <NavBar navegando="ALTERAR DIZIMISTA" />
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <Form style={{width:"95%",marginLeft:10}} onSubmit={handleLogin}>
        <div //Cabeçario
        style={{
            display:"flex",flexDirection:"column",borderRadius:15,backgroundColor:"#fff",
            width:"100%",marginLeft:10,color:"#000"
            }}>
            <label>
              DATA DO SISTEMA: {dia}/{mes}/{ano}
            </label>
            <label>SETOR: {info.nome_setor} </label>
            <label>CONGREGAÇÃO: {info.congregacao} </label>
            <label>
              {info.nome_cargo}: {info.nome}{" "}
            </label>
          </div>
          <Row
         className="mb-3"
        >
            <Form.Group className="tm" as={Col}>
            <Form.Label style={{color:"#FFF"}}>SETOR</Form.Label>
            <Form.Select 
              style={{ maxWidth: 300 }}
              onChange={selectSetor}
              defaultValue="0"
            >
               <option value="0" >{""}</option>
             {    
                  listSetor.map((list,index)=>(
                  <option key={index} value={""+list.id_setor}>{list.nome_setor}</option>
                  ))
              }
            
            </Form.Select>
          </Form.Group>
          </Row>
          <Row>
          <Form.Group className="tm" as={Col}>
          <Form.Label style={{color:"#FFF"}}>CONGREGAÇÃO</Form.Label>
            <Form.Select 
                style={{ maxWidth: 300 }}
              onChange={selectCongregacao }
              defaultValue="0"
            >
               <option value="0" >{""}</option>
             {    
                   listCongragacao.map((list,index)=>(
                  <option key={index} value={""+list.id_congregacao}>{list.congregacao}</option>
                  ))
              }
            </Form.Select>
          </Form.Group>
          </Row>
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr> 
           <div //Cabeçario
        style={{
            display:"flex",flexDirection:"column",borderRadius:15,
            width:"100%",marginLeft:10
            }}></div>
                  <Row className="mb-3">
          <Form.Group className="tm" as={Col}>
            <Form.Label style={{color:"#FFF"}}>RELATÓRIO DO MÊS</Form.Label>
            <Form.Select
              style={{ maxWidth: 300 }}
              onChange={onchangeListarData} //(e) => setTalao(e.target.value)}
              defaultValue=""
            >
               <option value=""></option>
              {   
           ListarData().map((dta)=>(
           <option value={dta.mesano}>{dta.mesnome} DE {dta.ano}</option>
           ))
           }
            </Form.Select>
          </Form.Group>
  
          </Row>
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>

      
          <div
            style={{
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            
            <div style={{width:"100%",display:"flex",flexDirection:"row",backgroundColor:"#1e222b"}}>  
                <label style={{width:"100%",textAlign:"center",fontSize:24,textDecorationLine:"underline",textDecorationColor:"#fff"}}>Lista de dizimista</label>
              </div>
            {
                listDizimista.map((list,index)=>(
                    index % 2 ===0 ?
                    <div key={index} style={{width:"100%", display:"flex",flexDirection:"row",borderBottomStyle:"solid",borderBlockColor:"#1e222b"}}>
                    <label onClick={() =>exibir(list)} style={{color:"#000",width:"100%",fontSize:20}}>{list.membro}</label>
                    <div style= {{display:"flex",flexDirection:"row-reverse",width:"100%"}}>
                        <label style={{color:"#000",fontSize:20,width:80,textAlign:"right"}}>{converter.ToReal(list.dizimo)}</label>
                        <label style={{color:"#000",fontSize:20}}>R$</label>
                    </div>
                    </div>
                    :
                    <div key={index} style={{width:"100%", display:"flex",flexDirection:"row",borderBottomStyle:"solid",borderBlockColor:"black"}}>
                    <label onClick={() =>exibir(list)} style={{color:"#000",width:"100%",fontSize:20,backgroundColor:"#c9c9c9"}}>{list.membro}</label>
                    <div style= {{display:"flex",flexDirection:"row-reverse",width:"100%",backgroundColor:"#c9c9c9"}}>
                        <label style={{color:"#000",fontSize:20,width:80,backgroundColor:"#c9c9c9",textAlign:"right"}}>{converter.ToReal(list.dizimo)}</label>
                        <label style={{color:"#000",fontSize:20,backgroundColor:"#c9c9c9"}}>R$</label>
                    </div>
                    </div>
                  
                    
                ))

            }
           


          </div>
          <div
            style={{
              width: "100%",
              height: 60,
              display: "flex",
              flexDirection: "row-reverse",
              backgroundColor: "#1e222b",
            }}
          >
            <label style={{ fontSize: 24 }}>
              TOTAL R$ {converter.ConverterReal( valorTotal)}
            </label>
          </div>
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>
        </Form>
      </div>

      <Modal //modal de pesquisa
        show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Row //Nome do igreja, data de batismo
                className="mb-3"
                >
                    <Form.Group as={Col}>
                        <Form.Label className="lbl-list-dizimista">Alterar ou Excluir Dizimista</Form.Label> 
                    </Form.Group>
                </Row>
                <label style={{color:"#c9c9c9"}}>Talão: {talao} {" | "} {converter.Dates(dataDizimo).date}</label>
                <Row //Nome do igreja, data de batismo
                className="mb-3"
                >
                    <Form.Group as={Col}>
                        <Form.Label className="label-igreja">{id}-{dizimista}</Form.Label> 
                    </Form.Group>
                </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
            <Row //Nome do igreja, data de batismo
            
            className="mb-3"
            >
                <Form.Group as={Col}>
                <Form.Label className="label-igreja">Nome do Dizimista</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Digite o nome "
                    style={{ width:400}}
                    value={dizimista}
                    onChange={(e)=> setDizimista(e.target.value)}
                />
                </Form.Group>
            </Row>
            <Row //Nome do igreja, data de batismo
            className="mb-3"
            >
                <Form.Group as={Col}>
                <Form.Label className="label-igreja">Valor do Dizimo</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Digite o valor do dizimo"
                    style={{ width:400}}
                    value={dizimo}
                    onChange={(e)=> setDizimo(e.target.value)}
                />
                </Form.Group>
            </Row>
            <div style={{display:"flex",flexDirection:"column",alignItems:"center",alignContent:"center"}}>
                    <Button onClick={alterar} className="btn-alterar">Alterar</Button>

                    <Button onClick={excluir} className="btn-excluir">Excluir</Button>
            </div>
        </Modal.Body>
        </Modal>

     </div>

  );
}

export default DizimistaGeral;
