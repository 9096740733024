import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import React, { useRef,useEffect,useState } from "react";
import { useReactToPrint } from "react-to-print";
import imgLogo from "../../../imagens/ad.jpg";
import NavBar from "../../../components/NavBar";
import api from "../../../services/api";
import converter from "../../../Util/Converter";
import './setor.css';
import { ListarData } from "../../../hooks/datas";
function RelatorioGeralSetor() {

const  [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
const  [data, setData] = useState("");
 const [dia,setDia]=useState(0);
 const [mes,setMes]=useState(0);
 const [ano,setAno]=useState(0);
 const [mesano,setMesano] =useState("0");
 const [mesResumo,setMesResumo] =useState("");
 const [mesRelatorio,setMesRelatorio] = useState("");
 const [totalEntradas,setTotalEntradas] = useState("0,00");
 const [totalSaidas,setTtotalSaidas] = useState("0,00");
 const [saldo,setSaldo] =  useState("0,00");
 const [dizimo,setDizimo] = useState("0,00");
 const [oferta,setoferta] =  useState("0,00");
 const [listTalao,setListTalao] =  useState([]);
 const [listDespesa,setListDespesa] =  useState([]);
 const nomeMes =["","JANEIRO","FEVEREIRO","MARÇO","ABRIL","MAIO","JUNHO","JULHO","AGOSTO","SETEMBRO","OUTUBRO","NOVEMBRO","DEZEMBRO"];
 const rowsSaidas=["","","","","","","","","","","","","","","","","","","",""];
//-----------geral
const [setor, setSetor] = useState("0");
const [listSetor,setListSetor] =useState([]);
const [listCongregacao,setListCongregacao]= useState([{}]);
const [listGeralCongregacao,setListGeralCongregacao]= useState([{}]);
const [valorGeral,setValorGeral] = useState({dizimo:'0.00',oferta:'0.00',total:'0.00',saida:'0.00',saldo:'0,00',sede:'0.00'});
const [valorTotal,setValorTotal] = useState({dizimo:'0.00',oferta:'0.00',total:'0.00',saida:'0.00',saldo:'0,00'});
const [listGeralSetor,setListGeralSetor] = useState([{}]);

const [subsede,setSubsede] = useState({ setor:"",
  congregacao: "SUB SEDE",
  dizimo: "0.00",
  oferta: "0.00" ,
  total:"0.00",
  despesa:"0.00",
  saldo:"0.00"});
const [subsedeVisivel,setSubsedeVisivel] = useState(false);
const [isSede,setIsSede] = useState(false);




async function GeralSetor(m,s)
{
  const dados= await api.postGetRelatorioGeralSetor({
    setor: s,
    mesano:m,
    info:info,
    grupo:0,
  })
  .then(( {data} ) => {
    return data;
  });
  const des= dados[1];
  const en= dados[0];

  let lista=[];
  let valores={}
  valores.dizimo='0.00';
  valores.oferta= '0.00';
  valores.total=  '0.00';
  valores.despesas='0.00';

  let sub={}
  sub.setor='';
  sub.congregacao='';
  sub.dizimo='0.00';
  sub.oferta= '0.00';
  sub.total=  '0.00';
  sub.despesa='0.00';
  sub.saldo="0.00";
  let despesa="0.00";
  let nome_congregacao="";
 
  let subDespesas="0.00";


  setSubsedeVisivel(true);
      en.map((value)=>{ 
       
        if(value.tipo < 1){
                des.map((valor)=>{
                  if(value.id_congregacao==valor.id_congregacao)
                  {
                    despesa=valor.valor==null?'0.00':valor.valor; 
                    //nome_congregacao = value.congregacao  
                  }
                  });
                  valores.dizimo= converter.Somar(valores.dizimo==null?'0.00':valores.dizimo,value.dizimo ==null?'0.00':value.dizimo);
                  valores.oferta= converter.Somar(valores.oferta==null?'0.00':valores.oferta,value.oferta ==null?'0.00':value.oferta);
                  valores.total=  converter.Somar(valores.total==null?'0.00':valores.total,value.total ==null?'0.00':value.total);
                  valores.despesas=converter.Somar(valores.despesas==null?'0.00':valores.despesas,despesa ==null?'0.00':despesa);
                  lista=[...lista,
                  {
                    setor:value.nome_setor,
                    congregacao: value.congregacao,
                    dizimo:value.dizimo==null?'0.00':value.dizimo,
                    oferta:value.oferta==null?'0.00':value.oferta,
                    total:value.total==null?'0.00':value.total,
                    saida:despesa,
                    saldo:converter.FSubtrairf(value.total==null?'0.00':value.total,despesa)
                  }]
                  despesa="0.00";
                }else{
                     if(value.tipo==2)
                         setIsSede(true);
                      if(value.tipo==1)
                         setIsSede(false);
                    des.map((valor)=>{
                        if(value.id_congregacao==valor.id_congregacao)
                        {
                          despesa=valor.valor==null?'0.00':valor.valor; 
                          //nome_congregacao = value.congregacao  
                        }
                      });
                    
                      sub.setor=value.nome_setor;
                      sub.congregacao= value.congregacao;
                      sub.dizimo= value.dizimo ==null?'0.00':value.dizimo;
                      sub.oferta= value.dizimo ==null?'0.00':value.dizimo;
                      sub.total= value.total ==null?'0.00':value.total;
                      sub.despesa=despesa ==null?'0.00':despesa;
                      sub.saldo=converter.FSubtrairf(value.total==null?'0.00':value.total,despesa);
                  
                      despesa="0.00";


                }

              });  
              setSubsede({
                setor:sub.setor,
                congregacao:sub.congregacao,
                dizimo: sub.dizimo,
                oferta: sub.oferta ,
                total:sub.total,
                despesa:sub.despesa,
                saldo:sub.saldo
              });
              setValorGeral({
              dizimo:parseFloat( valores.dizimo==null?'0.00':valores.dizimo).toFixed(2),
              oferta:parseFloat(valores.oferta==null?'0.00':valores.oferta).toFixed(2),
              total:parseFloat(converter.FSomarf( valores.total==null?'0.00':valores.total,sub.total)).toFixed(2),
              saida:parseFloat(converter.FSomarf( valores.total==null?'0.00':valores.total, sub.despesa)).toFixed(2),
              saldo:converter.FSubtrairf(valores.total, valores.despesas),
              sede:sub.saldo
              });
    
  setListGeralSetor(lista);
 

}

   const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      onAfterPrint: () => {
        return new Promise(() => {
          setShow(false);
        });
      }
    });
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }


const getData=()=>
{
    api.postGetDate({info}).then(({ data }) => {
      if(data){
     const date = converter.Date(data);
        setData(date.date);
        setDia(date.dia);
        setMes(date.mes);
        setAno(date.ano);
        setMesano(date.mes+""+date.ano)
      }
    });
}

useEffect(() => {
    getData();
    getSetor();
  }, []);
 function onchangeListarData(ev) {
    let v = ev.target.value;

    setTtotalSaidas("0,00");
    setListDespesa([]);
    setListTalao([]);
              
 const texto = ev.target.options[ev.target.selectedIndex].text;
 setMesResumo(texto);

     getCongragacao(setor);
     setMesano(v);
     GeralSetor(v,setor);
   

 }

 const getSetor=()=>
 {
     api.postGetSetor({info}).then(({ data }) => {
       if(data){
        setListSetor(data);
       }
     });
 }

 function selectSetor(e){
    let v = e.target.value;
    setSetor(v);
    getCongragacao(v);
    GeralSetor(mesano,v);
 }

  const getCongragacao= async(val)=>
  {
     const list= await api.postGetCongregacaoSetor({
        setor:val,
        info:info,
      }).then(({ data }) => {
        if(data){
         return data;
        }
      });
      setListCongregacao(list);
  }
  return (
<   div style={{ backgroundColor:"#000"}}>
        <NavBar navegando={"Relatório Geral Setor"} />
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
        <div //Cabeçario
        style={{
            display:"flex",flexDirection:"column",borderRadius:15,backgroundColor:"#fff",
            width:"100%",marginLeft:10
            }}>
        <label>DATA DO SISTEMA: {data}</label>
        <label>SETOR: {info.nome_setor} </label>
        <label>CONGREGAÇÃO: {info.congregacao} </label>
        <label>{info.nome_cargo}: {info.nome} </label>

        </div>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
        <div style={{marginLeft:10}}>
        
        <Row className="mb-3">
          <Form.Group className="tm" as={Col}>
            <Form.Label style={{color:"#FFF"}}>RELATÓRIO DO MÊS</Form.Label>
            <Form.Select
              style={{ maxWidth: 300 }}
              onChange={onchangeListarData} //(e) => setTalao(e.target.value)}
              defaultValue=""
            >
            <option value=""></option>
            {   
           ListarData().map((dta)=>(
           <option value={dta.mesano}>{dta.mesnome} DE {dta.ano}</option>
           ))
           }


            </Form.Select>
          </Form.Group>

        </Row>
        <Row
         className="mb-3"
        >
        <Form.Group className="tm" as={Col}>
        <Form.Label style={{color:"#FFF"}}>SETOR</Form.Label>
            <Form.Select
            style={{width:300}}
            onChange={selectSetor} 
             defaultValue="1">
                <option value={"0"}>{"TODOS"}</option>
              {    
                  listSetor.map((list,index)=>(
                  <option key={index} value={""+list.id_setor}>{list.nome_setor}</option>
                  ))
              }
            
            </Form.Select>
          </Form.Group>
        </Row>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <div style={{color:"#FFF",display:"flex",flexDirection:"column",marginLeft:10}}>
            <p>RESUMO DO MÊS {mesResumo}</p>
            <p>DIZIMO:___________  {converter.Br( valorGeral.dizimo)}</p>
            <p>OFERTA:___________  {converter.Br( valorGeral.oferta)}</p>
            <p>ENTRADAS:_______    {converter.Br( valorGeral.total)}</p>
            <p>SAIDAS:___________ {converter.Br( valorGeral.saida)}</p>
            { setor==1
              ?
              <p>SALDO EM CAIXA___     {converter.Br(valorGeral.sede)}</p>
              :
              <p>SALDO TRANSF______     {converter.Br(valorGeral.sede)}</p>
            }
           
        </div>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
          <div >
            {/* <Button
              style={{height:60,width:200,borderRadius:5}}
              onClick={handlePrint}
              >IMPRIMIR
            </Button> */}
            <Button
                style={{height:60,width:200,borderRadius:5}}
                onClick={() => handleShow(true)}
              >VISUALIZAR
            </Button>
          </div>
        </div>
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
 
         <Modal //modal de pesquisa
         show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
            <Button
              style={{height:60,width:200,borderRadius:5}}
              onClick={handlePrint}
              >IMPRIMIR
            </Button>
           </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div //////////////////////////Impresãoooooooooo
              style={{flexDirection:"column",width:1133,height:"100%",background:"#FFF"}} >
                  <style type="text/css" media="print">{"@page { size: portrait; } @media print{#noprint{display:none}}"}</style>
                  <div style={{display:"flex",flexDirection:"column", width:1333,height:"100%",background:"#FFF"}} ref={componentRef}>
                      <div className="geral-cabecario">
                          <div className="geral-img"> <img src={imgLogo} width="100" height="90" /></div>
                          <div className="geral-cabecario-text">
                            <label style={{fontSize:24,fontWeight:"bold"}} className="ft-c">IGREJA EVANGELICA  ASSEMBLÉIA DE DEUS </label>
                            <label style={{fontSize:20}} className="ft-c">Ministério do Belém - Campo Circuito das Águas Amparo/sp</label>
                            <label style={{fontSize:19,fontWeight:"bold"}} className="ft-c">Pr. Nilson Marcos Lima - Presidente</label>
                          </div>
                      </div> 
                      <label style={{fontSize:18,fontWeight:"bold"}} className="ftc"> FINANCEIRO GERAL  - REFERENTE  AO MÊS DE {mesResumo} </label>  
                    <div> 
                      { 
                        
                        listGeralSetor.map((value,index)=>(
                          
                          <div key={index} >
                                <div>
                                  <label className="ft">SETOR: {value.setor}</label>
                                </div>
                                <div>
                                  <label className="ft">CONGREGAÇÃO: {value.congregacao}</label>
                                </div>
                                <div >
                                  <label className="ft w200">DIZIMO</label>
                                  <label className="ft-a w150"><span  className="spn" > {converter.Br(value.dizimo)}</span></label>
                                </div>
                                <div >
                                   <label className="ft w200">OFERTA</label>
                                   <label className="ft-a w150"><span  className="spn"  > {converter.Br(value.oferta)}</span></label>
                                </div>
                                 <div>
                                   <label className="ft w400">ENTRADAS</label>
                                   <label className="ft-a-n w150"><span  className="spn" > {converter.Br(value.total)}</span></label>
                                </div> 
                                <div>
                                   <label className="ft-r w400">DESPESAS PAGAS</label>
                                   <label className="ft-r-a w150"><span  className="spn" >{converter.Br(value.saida)}</span></label>
                                </div> 
                                <div >
                                   <label className="ft-b w600">SALDO</label>
                                   <label className="ft-b-a w150"><span  className="spn" >{converter.Br(value.saldo)}</span></label>
                                </div>
                            
                             
                             
                             
                              <hr></hr>
                          </div>
                          
                        ))
                      }
                      { subsedeVisivel ?
                        <div style={{backgroundColor:"#C9C9C9"}}>
                         
                                <div>
                                  <label className="ft">SETOR: {subsede.setor}</label>
                                </div>
                                <div>
                                  <label className="ft">CONGREGAÇÃO: {subsede.congregacao}</label>
                                </div>
                                <div >
                                  <label className="ft w200">DIZIMO</label>
                                  <label className="ft-a w150"><span  className="spn" > {converter.Br(subsede.dizimo)}</span></label>
                                </div>
                                <div >
                                   <label className="ft w200">OFERTA</label>
                                   <label className="ft-a w150"><span  className="spn"  > {converter.Br(subsede.oferta)}</span></label>
                                </div>
                                 <div>
                                   <label className="ft w400">ENTRADAS</label>
                                   <label className="ft-a-n w150"><span  className="spn" > {converter.Br(subsede.total)}</span></label>
                                </div> 
                                <div>
                                   <label className="ft-r w400">DESPESAS PAGAS</label>
                                   <label className="ft-r-a w150"><span  className="spn" >{converter.Br(subsede.despesa)}</span></label>
                                </div> 
                                <div >
                                   <label className="ft-b w600">SALDO</label>
                                   <label className="ft-b-a w150"><span  className="spn" >{converter.Br(parseFloat( subsede.saldo).toFixed(2))}</span></label>
                                </div>
                          
                              <hr></hr>
                          </div>
                          :""
                          }
                          <div style={{backgroundColor:"#c9c999",height:250}}>
                              <div >
                                          <label className="ft w200">TOTAL DE ENTRADAS</label>
                                          <label className="ft-a-n w150"><span  className="spn" >{converter.Br(valorGeral.total)}</span></label>
                              </div>  
                              <div >
                                          <label className="ft-r w400">DESPESAS PAGAS</label>
                                          <label className="ft-r-a w150"><span  className="spn" >{converter.Br(valorGeral.saida)}</span></label>
                              </div> 
                              
                              <div >    
                                          <label className="ft-b w600">{ 
                                           isSede?
                                            "TRANSFERENCIA P/ SEDE":
                                            "TRANSFERENCIA P/ SUB SEDE"

                                           } 
                                            </label>
                                          <label className="ft-b-a w150"><span  className="spn" >{converter.Br(valorGeral.saldo)}</span></label>
                              </div> 
                              <div >
                                          <label className="ft-b w600">
                                          {isSede?
                                            "SALDO EM CAIXA":
                                            "TRANSFERENCIA P/ SEDE"

                                          }
                                            </label>
                                          <label className="ft-b-a w150"><span  className="spn" >{converter.Br(valorGeral.sede)}</span></label>
                              </div> 
                          </div>
                     </div>

                   </div>
              </div>
          </Modal.Body>
        </Modal>
        </div>
  );
}

export default RelatorioGeralSetor;
