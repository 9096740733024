import React from "react";

function ListarSetor({listar}) {
    const [total,setTotal] = React.useState(0);
   

        return (
            <>
              <div style={{width:"100%",display:"flex",flexDirection:"row",backgroundColor:"#1e222b"}}>  
                <label style={{width:"100%",textAlign:"center",fontSize:24,textDecorationLine:"underline",textDecorationColor:"#fff"}}>Lista de Setor</label>
              </div>
            {
                listar.map((list,index)=>(
                    index % 2 ===0 ?
                    <div key={index} style={{backgroundColor:"#FFF",width:"100%", display:"flex",flexDirection:"row",borderBottomStyle:"solid",borderBlockColor:"#1e222b"}}>
                        <label style={{color:"#000",width:"100%",fontSize:20}}>{list.nome_setor}</label>
                    </div>
                    :
                    <div key={index} style={{width:"100%", display:"flex",flexDirection:"row",borderBottomStyle:"solid",borderBlockColor:"black"}}>
                        <label style={{color:"#000",width:"100%",fontSize:20,backgroundColor:"#c9c9c9"}}>{list.nome_setor}</label>
                    </div>
                  
                    
                ))

            }
         
            </>
        )
    }
export default ListarSetor;