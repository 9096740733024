import Grupo from '../PG/grupo';
import Lider from '../PG/lider';
import Membro from '../PG/membro';
import Chamada from '../PG/chamada';
import Inicio from '../PG/inicio';
import Serie from '../PG/serie';

export const routesPG = [
    {
        path: '/pg/serie',
        element: <Serie />
    },
    {
        path: '/pg/grupo',
        element: <Grupo />
    },
    {
        path: '/pg/lider',
        element: <Lider />
    },
    {
        path: '/pg/membro',
        element: <Membro />
    },
    {
        path: '/pg/chamada',
        element: <Chamada/>
    },
    {
        path: '/pg/inicio',
        element: <Inicio />
    },
];