
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './style.css';
import { Button, Form, InputGroup, Modal, Tab, Table, Tabs } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import { Get } from '../Api';
import { URL_SERVIDOR } from '../Api/env';

import { CopyToClipboard } from 'react-copy-to-clipboard';

const Lider = () => {
  const [copie, set_copie] = useState(false);
  const [lider_num, set_lider_num] = useState(1);
  const [lider_id, set_lider_id] = useState("");
  const [showListar, setShowListar] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [setor_id, set_setor_id] = useState(1);
  const [congregacao_id, set_congregacao_id] = useState("");
  const [membro_id, set_membro_id] = useState("");
  const [nome, set_nome] = useState("");
  const [tel, set_tel] = useState("");
  const [login_pre, set_login_pre] = useState("@pglider");
  const [login, set_login] = useState("");
  const [senha, set_senha] = useState("");
  const [ativo, set_ativo] = useState("");
  const [is_alterar, set_is_alterar] = useState(false);
  const [list_setor, set_list_setor] = useState([]);
  const [list_congregacao, set_list_congregacao] = useState([]);
  const [list_lider, set_list_lider] = useState([]);
  const [info, setInfo] = useState(JSON.parse(localStorage.getItem('config')) || []);

  function gerarSenha() {
    // Define os caracteres possíveis para a senha
    var caracteres = "abcdefghkmnpqrsuvwxyzABCDEFGHKMNPQRSUVWXYZ23456789!@#$%&";
    var tamanhoSenha = 6; // Altere para o tamanho desejado

    // Gera uma senha aleatória
    var senha = "";
    for (var i = 0; i < tamanhoSenha; i++) {
      var posicaoAleatoria = Math.floor(Math.random() * caracteres.length);
      var caractereAleatorio = caracteres.charAt(posicaoAleatoria);
      senha += caractereAleatorio;
    }

    // Exibe a senha gerada
    set_senha(senha.toLocaleLowerCase());
  }

  const getSetor = async () => {
    const { data } = await Get('/pg/congregacao/setor');
    console.log(data);
    if (data?.sucess)
      set_list_setor(data.data)
  }

  const getCongregacao = async (setor) => {



    const { data } = await Get(`/pg/congregacao/fk_setor?setor_id=${setor || setor_id}`);
    console.log(data);
    if (data?.sucess)
      set_list_congregacao(data.data);
  }

  const Salvar = async () => {
    if (!nome)
      return alert("Digite o Nome");
    if (!tel)
      return alert("Digite o Telefone");
    if (!senha)
      return alert("Digite a senha");
    const { data } = await axios.post(`${URL_SERVIDOR}/pg/lider`, {
      setor_id,
      congregacao_id,
      membro_id: 0,
      nome,
      tel,
      login: login_pre,
      senha,
      token: 0,
      ativo: 1,
      lider_num
    });
    console.log("registrador ", data)
    if (data?.data?.affectedRows) {

      alert("lider registrado com sucesso!!!");
      await Novo();
    }
  }

  const Novo = () => {
    //alert('Novo');
    set_nome("");
    set_tel("");
    NovoLider();
    gerarSenha();
    Listar();
    set_is_alterar(false)
  }

  const Alterar = async () => {
    // alert('Alterar');
    if (!nome)
      return alert("Digite o Nome");
    if (!tel)
      return alert("Digite o Telefone");
    if (!senha)
      return alert("Digite a senha");
    const { data } = await axios.put(`${URL_SERVIDOR}/pg/lider`, {
      lider_id,
      setor_id,
      congregacao_id,
      membro_id: 0,
      nome,
      tel,
      senha,
    });
    console.log("alterador ", data)
    if (data?.data?.affectedRows) {

      alert("lider alterado com sucesso!!!");
      await Novo();
    }
  }

  const Cancelar = () => {
    set_is_alterar(false);
    //alert('Cancelar');
    Novo();
  }

  const Desativar = async() => {
    //alert('Excluir');
    const { data } = await axios.patch(`${URL_SERVIDOR}/pg/lider`, {
      setor_id
    });
    console.log("desativado ", data?.data?.affectedRows)
    if (data?.data?.affectedRows) {

      alert("lider desativado com sucesso!!!");
      await Novo();
    }
  }

  const Listar = async () => {
    //alert('Listar');
    const { data } = await axios.get(`${URL_SERVIDOR}/pg/lider/listar_lider?setor_id=${info?.id_setor}&congregacao_id=${info?.id_congregacao}`);
    console.log("Listar ... .  ", data);
    if (data?.sucess)
      set_list_lider(data?.data);


  }
  const NovoLider = async () => {
    //alert('Listar');
    const { data } = await axios.get(`${URL_SERVIDOR}/pg/lider/novo_lider?setor_id=${info?.id_setor}&congregacao_id=${info?.id_congregacao}`);
    console.log("Novo Lider ... .  ", data.data[0]?.numero);
    if (data?.data[0]?.numero) {
      var num = data?.data[0]?.numero + 1;
      set_login_pre(info?.login.split('@')[0] + "@pglider" + num);
      set_lider_num(num);
    }
    else {
      set_login_pre(info?.login.split('@')[0] + "@pglider" + 1);
      set_lider_num(1);
    }



  }

  const SelecionarLider = (value) => {
    //set_copie(false)

    setShowListar(false);
    set_is_alterar(true);
    set_lider_id(value.lider_id)
    set_nome(value.nome);
    set_tel(value.tel);
    set_senha(value.senha);
    set_login_pre(value.login);


  }


  const Inicio = () => {

    set_congregacao_id(info?.id_congregacao);
    set_setor_id(info?.id_setor);
    gerarSenha();
    NovoLider();
    Listar();

  }

  useEffect(() => {

    Inicio();

  }, []);



  return (
    <div>
      <NavBar navegando={"Cad. Lider"} />t
      <div className='lider-body'>


        {/* Adição de Novo Membro */}
        <div className='lider-form'>
          <label className='lider-cabecario'>Adicionar Lider</label>
          <label className='lider-label-dados'>Dados do Lider</label>
          {true ? <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <input className='lider-input' value={info.nome_setor} disabled />
            <input className='lider-input' value={info.congregacao} disabled />
          </div>
            :
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <select className='lider-input' value={setor_id} onChange={(e) => set_setor_id(e.target.value)} >
                <option value={""}></option>
                {
                  list_setor.map((value) => (
                    <option value={value.setor_id}>{value.nome_setor}</option>))}
              </select>

              <select value={congregacao_id} onChange={(e) => set_congregacao_id(e.target.value)} >
                <option value={""}></option>
                {
                  list_congregacao.map((value) => (
                    <option value={value.id_congrecao}>{value.congregacao}</option>))
                }
              </select>
            </div>}
          <input type="text" name="l_membro_id" placeholder="Nome Completo" value={nome} onChange={(e) => set_nome(e.target.value.toUpperCase())} />

          <input type="text" name="l_tel" placeholder="Celular" value={tel} onChange={(e) => set_tel(e.target.value)} />
          <label className='lider-label-dados'>Login e Senha</label>
          <InputGroup>
            <InputGroup.Text style={{ width: "100%", textAlign: "center" }} id="btnGroupAddon">Login</InputGroup.Text>
          </InputGroup>
          <InputGroup>

            <InputGroup.Text style={{ width: "100%", textAlign: "center" }} id="btnGroupAddon">{login_pre}</InputGroup.Text>
          </InputGroup>
          <InputGroup>
            <InputGroup.Text className='lider-gerar' style={{ width: 200 }} id="btnGroupAddon" onClick={() => gerarSenha()}>Gerar Senha</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Senha"
              aria-label="Input group example"
              aria-describedby="btnGroupAddon"
              value={senha}
              onChange={(e) => set_ativo(e.target.value)}
            />
          </InputGroup>
          {is_alterar ?

            <div style={{ paddingRight: 10, marginBottom: 30, display: "flex", flexDirection: "row-reverse", gap: 10 }}>
              <Button style={{ width: 100 }} variant='success' onClick={Alterar}>Alterar</Button>
              <Button style={{ width: 100 }} variant='warning' onClick={Cancelar}>Cancelar</Button>
              <Button style={{ width: 100 }} variant='danger' onClick={Desativar}>Desativar</Button>
            </div>
            :
            <div style={{ paddingRight: 10, marginBottom: 30, display: "flex", flexDirection: "row-reverse", gap: 10 }}>

              <Button style={{ width: 100 }} variant='success' onClick={Salvar}>Salvar</Button>
              <Button style={{ width: 100 }} onClick={Novo}>Novo</Button>
              <Button style={{ width: 100 }} variant='warning' onClick={() => setShowListar(true)}>Listar</Button>
            </div>

          }

        </div>

        <Modal //modal Despesa
          show={showListar} fullscreen={false} onHide={() => setShowListar(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div>
                <label>Liders já cadastrados</label>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey="alterar"
              id="uncontrolled-tab-example"
              className="mb-3">
              <Tab eventKey="alterar" title="Alterar">
                <div style={{ height: 400, overflow: "auto" }}>
                  <Table hover>
                    <thead>
                      <tr>
                        <th>Nome</th>

                        <th>Login</th>

                        <th>Senha</th>

                        <th>Celular</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list_lider.map((value) => (
                        <tr className='hover' onClick={() => SelecionarLider(value)}>
                          <td>{value.nome}</td>
                          <td>{value.login}</td>
                          <td>{value.senha}</td>
                          <td>{value.tel}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                </div>
              </Tab>
              <Tab eventKey="copiar" title="Copiar">
                <div style={{ height: 400, overflow: "auto" }}>
                  <Table hover>
                    <thead>

                      <tr>
                        <th>Login</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list_lider.map((value) => (
                        <tr>


                          <td>
                            <CopyToClipboard style={{ width: "100%", }} text={`Pequenos Grupos\n\nhttp://adbelemamparo.com.br\n\nLogin: ${value.login}\nSenha: ${value.senha}`}
                            >
                              <span className='hover'  >{value.login}</span>
                            </CopyToClipboard>
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </Table>

                </div>
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>


      </div>

    </div>
  );
};

export default Lider;