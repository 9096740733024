import NavBar from "../../components/NavBar"

export default function Home()
{
    return(
        <div>
            <NavBar/>
            <h1>Página Home</h1>
        </div>
    )
}