import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useEffect, useState } from 'react';
import './navbar.css';
import fundo from '../imagens';
import { useNavigate } from 'react-router-dom';
import { SYS } from '../../../Api/env';

function NavBar({ navegando }) {
  const navigate = useNavigate();

  ////////////////////////////////////////////////////////////////////////////////
 

  const Carregar = () => {

       
    

  }
  function handleSair() {
    localStorage.setItem('config',[]);
    navigate("/"); 
  }
  useEffect(()=>{
    const info = localStorage.getItem("config");
    if(info)
 if (!JSON.parse(info).secretaria){
       handleSair();
   }

  },[])
  return (
    <>
   
    <Navbar className='nav-principal' expand="lg">
        <button style={{color:"red",fontWeight: "bold"}} className="nav-btn" onClick={handleSair}>SAIR</button>
      <Container className='teste'> 
     
      
        <Navbar.Brand className='nav-adbelem' >AD BELÉM AMPARO</Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          
              
                  <Nav className="me-auto" >
                  <Nav.Link className="nav-tesouraria"  href="/secretaria/inicio" >INÍCIO</Nav.Link>
                  {/* <Nav.Link className="nav-tesouraria"  href="/relatorio" >RELATÓRIO</Nav.Link> */}
            
                 <NavDropdown style={{fontWeight:"bold"}} title="RELATÓRIO" id="basic-nav-dropdown">
                  {/* <NavDropdown.Item href="/relatorio/batismo">BATISMO</NavDropdown.Item> */}
                  <NavDropdown.Divider />
                 
                </NavDropdown>
             
          </Nav>
          </Nav>

        </Navbar.Collapse>
       <Navbar.Brand className='nav-adbelem' >{navegando}</Navbar.Brand>
      </Container>
      {/* <div className='navegado'>{navegando}</div> */}
    </Navbar>
  
    </>
  );
}

export default NavBar;