import "./imprimir.css";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import imgLogo from "../../imagens/adbelem.png";
import NavBar from "../NavBar";

class ComponentToPrint extends React.Component {
  render() {
    return (
        
      <div style={{width:1550,height:"100%"}}>

        <div style={{display:"flex",width:"100%",height:100,backgroundColor:"#c9c9c9"}}>
        
          <div 
            style={{display:"flex",width:220,height:200,backgroundColor:"#fff",justifyContent:"center",
            }}><img src={imgLogo} width="200" height="180"/>
          </div>
          <div style={{width:"100%",height:"100%",backgroundColor:"#FFF"}}>
          <div style={{width:"100%",height:40,backgroundColor:"#FFF"}}>
          <label style={{width:750,fontSize:28,fontWeight:"bold",textAlign:"center"}}>RELATÓRIO FINANCEIRO</label>
                
                <label style={{fontSize:26}}>folha n° 01</label>
          </div>
            <div style={{width:"100%",height:50,backgroundColor:"#FFF"}}>
         
                <label style={{width:750,fontSize:24,textAlign:"center",fontWeight:"bold"}}>IGREJA EVANGÉLICA ASSEMBLÉIA DE DEUS</label>
                <label style={{fontSize:20,fontWeight:"bold",marginRight:5}}>SETOR:</label>
                <label style={{fontSize:20,fontWeight:"bold"}}>AMPARO</label>
            </div>
            <div style={{width:"100%",height:40,backgroundColor:"#FFF"}}>
                <label style={{width:750,textAlign:"center",fontSize:18}}>MINISTÉRIO DE BELÉM - AMPARO - SP</label>
                <label style={{marginRight:10, fontSize:16,fontWeight:"bold"}}>CONGREGAÇÃO</label>
                <label style={{textAlign:"center",fontSize:16}}>TRÊS PONTES</label>
            </div>
            <div style={{width:"100%",height:70,backgroundColor:"#FFF"}}>
                <label style={{width:750,backgroundColor:"#FFF"}}></label>
                <label style={{fontWeight:"bold",fontSize:20}}>REFERENTE AO MÊS</label>
                <label style={{marginLeft:5,fontSize:20}}>MAIO</label>
                <label style={{marginLeft:5,fontWeight:"bold",fontSize:20}}>DE</label>
                <label style={{marginLeft:5,fontWeight:"bold",fontSize:20}}>2022</label>  
            </div>
          </div>
        </div>
      </div>
    );
  }
}



export function Imprimir() {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    return (
      <>
         <NavBar navegando="RELATÓRIO"/>
      <div style={{backgroundColor:"#c9c9c9"}}>
      <button style={{width:200,height:60,borderRadius:15}} onClick={handlePrint}>DEZEMBRO</button>
      </div>
      <div>
       
        <ComponentToPrint
         ref={componentRef} 
         />
     
      </div>
      </>
          );
}
