import "./logo.css";
import Figure from "react-bootstrap/Figure";
import { Link } from "react-router-dom";
import logo from "../../imagens/adbelem.png";

export function Logo() {
  return (
    <div className="container-logo">
         <img className="img-logo" src={logo} />
          <h2 className="ad">AD</h2>
          <h2 className="belem">BELÉM</h2>
    </div>
  );
}
