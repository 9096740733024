
import Membro from '../secretaria/membro/cadastro.js';

import Inicio from '../secretaria/inicio/inicio.js';
import Calendario from '../secretaria/calendario/index.js';

export const routesSecretaria = [
    {
        path: '/secretaria/calendario',
        element: <Calendario />
    },
    {
        path: '/secretaria/membro/cadastro',
        element: <Membro />
    },
    {
        path: '/secretaria/inicio',
        element: <Inicio />
    },
      
];