import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useEffect, useState } from 'react';
import './navbar.css';
import imgLogo from '../../imagens/adbelem.png';
import { useNavigate } from 'react-router-dom';
function NavBar({ navegando }) {
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  ////////////////////////////////////////////////////////////////////////////////
  function handleSair() {
    localStorage.setItem('config', []);
    navigate("/login");
  }
  const Inicio = () => {

    const _info = localStorage.getItem('config');
    if (_info)
      setInfo(JSON.parse(_info));


  }

  useEffect(() => {
    Inicio();
  }, [])

  return (
    <>

      <Navbar style={{ backgroundColor: "white" }} bg="light" expand="lg">
        <button style={{ color: "red", fontWeight: "bold" }} className="nav-btn" onClick={handleSair}>SAIR</button>
        <Container className='teste'>


          <Navbar.Brand className='nav-adbelem' >AD BELÉM AMPARO</Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Navbar.Brand href="/inicio" >INICIO</Navbar.Brand>
              {
                info.tesouraria == 1 && info.geral == 0 ?
                  <Nav className="me-auto" >
                    <Nav.Link className="nav-tesouraria" href="/culto" >CULTO</Nav.Link>
                    <Nav.Link className="nav-tesouraria" href="/tesouraria/dizimista">DIZIMISTA</Nav.Link>
                    <Nav.Link className="nav-tesouraria" href="/despesa">CONTAS A PAGAR</Nav.Link>
                    <Nav.Link className="nav-tesouraria " href="/relatorio/tesouraria_mensal">EXTRATO DE MOVIMENTACÃO</Nav.Link>
                    <Nav.Link className="nav-tesouraria" href="/relatorio/dizimista_mensal">DIZIMISTA MENSAL</Nav.Link>
                  </Nav>

                  : ""
              }
              {info.secretaria == 3 ?
                <Nav.Link href="/cadastro_membro">MEMBRO</Nav.Link>
                : ""
              }


              {info.geral == 3 ?
                <NavDropdown style={{ fontWeight: "bold" }} title="CADASTRO" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/cadastro_membro">MEMBRO</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/cadastro_igreja">IGREJA</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/setor">SETOR</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/administrador">ADMINISTRADOR</NavDropdown.Item>
                </NavDropdown>
                : ""
              }
              {(info.geral == 2 || info.geral == 1) && info.tesouraria == 1 ?
                <NavDropdown style={{ fontWeight: "bold" }} title="FINANCEIRO" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/culto">CULTO</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/despesa">CONTAS A PAGAR</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/tesouraria/dizimista">DIZIMISTA</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/relatorio/tesouraria_mensal">EXTRATO DE MOVIMENTACÃO</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/relatorio/dizimista_mensal">DIZIMISTA MENSAL</NavDropdown.Item>
                </NavDropdown>
                : ""
              }

              {info.geral == 3 ?
                <NavDropdown style={{ fontWeight: "bold" }} title="FINANCEIRO" id="basic-nav-dropdown">

                  <NavDropdown.Item href="/culto">CULTO</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/tesouraria/culto/saldo/sede">SALDO SEDE</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/despesa">CONTAS A PAGAR</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/tesouraria/dizimista">DIZIMISTA</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/geral/relatorio_mensal">EXTRATO DE MOVIMENTACÃO (SEDE)</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/relatorio/tesouraria_mensal">EXTRATO DE MOVIMENTACÃO</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/relatorio/dizimista_mensal">DIZIMISTA MENSAL</NavDropdown.Item>

                </NavDropdown>
                : ""
              }
              {/* {info.geral == 3 ? //ebd
                <NavDropdown style={{ fontWeight: "bold" }} title="EBD" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/ebd/matricula">MATRICULA</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/ebd/cadastro_aluno">CADASTRO DE ALUNO</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/ebd/cadastro_professor">CADASTRO DE PROFESSOR</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/ebd/cadastro_licao">CADASTRO DE LIÇÃO</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/ebd/cadastro_classe">CADASTRO DE CLASSE</NavDropdown.Item>
                </NavDropdown>
                : ""
              } */}
              {(info.geral == 2 || info.geral == 3) && info.tesouraria == 1 ?
                <NavDropdown style={{ fontWeight: "bold" }} title="RELATORIO" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/relatorio/relatorio_geral2">GERAL 2</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/relatorio/geral">GERAL</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/relatorio/geral/setor">SETOR</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/relatorio/geral/congregacao">CONGREGAÇÃO</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/relatorio/geral/dizimista_mensal">DIZIMISTA MENSAL</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/relatorio/geral/talao_lancado">TALÕES LANÇADOS</NavDropdown.Item>

                </NavDropdown>
                : ""
              }
              {info.geral == 1 ?
                <NavDropdown style={{ fontWeight: "bold" }} title="RELATORIO" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/relatorio/setorial">SETORIAL</NavDropdown.Item>
                  <NavDropdown.Item href="/relatorio/setorial/congregacao">CONGREGAÇÃO</NavDropdown.Item>


                </NavDropdown>
                : ""
              }
              {info.geral == 3 ?
                <NavDropdown style={{ fontWeight: "bold" }} title="RELATORIO SETOR" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/relatorio/setorial">SETORIAL</NavDropdown.Item>
                  <NavDropdown.Item href="/relatorio/setorial/congregacao">CONGREGAÇÃO</NavDropdown.Item>


                </NavDropdown>
                : ""
              }
              {info.geral == 3 ?
                <NavDropdown style={{ fontWeight: "bold" }} title="ALTERAR" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/alterar/dizimista_geral">DIZIMISTA</NavDropdown.Item>
                  <NavDropdown.Item href="/alterar/relatorio_geral">RELATORIO</NavDropdown.Item>
                </NavDropdown>
                : ""
              }
              {info.geral == 3 ?
                <NavDropdown style={{ fontWeight: "bold" }} title="SECRETARIA" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/secretaria/inicio">INICIO</NavDropdown.Item>
                  <NavDropdown.Item href="/batismo/login">LOGIN BATISMO</NavDropdown.Item>
                  <NavDropdown.Item href="/batismo/relatorio">RELATORIO BATISMO</NavDropdown.Item>

                </NavDropdown>
                : ""
              }
              {info.secretaria == 1 && info.geral == 2 ?
                <NavDropdown style={{ fontWeight: "bold" }} title="SECRETARIA" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/secretaria/inicio">INICIO</NavDropdown.Item>
                  <NavDropdown.Item href="/batismo/login">LOGIN BATISMO</NavDropdown.Item>
                  <NavDropdown.Item href="/batismo/relatorio">RELATORIO BATISMO</NavDropdown.Item>

                </NavDropdown>
                : ""
              }

              <NavDropdown style={{ fontWeight: "bold" }} title="PGRUPO" id="basic-nav-dropdown">
                <NavDropdown.Item href="/pg/lider">Cad.. Lider</NavDropdown.Item>
              </NavDropdown>
              {/*<Nav.Link href="/imprimir">Imprimir</Nav.Link>*/}
            </Nav>
          </Navbar.Collapse>

        </Container>

      </Navbar>
      <div className='navegado'>{navegando}</div>
    </>
  );
}

export default NavBar;