import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import NavBar from "../../components/NavBar";
import "./administrador.css";
import api from "../../services/api";
import { useState, useEffect } from "react";
import Axios from "axios";
import { Table } from "react-bootstrap";

function Administrador() {
  ///////////////////////////////////////////////////////////////////////////////
  const [info, setInfo] = useState(JSON.parse(localStorage.getItem('config')) || []);
  const [idAdmin, setIdAdmin] = useState(localStorage.getItem('id') || "Não Logado");
  const [user, setUser] = useState(localStorage.getItem("user") || "Não Logado!");
  const [geral, setGeral] = useState(localStorage.getItem("geral") || 0);
  const [cargoAdmin, setCargoAdmin] = useState(
    localStorage.getItem("cargo") || "Não logado"
  );
  const [id_setor, setIdSetor] = useState(localStorage.getItem('id_setor') || "0");
  const [id_congregacao, setIdCongregacao] = useState(localStorage.getItem('id_congregacao') || "0");


  ////////////////////////////////////////////////////////////////////////////////
  const [id, setId] = useState("");
  const [setor, setSetor] = useState("");
  const [congregacao, setCongregacao] = useState("1");
  const [cargo, setCargo] = useState("1");
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [nome, setNome] = useState("");
  const [celular, setCelular] = useState("");
  const [tesouraria, setTesouraria] = useState("0");
  const [secretaria, setSecretaria] = useState("0");
  const [listSetor, setListSetor] = useState([]);
  const [listCargo, setListCargo] = useState([]);
  const [listCongragacao, setListCongregacao] = useState([]);
  const [listAdmin, setListAdmin] = useState([]);
  const [listAdminPesquisar, setListAdminPesquisar] = useState([]);

  const getCongragacao = (val) => {
    api.postGetCongregacaoSetor({
      setor: val,
      info: info,
    }).then(({ data }) => {
      if (data) {
        setListCongregacao(data);
      }
    });
  }

  const getCargo = () => {
    api.postGetCargo({ info }).then(({ data }) => {
      if (data) {
        setListCargo(data);
      }
    });
  }
  const getSetor = () => {
    api.postGetSetor({ info }).then(({ data }) => {
      if (data) {
        setListSetor(data);
      }
    });
  }
  const getAdmin = () => {

    api.post('/v2/administrador/get', { info }).then(({ data }) => {
      if (data) {
        setListAdminPesquisar(data);
        setListAdmin(data);
        console.log(data)
      }
    });
  }
  useEffect(() => {

    getCargo();
    getSetor();
    getAdmin();

  }, []);

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  function handleLogin(e) {
    e.preventDefault();
   
   
  }



  
  function selectSetor(ev) {
    let v = ev.target.value;
    //console.log("id setor",v)
    setSetor(v);
    if (v > 0)
      getCongragacao(v);

  }

  const [bloquear, set_bloquear] = useState({
    pesquisar: false,
    novo: false,
    alterar: true,
    cancela: true,
    salvar: true,
    excluir: true,
    formulario: true,
    upadate: true
  });

  function Limpar() {
    set_bloquear({
      pesquisar: false,
      novo: false,
      alterar: true,
      cancela: true,
      salvar: true,
      excluir: true,
      formulario: true,
      update: true
    });
    setLogin("");
    setNome("");
    setSenha("");
    setCelular("");
  }
  function Pesquisar() {
    setShow(true);

  }
  function Novo() { }
  function Alterar() {
    set_bloquear({
      pesquisar: true,
      novo: true,
      alterar: true,
      cancela: false,
      salvar: false,
      excluir: true,
      formulario: false,
      update: false
    });

  }
  function Excluir() { }
  function Cancelar() {
    Limpar();
  }
  function Salvar() { 
    if (!bloquear.upadate) {
      api.putSetAdministrador({
        id: id,
        setor: setor,
        congregacao: congregacao,
        cargo: cargo,
        login: login,
        senha: senha,
        nome: nome,
        celular: celular,
        tesouraria: tesouraria,
        secretaria: secretaria,
        info: info,
      })
        .then((response) => {

          alert(response.data.msg);
        });

    } 
    else {

      api.postSetAdministrador({
        setor: setor,
        congregacao: congregacao,
        cargo: cargo,
        login: login,
        senha: senha,
        nome: nome,
        celular: celular,
        tesouraria: tesouraria,
        secretaria: secretaria,
        info: info,
      })
        .then((response) => {

          alert(response.data.msg);
        });
    }


  }
  function SelectAdmin(ad) {

    set_bloquear({
      pesquisar: true,
      novo: true,
      alterar: false,
      cancela: false,
      salvar: false,
      excluir: false,
      formulario: true,
      update: true
    });
    setId(ad.id)
    setIdSetor(ad.fk_id_setor);
    getCongragacao(ad.fk_id_setor);
    setCongregacao(ad.fk_id_congregacao);
    setNome(ad.nome);
    setLogin(ad.login);
    setSenha(ad.senha);
    setCelular(ad.celular);

    setShow(false);

  }
  function PesquisarAdmin(ev) {

    console.log("pesquisar administrador", ev)

    var lst = listAdmin.filter((f) => f.login.toLowerCase().includes(ev.toLowerCase()) || f.nome.toLowerCase().includes(ev.toLowerCase()) || f.congregacao.toLowerCase().includes(ev.toLowerCase()));
    setListAdminPesquisar(lst)
    if (ev == "")
      setListAdminPesquisar(listAdmin)



  }
  return (
    <div style={{ backgroundColor: "#000" }} className="container-igreja">
      <NavBar navegando="CADASTRO DE ADMINISTRADOR" />
      <div className='div-button-cadastro-btn'>
        <Button className='btn-cadastro' onClick={Pesquisar} disabled={bloquear.pesquisar}>PESQUISAR</Button>{" "}
        <Button className='btn-cadastro' onClick={Novo} disabled={bloquear.novo}>NOVO</Button>{" "}
        <Button className='btn-cadastro' onClick={Alterar} disabled={bloquear.alterar}>ALTERAR</Button>{" "}
        <Button className='btn-cadastro' onClick={Excluir} disabled={bloquear.excluir}>EXCLUIR</Button>{" "}
        <Button className='btn-cadastro' onClick={Cancelar} disabled={bloquear.cancela}>CANCELAR</Button>{" "}
        <Button className='btn-cadastro' onClick={Salvar} disabled={bloquear.salvar}>SALVAR</Button>{" "}
      </div>
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
      <Form className="container-form" onSubmit={handleLogin}>


        <Row
          className="mb-3"
        >
          <Form.Group className="tm" as={Col}>
            <Form.Label className="label-igreja">SETOR</Form.Label>
            <Form.Select
              onChange={selectSetor}
              defaultValue="0"
              value={setor}
              disabled={bloquear.formulario}
            >
              <option value="0">{""}</option>
              {
                listSetor.map((list, index) => (
                  <option key={index} value={"" + list.id_setor}>{list.nome_setor}</option>
                ))
              }

            </Form.Select>
          </Form.Group>
          <Form.Group className="tm" as={Col}>
            <Form.Label className="label-igreja">CONGREGAÇÃO</Form.Label>
            <Form.Select
              disabled={bloquear.formulario}
              onChange={(e) => setCongregacao(e.target.value)}
              defaultValue="1"
              value={congregacao}
            >
              {
                listCongragacao.map((list, index) => (
                  <option key={index} value={"" + list.id_congregacao}>{list.congregacao}</option>
                ))
              }
            </Form.Select>
          </Form.Group>
          <Form.Group className="tm" as={Col}>
            <Form.Label className="label-igreja">CARGO</Form.Label>
            <Form.Select
              disabled={bloquear.formulario}
              onChange={(e) => setCargo(e.target.value)}
              defaultValue="1"
              value={cargo}
            >
              {
                listCargo.map((list, index) => (
                  <option key={index} value={"" + list.id_cargo}>{list.nome_cargo}</option>
                ))
              }
            </Form.Select>
          </Form.Group>
        </Row>


        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <div
          style={{ display: "flex" }}
        >

          <div
            style={{ marginRight: 20 }}
          >
            <Form.Label className="label-igreja">ACESSAR SECRETARIA</Form.Label>
            <Form.Select
              disabled={bloquear.formulario}
              onChange={(e) => setSecretaria(e.target.value)}
              defaultValue="0"
              value={secretaria}
            >
              <option value="0">NÃO</option>
              <option value="1">SIM</option>
            </Form.Select>
          </div>
          <div>
            <Form.Label className="label-igreja">ACESSAR TESOURARIA</Form.Label>
            <Form.Select
              disabled={bloquear.formulario}
              onChange={(e) => setTesouraria(e.target.value)}
              defaultValue="0"
              value={tesouraria}
            >
              <option value="0">NÃO</option>
              <option value="1">SIM</option>
            </Form.Select>
          </div>
        </div>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <Row //
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-igreja">NOME DO MEMBRO</Form.Label>
            <Form.Control
              disabled={bloquear.formulario}
              type="text"
              placeholder="Digite o nome do membro"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-igreja">CELULAR</Form.Label>
            <Form.Control
              disabled={bloquear.formulario}
              style={{ width: 300 }}
              type="text"
              placeholder="Digite o celular"
              value={celular}
              onChange={(e) => setCelular(e.target.value)}
            />
          </Form.Group>
        </Row>




        <Row //Nome da congragação
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-igreja">LOGIN</Form.Label>
            <Form.Control
              disabled={bloquear.formulario}
              type="text"
              placeholder="Digite o login"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className="label-igreja">SENHA</Form.Label>
            <Form.Control
              disabled={bloquear.formulario}
              type="text"
              placeholder="Digite a senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
            />
          </Form.Group>
        </Row>



        <hr  // Linha de separação
          style={{ color: "yellow", fontWeight: "bold", marginBottom: 40, marginTop: 40 }}
        ></hr>

      </Form>

      <Modal //modal de pesquisa
        show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Row //Nome do igreja, data de batismo
              className="mb-3"
            >
              <Form.Group as={Col}>
                <Form.Label className="label-igreja">Pesquisar Administrador</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Digite o login, o nome, ou congregacao"
                  style={{ width: 400 }}

                  onChange={(e) => PesquisarAdmin(e.target.value)}
                />
              </Form.Group>

            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped hover>

            <tr className="admin-tr-head">
              <td className="admin-td-head">ID</td>
              <td className="admin-td-head">Login</td>
              <td className="admin-td-head">Senha</td>
              <td className="admin-td-head">Nome</td>
              <td className="admin-td-head">Celular</td>
              <td className="admin-td-head">Congregação</td>
            </tr>

            <tbody>
              {
                listAdminPesquisar.map((ad, index) => (
                  <tr key={index} className="admin-td-select" onClick={() => SelectAdmin(ad)}>
                    <td>{ad.id}</td>
                    <td>{ad.login}</td>
                    <td>{ad.senha}</td>
                    <td>{ad.nome}</td>
                    <td>{ad.celular}</td>
                    <td>{ad.congregacao}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Administrador;
