import { useState } from 'react';
import axios from 'axios';
import './upload.css'
function Upload() {

    const url = axios.create({
        baseURL:"http://localhost:3006"
    })

    const [image, setImagem] = useState("");

    const uploadImagem = async e => {
        e.preventDefault();
        console.log("Upload Imagem");
        console.log(image);
        const formData= new FormData();
        formData.append('image',image);

        const headers ={
            'headers':{
                'Content-Type':'application/json'
            }
        }
        await url.post("/upload",formData)
        .then((response) =>{
            console.log("url ",response);
        }).catch((err) =>{
            console.log("url 2 ",err);
            if(err.response){
                console.log(err.response);
            }else{
                console.log("Erro: Tente mais tarde!")
            }
        });
    }

    return (
        <div className='texto'>
            <h1>Upload</h1>
            <form onSubmit={uploadImagem}>
                <label >Imagem:</label>
                <input type="file" name="image"
                    onChange={e => setImagem(e.target.files[0])}
                /><br /><br />
                <button type="submit">Salvar</button>
            </form>
        </div>
    );
}




export default Upload;