import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import React, { useRef,useEffect,useState } from "react";
import { useReactToPrint } from "react-to-print";
import imgLogo from "../../../imagens/ad.jpg";
import NavBar from "../../../components/NavBar";
import api from "../../../services/api";
import converter from "../../../Util/Converter";
import './talao.css';
function RelatorioTalao() {

//-----------geral
const [setor, setSetor] = useState("0");
const [listTalao,setListTalao] =useState([]);
const [listGeralCongregacao,setListGeralCongregacao]= useState([{}]);
const [valorGeral,setValorGeral] = useState({dizimo:'0.00',oferta:'0.00',total:'0.00',saida:'0.00',saldo:'0,00'});
const [listGeralSetor,setListGeralSetor] = useState([{}]);


async function GetTalao(mes){
    const gettalao= await api.postGeralRelatorioTalao({
     
      mesano:mes,
      info:info,
     
    })
    .then(( {data} ) => {
      return data;
    });
    console.log(gettalao[0])
setListTalao(gettalao[0]);
}

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      onAfterPrint: () => {
        return new Promise(() => {
          setShow(false);
        });
      }
    });
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
////////////////////////////////////////////////////////////////////////////////
const [id_setor, setIdSetor] = useState( localStorage.getItem('id_setor')||"0");
const [id_congregacao, setIdCongregacao] = useState(localStorage.getItem('id_congregacao')||"0");

////////////////////////////////////////////////////////////////////////////////
const  [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
const  [data, setData] = useState("");
 const [dia,setDia]=useState(0);
 const [mes,setMes]=useState(0);
 const [ano,setAno]=useState(0);
 const [mesano,setMesano] =useState("0");
 const [mesResumo,setMesResumo] =useState("");
 const [mesRelatorio,setMesRelatorio] = useState("");
 const [totalEntradas,setTotalEntradas] = useState("0,00");
 const [totalSaidas,setTtotalSaidas] = useState("0,00");
 const [saldo,setSaldo] =  useState("0,00");
 const [dizimo,setDizimo] = useState("0,00");
 const [oferta,setoferta] =  useState("0,00");

 const [listDespesa,setListDespesa] =  useState([]);
 const nomeMes =["","JANEIRO","FEVEREIRO","MARÇO","ABRIL","MAIO","JUNHO","JULHO","AGOSTO","SETEMBRO","OUTUBRO","NOVEMBRO","DEZEMBRO"];
 const rowsSaidas=["","","","","","","","","","","","","","","","","","","",""];

const getData=()=>
{
    api.postGetDate({info}).then(({ data }) => {
      if(data){
        let dat =data.split('T')[0];
        let dta = dat.split('-');
        let dia=parseInt(dta[2]);
        let m =parseInt(dta[1]);
        let a =parseInt(dta[0]);
        setData(dat);
        setDia(dia);
        setMes(m);
        setAno(a);
        setMesano(m+""+a)
      }
    });
}

useEffect(() => {
   GetTalao("032023");
  }, []);
 function onchangeListarData(ev) {
    let v = ev.target.value;

    setTtotalSaidas("0,00");
    setListDespesa([]);
    setListTalao([]);
            
    const texto = ev.target.options[ev.target.selectedIndex].text;
    setMesResumo(texto);

     valorGeral(v);

 }
 

  return (
<   div style={{ backgroundColor:"#000"}}>
        <NavBar navegando={"Relatório"} />
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
        <div //Cabeçario
        style={{
            display:"flex",flexDirection:"column",borderRadius:15,backgroundColor:"#fff",
            width:"100%",marginLeft:10
            }}>
        <label>DATA DO SISTEMA: {dia<10?"0"+dia:dia}/{mes<10?"0"+mes:mes}/{ano}</label>
        <label>SETOR: {info.nome_setor} </label>
        <label>CONGREGAÇÃO: {info.congregacao} </label>
        <label>{info.nome_cargo}: {info.nome} </label>

        </div>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
        <div style={{marginLeft:10,color:"#FFF",display:"flex",flexDirection:"column"}}>
         {
         listTalao.map((value)=>
            ( 
                <div style={{marginLeft:10,color:"#FFF",display:"flex",flexDirection:"row", borderWidth:2,borderBlockEndStyle:"solid",}}>
                    <label style={{width:230}}>{value.nome_setor}</label>
                    <label>{value.congregacao}</label>
                </div>
            ) 
         )
         }
        </div>
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
 
         <Modal //modal de pesquisa
         show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
            <Button
              style={{height:60,width:200,borderRadius:5}}
              onClick={handlePrint}
              >IMPRIMIR
            </Button>
           </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div //////////////////////////Impresãoooooooooo
              style={{flexDirection:"column",width:1133,height:"100%",background:"#FFF"}} >
                  <style type="text/css" media="print">{"@page { size: portrait; } @media print{#noprint{display:none}}"}</style>
                  <div style={{display:"flex",flexDirection:"column", width:1333,height:"100%",background:"#FFF"}} ref={componentRef}>
                      <div className="geral-cabecario">
                          <div className="geral-img"> <img src={imgLogo} width="100" height="90" /></div>
                          <div className="geral-cabecario-text">
                            <label style={{fontSize:24,fontWeight:"bold"}} className="ft-c">IGREJA EVANGELICA  ASSEMBLÉIA DE DEUS </label>
                            <label style={{fontSize:20}} className="ft-c">Ministério do Belém - Campo Circuito das Águas Amparo/sp</label>
                            <label style={{fontSize:19,fontWeight:"bold"}} className="ft-c">Pr. Nilson Marcos Lima - Presidente</label>
                          </div>
                      </div> 
                      <label style={{fontSize:18,fontWeight:"bold"}} className="ftc"> FINANCEIRO GERAL  - REFERENTE  AO MÊS DE {mesResumo} </label>  
                    <div> 
                      { 
                        
                        listGeralSetor.map((value,index)=>(
                          
                          <div key={index} >
                                <div>
                                  <label className="ft">SETOR: {value.setor}</label>
                                </div>
                                <div>
                                  <label className="ft">CONGREGAÇÃO: {value.congregacao}</label>
                                </div>
                                <div >
                                  <label className="ft w200">DIZIMO</label>
                                  <label className="ft-a w150">R$<span  className="spn" > {converter.ConverterReal(value.dizimo)}</span></label>
                                </div>
                                <div >
                                   <label className="ft w200">OFERTA</label>
                                   <label className="ft-a w150">R$<span  className="spn"  > {converter.ConverterReal(value.oferta)}</span></label>
                                </div>
                                 <div>
                                   <label className="ft w400">ENTRADAS</label>
                                   <label className="ft-a-n w150">R$<span  className="spn" > {converter.ConverterReal(value.total)}</span></label>
                                </div> 
                                <div>
                                   <label className="ft-r w400">SAÍDAS</label>
                                   <label className="ft-r-a w150">R$<span  className="spn" >{converter.ConverterReal(value.saida)}</span></label>
                                </div> 
                                <div >
                                   <label className="ft-b w600">SALDO</label>
                                   <label className="ft-b-a w150">R$<span  className="spn" >{value.saldo}</span></label>
                                </div>
                            
                             
                             
                             
                              <hr></hr>
                          </div>
                          
                        ))
                      }
                       <div >
                                   <label className="ft w200">TOTAL DE ENTRADAS</label>
                                   <label className="ft-a-n w150">R$<span  className="spn" >{converter.ConverterReal(valorGeral.total)}</span></label>
                       </div>  
                       <div >
                                   <label className="ft-r w400">TOTAL DE SAÍDAS</label>
                                   <label className="ft-r-a w150">R$<span  className="spn" >{converter.ConverterReal(valorGeral.saida)}</span></label>
                       </div> 
                       <div >
                                   <label className="ft-b w600">SALDO</label>
                                   <label className="ft-b-a w150">R$<span  className="spn" >{valorGeral.saldo}</span></label>
                       </div> 
                     </div>

                   </div>
              </div>
          </Modal.Body>
        </Modal>

        </div>
  );
}

export default RelatorioTalao;
