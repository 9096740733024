


class ConvertDate{
    DateSort(date)
    {
      let dt=date; 
      dt= dt.split('/');
      let d=dt[0];
      let m =dt[1];
      let a =dt[2];
      return {date:d+"/"+m+"/"+a,date_us:a+"-"+m+"-"+d, dia:d, mes:m, ano:a}
    }
    Date(date)
    {
     
      let dt =date;
      dt= dt.split('/');
      let d=dt[0];
      let m =dt[1];
      let a =dt[2];
      return {date:d+"/"+m+"/"+a,date_us:a+"-"+m+"-"+d, dia:d, mes:m, ano:a}
    }
    Date_Us(date)
    {
      if(!date)
      return{date:null,date_us:null}
      let dt=date;
      try{
      dt =date.split('T')[0];
      dt= dt.split('-');
      }catch{return {date:null,date_us:null}}
      let d=dt[2];
      let m =dt[1];
      let a =dt[0];
      return {date:d+"/"+m+"/"+a,date_us:a+"-"+m+"-"+d, dia:d, mes:m, ano:a}
    }
    DataAgora()
    {
      let ts = new Date();
      let date=  new Intl.DateTimeFormat(
          'pt-BR', {
              timeZone: 'America/Sao_Paulo',
              dateStyle: 'short',  
          }).format(ts);
         return date;
    }
    DataNow()
    {
      let ts = new Date();
      let date=  new Intl.DateTimeFormat(
          'pt-BR', {
              timeZone: 'America/Sao_Paulo',
              dateStyle: 'short',  
          }).format(ts);
         return this.Date(date).date_us;
    }

}
const converter_date = new ConvertDate()
export default converter_date