import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import NavBar from "../../../components/NavBar";
import api from "../../../services/api";
import { useState, useEffect, useRef } from "react";
import "./dizimista.css";
import ListarDizimista from "../../../components/Listar/ListarDizimista";
import { useReactToPrint } from "react-to-print";
import ReactToPrint from 'react-to-print';
import { Link } from "react-router-dom";
import imgLogo from "../../../imagens/adbelem.png";
import converter from "../../../Util/Converter";
import { ListarData } from "../../../hooks/datas";
import { LayoutCongregacao } from "./layout/congregacao";


function RelatorioDizimistaMesal() {

  const componentRef = useRef();
  const componentRefvalor = useRef();

  const handlePrint = useReactToPrint({

    content: () => componentRef.current,
  });
  const handlePrintvalor = useReactToPrint({

    content: () => componentRefvalor.current,
  });
  ////////////////////////////////////////////////////////////////////////////////
  const [setor, setSetor] = useState(
    localStorage.getItem("setor") || "Não Logado"
  );
  const [congregacao, setCongregacao] = useState(
    localStorage.getItem("congregacao") || "Não Logado"
  );
  const [user, setUser] = useState(
    localStorage.getItem("user") || "Não Logado!"
  );
  const [geral, setGeral] = useState(localStorage.getItem("geral") || 0);
  const [cargo, setCargo] = useState(
    localStorage.getItem("cargo") || "Não logado"
  );
  const [id_setor, setIdSetor] = useState(
    localStorage.getItem("id_setor") || "0"
  );
  const [id_congregacao, setIdCongregacao] = useState(
    localStorage.getItem("id_congregacao") || "0"
  );
  const [id_cargo, setIdCargo] = useState(
    localStorage.getItem("id_cargo") || "0"
  );
  const [id, setId] = useState(localStorage.getItem("id") || "Não Logado");
  ////////////////////////////////////////////////////////////////////////////////
  const [mesRelatorio, setMesRelatorio] = useState("");
  const [mesResumo, setMesResumo] = useState("");
  const [info, setInfo] = useState(JSON.parse(localStorage.getItem('config')) || []);
  const [data, setData] = useState("");
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState("");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState("0");
  const [dia, setDia] = useState(0);
  const [mes, setMes] = useState(0);
  const [ano, setAno] = useState(0);
  const [mesano, SetMesAno] = useState(0);
  const [isData, setIsData] = useState("1");
  const [dataString, setDataString] = useState("1");
  const [log, setLog] = useState("log");

  //////////////////////////////////////////////////////////////////////////////
  //
  /////////////////////////////////////////////////////////////////////////////
  const [valorDizimistaTalao, setValorDizimistaTalao] = useState(0);
  const [talao, setTalao] = useState(0);
  const [dizimo, setDizimo] = useState("");
  const [oferta, setOferta] = useState(0);
  const [numero, setNumero] = useState("");
  const [listDizimista, setListDizimista] = useState([]);
  const [listarDizimista, setListarDizimista] = useState([]);
  const [listDizimistaValor, setListDizimistaValor] = useState([]);
  const [nomeDizimista, setNomeDizimista] = useState("");
  const [valorDizimista, setValorDizimista] = useState("");
  const [valorEntrada, setValorEntrada] = useState(0);
  const [valorTotal, setValorTotal] = useState("0,00");
  const [listTalao, setListTalao] = useState([]);
  //select_dizmista_soma
  
  // const DividirLista =(lista)=>{

  //   let qnt = lista.length||0;
  //   let indice=30;
  //   let count=0;

  //   while(qnt>indice){
  //     qnt-=30;
  //     count++;
  //   }

  //   if(count>0&&qnt<3){
  //   qnt = lista.length||0;
  //   indice=27;
  //   count=0;
  //   while(qnt>indice){

  //     qnt-=30;
  //     count++;
  //   }
  // }
  // qnt = lista.length||0;
  // for(let i=0;i<count;i++){
    
  

      
  // }


  //   setListDizimista([]);

  // }

  const DividirLista =(lista)=>{
    const tamanhoGrupoIdeal = 29;
    const tamanhoGrupoMinimo = 28;
    const grupos = [];
  
    for (let i = 0; i < lista.length; i += tamanhoGrupoIdeal) {
      const grupo = lista.slice(i, i + tamanhoGrupoIdeal);
  
      // Verifica se o último grupo tem menos que 30 elementos
      if (grupo.length < tamanhoGrupoIdeal && grupo.length >= tamanhoGrupoMinimo) {
        // Se tiver entre 28 e 30, ajusta para 28
        grupos.pop(); // Remove o último grupo adicionado

        grupos.push(lista.slice(i, i + tamanhoGrupoMinimo));

      } else {

        grupos.push(grupo);

      }
    }
    setListarDizimista(grupos)
    //return grupos;
  }

  const getDizmistaMensal = (v) => {
    api
      .postGetDizimistaMensal({
        setor: id_setor,
        congregacao: id_congregacao,
        info: info,
        mesano: v,
        grupo: 0
      })
      .then(({ data }) => {

        if (data.length > 0) {
          setListDizimista(data[0]);

          setValorTotal(data[1][0].dizimo);
          setListDizimistaValor(data[2]);
          
          DividirLista(data[0]);

        }
      });
  };

  const getData = () => {
    api.postGetDate({ info }).then(({ data }) => {
      if (data) {
        let date = converter.Date(data);

        setDia(date.dia);
        setMes(date.mes);
        setAno(date.ano);
      }
    });
  };

  useEffect(() => {
    getData();

  }, []);

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }


  function handleLogin(e) {
    e.preventDefault();
  }

  function onchangeListarData(ev) {
    let v = ev.target.value;

    
    setListDizimista([])
    const texto = ev.target.options[ev.target.selectedIndex].text;
    setMesResumo(texto);


    getDizmistaMensal(v);

  }


  return (

    <div style={{ backgroundColor: "#000" }} className="container-culto">
      <div>
        <NavBar navegando="DIZIMISTA DO MÊS " />
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <Form style={{ width: "95%", marginLeft: 10 }} onSubmit={handleLogin}>
          <div //Cabeçario
            style={{
              display: "flex", flexDirection: "column", borderRadius: 15, backgroundColor: "#fff",
              width: "100%", marginLeft: 10, color: "#000"
            }}>
            <label>
              DATA DO SISTEMA: {dia}/{mes}/{ano}
            </label>
            <label>SETOR: {info.nome_setor} </label>
            <label>CONGREGAÇÃO: {info.congregacao} </label>
            <label>
              {info.nome_cargo}: {info.nome}{" "}
            </label>
          </div>

          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>
          <div //Cabeçario
            style={{
              display: "flex", flexDirection: "column", borderRadius: 15,
              width: "100%", marginLeft: 10
            }}></div>
          <Row className="mb-3">
            <Form.Group className="tm" as={Col}>
              <Form.Label style={{ color: "#FFF" }}>RELATÓRIO DO MÊS</Form.Label>
              <Form.Select
                style={{ maxWidth: 300 }}
                onChange={onchangeListarData} //(e) => setTalao(e.target.value)}
                defaultValue=""
              >
                <option value="0"></option>
                {
                  ListarData().map((dta) => (
                    <option value={dta.mesano}>{dta.mesnome} DE {dta.ano}</option>
                  ))
                }

              </Form.Select>
            </Form.Group>

          </Row>
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>

          <div
            style={{
              marginTop: 30,
              display: "flex",
              height: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >

            <Button
              style={{ width: 200, height: 50 }}
              onClick={handlePrintvalor}
            //to={'/imprimir_dizimista'}
            >
              IMPRIMIR COM VALOR
            </Button>
            <Button
              style={{ width: 200, height: 50 }}
              onClick={handlePrint}
            //to={'/imprimir_dizimista'}
            >
              IMPRIMIR SEM VALOR
            </Button>
            {/* <ReactToPrint
              trigger={() => <button>IMPRIMIR</button>}
              content={() => componentRef.current}
              
          
           /> */}
          </div>

          <div
            style={{
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
         <ListarDizimista listar={listDizimista}></ListarDizimista>
          </div>
          <div
            style={{
              width: "100%",
              height: 60,
              display: "flex",
              flexDirection: "row-reverse",
              backgroundColor: "#1e222b",
            }}
          >
            <label style={{ fontSize: 24 }}>
              TOTAL R$ {converter.ConverterReal(valorTotal)}
            </label>
          </div>
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>
        </Form>
      </div>
      <div /////////////////////////////////////////////////////////////////////////
       style={{ display: "none " }}

      >
        <style type="text/css" media="print">{"@page { size: portrait; }"}</style>
        <div style={{ margin: 0, padding: 0, backgroundColor: "#FFF", }} ref={componentRef}

        >
        { listarDizimista.map((grupo)=>(
           <LayoutCongregacao lista={grupo} mes={mesResumo} />
        ))

}
        </div>
         </div>
        {/* <div /////////////////////////////////////////////////////////////////////////
      style={{display:"none"}}

      >
         <style type="text/css" media="print">{"@page { size: portrait; }"}</style>
          <div    style={{margin:0,padding:0,width:800,  backgroundColor:"#FFF",}} ref={componentRef}
            
          >
         
            <div
              style={{
                display: "flex",
                width: "100%",
                height: 100,
                backgroundColor: "#c9c9c9",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: 220,
                  height: 200,
                  backgroundColor: "#fff",
                  justifyContent: "center",
                }}
              >
              <img src={imgLogo} width="200" height="180" />
              </div>
              <div
                style={{ width: "100%", height: "100%", backgroundColor: "#FFF" }}
              >
                <div style={{ width: "100%", height: 40, backgroundColor: "#FFF" }}>
              
                </div>
                <div style={{ width: "100%", height: 50, backgroundColor: "#FFF" }}>
                  <label
                    style={{
                      width:500,
                      fontSize: 24,
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    IGREJA EVANGÉLICA ASSEMBLÉIA DE DEUS
                  </label>
                </div>
                <div style={{ width: "100%", height: 40, backgroundColor: "#FFF" }}>
                  <label style={{ width: 500, textAlign: "center", fontSize: 18 }}>
                    MINISTÉRIO DE BELÉM - AMPARO - SP
                  </label>
                </div>
                <div
                  style={{ width: "100%", height: 70, backgroundColor: "#FFF" }}
                ></div>
              </div>
            </div>
          
            <div
                  style={{
                    backgroundColor:"#FFF",
                    width: "100%",
                    marginTop: 100,
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <label
                        style={{
                          width: "100%",
                          fontSize: 20,
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        DIZIMISTAS DO MÊS DE {mesResumo}
                      </label>
                      <hr // Linha de separação
                  style={{ color: "#000" }}
                ></hr>
                  <div style={{width:"100%",  backgroundColor:"#FFF"}}>
                  
                    
                      {listDizimista.map((list, index) => (
                          <label key={index}
                          style={{
                            width: "100%",
                            fontSize: 18,
                            textAlign: "center",
                          
                              }}
                            >
                          {list.membro}
                        </label>
                        
                      ))}
                    <hr/> 
                   <h6 > <p style={{fontWeight:"bold"}}>"Agradecemos aos irmãos que, com seus dízimos, honraram a Deus e abençoaram a sua obra neste mês. Que Deus os abençoe!
"Honra ao Senhor com os teus bens, e com as primícias de toda a tua renda; e se encherão os teus celeiros de fartura, e transbordarão de vinho novo os teus lagares." (Provérbios 3:9-10)"</p>
                  </h6>
                  </div> 
            </div>
   
          </div>
     </div> */}
        <div /////////////////////////////////////////////////////////////////////////
          style={{ display: "none" }}

        >
          <style type="text/css" media="print">{"@page { size: portrait; }"}</style>
          <div style={{ margin: 0, padding: 0, width: 800, backgroundColor: "#FFF", }} ref={componentRefvalor}

          >


            <div
              style={{
                backgroundColor: "#FFF",
                width: "100%",
                marginTop: 100,
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
              }}
            >
              <label
                style={{
                  width: "100%",
                  fontSize: 20,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                DIZIMISTAS DO MÊS DE {mesResumo}
              </label>
              <hr // Linha de separação
                style={{ color: "#000" }}
              ></hr>
              <div style={{ width: "100%", backgroundColor: "#FFF" }}>

                <table style={{ fontSize: 16 }}>
                  <tr>
                    <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>DATA</td>
                    <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>Nº TALÃO </td>
                    <td style={{ width: 350, height: 20, textAlign: "center", fontWeight: "bold" }}>NOME DO MEMBRO</td>
                    <td style={{ width: 150, height: 20, textAlign: "center", fontWeight: "bold" }}>VALOR DO DIZIMO</td>
                    {/* <td style={{width:0,height:20,textAlign:"center"}}>.</td> */}
                  </tr>

                  {listDizimistaValor.map((list, index) => (
                    <tr>
                      <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{converter.Dates(list.data).date}</td>
                      <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{list.talao}</td>
                      <td style={{ width: 400, height: 20, textAlign: "left", fontWeight: "bold" }}>{list.membro}</td>
                      <td style={{ width: 150, height: 20, fontWeight: "bold" }}>R$ <span style={{ float: "right", color: "red" }}>{converter.ConverterReal(list.dizimo)}</span></td>

                    </tr>

                  ))}
                  <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>-</td>
                  <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>-</td>
                  <td style={{ width: 400, height: 20, textAlign: "right", fontWeight: "bold" }}>TOTAL</td>
                  <td style={{ width: 150, height: 20, fontWeight: "bold" }}>R$ <span style={{ float: "right", color: "red" }}>{converter.ConverterReal(valorTotal)}</span></td>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

      );
}

      export default RelatorioDizimistaMesal;
