import{useState,useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Logo} from '../components/Logo';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import './style.css'
import { Table } from 'react-bootstrap';

function LoginBatismo() {

    useEffect(() => {
    
     
        }, []);

    const navigate = useNavigate();
    const [email,setEmail]= useState("");
    const [senha,setSenha]= useState("");
    const [erro,setErro]= useState("");
    const [btnAbilitar,setBtnAbilitar]= useState(true);
  
 async function Acessar () {
        const {data}= await api.BatismoLogin({
        codigo:senha,
        token:"Q2BuITXnw2KusoPATy8S9EHktMb4TMOwYbfX963cff4d65230e",
      })
      .catch((error) =>{
        return []
      })

      if(data)
      { 
       
            if(data.acesso){
                localStorage.setItem("pre_batsimo",JSON.stringify(data))
                navigate("/batismo/cadastro");
            }
            else {
                setErro("Login ou senha incorreto!");
                setBtnAbilitar(()=>true);
            }
       }
    
        //  Keyboard.dismiss();
      
    }

    return (
        <div style={{backgroundColor:"#000",display:'flex',flexDirection:'column'}} className='login-container' >
            <Logo className='login-logo'/>
            <Form className='form-login-batismo' style={{display:'flex',flexDirection:'column'}}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  
                    <Form.Control type="password" placeholder="Digite a senha" autoComplete='on'
                     value={senha}
                     required
                     onChange={(e)=> setSenha(e.target.value)}
                     />
                </Form.Group>
                <label style={{color:"red",height:40,fontSize:30,alignSelf:"center"}}>{erro}</label>
                <Button className="btn_ativo" onClick={Acessar}>
                        Entrar
                </Button>
            </Form>
       
        </div>
    );
}

export default LoginBatismo;