import React,{useState} from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import converter from "../../Util/Converter";
import { ButtonGroup } from "react-bootstrap";
import "./lista_dizimista_geral.css";
import api from "../../services/api";

function ListarDizimistaGeral({listar}) {
    const [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [dizimista, setDizimista] = useState("");
    const [dizimo, setDizimo] = useState("");
    const [id, setId] = useState(0);
 function alterarDizmista (){
        api
          .UpdateDizimistaGeral( {
            id: id,
            dizimista:dizimista,
            dizimo: converter.ToFloat(dizimo),
            info:info,
         
          })
          .then(({ data }) => {
            if (data) {
              console.log("Dizimista alterado com sucesso!");
            }
          });
      };
    function handleShow(breakpoint) {
      setFullscreen(breakpoint);
      setShow(true);
    }
    const [total,setTotal] = React.useState(0);
    function ToReal(valor) {
        return String(parseFloat(valor).toFixed(2)).replace('.',',');
      }
    
     function ToFloat(valor) {
        return parseFloat(valor.replace('.','').replace(',','.')).toFixed(2);          
      }
      function excluir(lista)
      {

      }
    function exibir(lista)
    { 
        setId(lista.id);
        setDizimista(lista.membro);
        setDizimo(converter.ConverterReal(lista.dizimo));
        handleShow(true);
       
    }
    function alterar()
    { 
        alert("ola!!!");
        //handleShow(false);
        alterarDizmista();
    }

    return (
            <>
              <div style={{width:"100%",display:"flex",flexDirection:"row",backgroundColor:"#1e222b"}}>  
                <label style={{width:"100%",textAlign:"center",fontSize:24,textDecorationLine:"underline",textDecorationColor:"#fff"}}>Lista de dizimista</label>
              </div>
            {
                listar.map((list,index)=>(
                    index % 2 ===0 ?
                    <div key={index} style={{width:"100%", display:"flex",flexDirection:"row",borderBottomStyle:"solid",borderBlockColor:"#1e222b"}}>
                    <label onClick={() =>exibir(list)} style={{color:"#000",width:"100%",fontSize:20}}>{list.membro}</label>
                    <div style= {{display:"flex",flexDirection:"row-reverse",width:"100%"}}>
                        <label style={{color:"#000",fontSize:20,width:80,textAlign:"right"}}>{ToReal(list.dizimo)}</label>
                        <label style={{color:"#000",fontSize:20}}>R$</label>
                    </div>
                    </div>
                    :
                    <div key={index} style={{width:"100%", display:"flex",flexDirection:"row",borderBottomStyle:"solid",borderBlockColor:"black"}}>
                    <label onClick={() =>exibir(list)} style={{color:"#000",width:"100%",fontSize:20,backgroundColor:"#c9c9c9"}}>{list.membro}</label>
                    <div style= {{display:"flex",flexDirection:"row-reverse",width:"100%",backgroundColor:"#c9c9c9"}}>
                        <label style={{color:"#000",fontSize:20,width:80,backgroundColor:"#c9c9c9",textAlign:"right"}}>{ToReal(list.dizimo)}</label>
                        <label style={{color:"#000",fontSize:20,backgroundColor:"#c9c9c9"}}>R$</label>
                    </div>
                    </div>
                  
                    
                ))

            }
        <Modal //modal de pesquisa
        show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Row //Nome do igreja, data de batismo
                className="mb-3"
                >
                    <Form.Group as={Col}>
                        <Form.Label className="lbl-list-dizimista">Alterar ou Excluir Dizimista</Form.Label> 
                    </Form.Group>
                </Row>
                <Row //Nome do igreja, data de batismo
                className="mb-3"
                >
                    <Form.Group as={Col}>
                        <Form.Label className="label-igreja">{dizimista}</Form.Label> 
                    </Form.Group>
                </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row //Nome do igreja, data de batismo
            
            className="mb-3"
            >
                <Form.Group as={Col}>
                <Form.Label className="label-igreja">ID</Form.Label>
                <Form.Control
                    type="text"
                    placeholder=""
                    style={{ width:400}}
                    value={id}
                />
                </Form.Group>
            </Row>
            <Row //Nome do igreja, data de batismo
            
            className="mb-3"
            >
                <Form.Group as={Col}>
                <Form.Label className="label-igreja">Nome do Dizimista</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Digite o nome "
                    style={{ width:400}}
                    value={dizimista}
                    onChange={(e)=> setDizimista(e.target.value)}
                />
                </Form.Group>
            </Row>
            <Row //Nome do igreja, data de batismo
            className="mb-3"
            >
                <Form.Group as={Col}>
                <Form.Label className="label-igreja">Valor do Dizimo</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Digite o valor do dizimo"
                    style={{ width:400}}
                    value={dizimo}
                    onChange={(e)=> setDizimo(e.target.value)}
                />
                </Form.Group>
            </Row>
            <div>
                    <Button onClick={alterar} className="btn-alterar">Alterar</Button>

                    <Button onClick={excluir} className="btn-excluir">Excluir</Button>
            </div>
        </Modal.Body>
        </Modal>
        </>
        )
    }
export default ListarDizimistaGeral;