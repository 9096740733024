import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import NavBar from "../../components/NavBar";
import "./culto.css";
import api from "../../services/api";
import { useState, useEffect, useRef } from "react";
import "../style.css";
import ListarDizimista from "../../components/Listar/ListarDizimista";
import converter from "../../Util/Converter";
import ListarTalao from "../../components/Listar/ListarTalao";
import Accordion from 'react-bootstrap/Accordion';
function Culto() {

  const handleRegisterTalao = () => {
    api.postSetTalao({
      setor: id_setor,
      congregacao: id_congregacao,
      talao: numero,
      dizimo: converter.ToFloat(dizimo == "" ? "0,00" : dizimo),
      oferta: converter.ToFloat(oferta == "" ? "0,00" : oferta),
      data: data,
      total: String(
        parseFloat(
          parseFloat(dizimo == "" ? 0 : dizimo.replace('.', '').replace(',', '.')) +
          parseFloat(oferta == "" ? 0 : oferta.replace('.', '').replace(',', '.'))
        ).toFixed(2)
      ),
      info: info,
      mesano: mesano
    }).then(({ data }) => {
      alert(data.msg);
      getNumeroTalao();
      getTalao();
    })

  }
  const inputRef = useRef(null);
  ////////////////////////////////////////////////////////////////////////////////
  const [info, setInfo] = useState(JSON.parse(localStorage.getItem('config')) || []);
  const [id_setor, setIdSetor] = useState(localStorage.getItem('id_setor') || "0");
  const [id_congregacao, setIdCongregacao] = useState(localStorage.getItem('id_congregacao') || "0");

  ////////////////////////////////////////////////////////////////////////////////
  const [data, setData] = useState("");
  const [data_min, setData_min] = useState("");
  const [data_max, setData_max] = useState("");

  const [dataSistem, setDataSistem] = useState("");
  const [dia, setDia] = useState(0);
  const [mes, setMes] = useState(0);
  const [ano, setAno] = useState(0);
  const [mesano, setMesano] = useState("0");




  //////////////////////////////////////////////////////////////////////////////
  const [dizimo, setDizimo] = useState("");
  const [oferta, setOferta] = useState("");
  const [numero, setNumero] = useState("");
  const [dinheiro, set_dinheiro] = useState("");
  const [pix, set_pix] = useState("");
  const [cartao, set_cartao] = useState("");

  function getNumeroTalao() {

    api.postGetTalaoNumero({
      setor: id_setor,
      congregacao: id_congregacao,
      talao: numero,
      info: info,
      mesano: mesano
    }).then(({ data }) => {
      if (Number.isInteger(parseInt(data[0].talao))) {
        setNumero(parseInt(data[0].talao) + 1);
      }
      else
        setNumero("");


    })
  }


  const addTalao = () => {
    let valor = String(

      parseFloat(
        parseFloat(String(oferta === "" ? 0 : oferta).replace('.', '').replace(',', '.')) +
        parseFloat(String(dizimo === "" ? 0 : dizimo).replace('.', '').replace(',', '.'))
      )
    ).replace('.', ',');
    const validar = parseInt(valor.replace(",", "").replace(".", "")) > 0 ? true : false;
    if (numero === "")
      return alert("Informe o numero do talão!")

    if (!validar)
      return alert("Informe a entrada do dizimo ou da orfeta corretamentamente !")



    handleRegisterTalao();


    setDizimo("");
    setOferta("");
    inputRef.current.focus();




  }
  const getData = () => {
    api.postGetDate({}).then(({ data }) => {
      if (data) {
        const date = converter.Date(data);

        setDataSistem(date);
        setData(date.data);
        setDia(date.dia);
        setMes(date.mes);
        setAno(date.ano);
        setMesano(date.mes + "" + date.ano);
        const dt = new Date(date.data);
        
        if (info.geral == 1)
          dt.setDate(dt.getDate() - 5);
        else
          dt.setDate(dt.getDate() - 3);

        if (info.geral == 3 || info.geral == 2)
          setData_min("2023-05-01");
        else
          setData_min(converter.Dates(dt.toISOString()).data);

        setData_max(date.data);
      }
    });
  }


  function onchange_dinheiro(ev) {

    set_dinheiro(formatarMoeda(ev));

  }
  function onchange_pix(ev) {

    set_pix(formatarMoeda(ev));

  }
  function onchange_cartao(ev) {
    set_cartao(formatarMoeda(ev));
  }

  function formatarMoeda(valor) {


    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';

    if (valor.length > 2)
      valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length == 2)
      valor = valor.replace(/([0-9]{2})$/g, "0,$1");



    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    if (valor.length >= 10) {
      alert("valor acima do permitido")
    }

    //alert(valor.length)





    return valor;

  }

  useEffect(() => {
    getData();
    getNumeroTalao();
    getTalao();
  }, []);
  const [listTalao, setListTalao] = useState([{}]);
  const getTalao = () => {
    api
      .postGetTalao({
        setor: id_setor,
        congregacao: id_congregacao,
        info: info
      })
      .then(({ data }) => {
        if (data) {
          setListTalao(data);

        }
      });
  };
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  function handleData(e) {
    let date = e.target.value;
    setData(date);
    setMesano(converter.Dates(date).mes + "" + converter.Dates(date).ano)
  }
  function handleLogin(e) {
    e.preventDefault();
  }

  return (
    <div style={{ backgroundColor: "#000" }} className="container-culto">
      <NavBar navegando="ENTRADA DO CULTO " />
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
      <Form className="container-form" onSubmit={handleLogin}>
        <div //Cabeçario
          style={{
            display: "flex", flexDirection: "column", borderRadius: 15, backgroundColor: "#fff",
            width: "100%", marginLeft: 10, color: "#000"
          }}>
          <label>DATA DO SISTEMA: {dataSistem?.date}</label>
          <label>SETOR: {info?.nome_setor} </label>
          <label>CONGREGAÇÃO: {info?.congregacao} </label>
          <label>{info?.cargo_nome}: {info?.nome} </label>



        </div>

        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <Row //Nome da congragação
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-culto">N° DO TALÃO</Form.Label>
            <Form.Control
              ref={inputRef}
              className="f"
              type="text"
              placeholder="Digite o talão"
              required
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className="label-culto">DATA</Form.Label>
            <Form.Control
              className="f"
              type="date"
              min={data_min}
              max={data_max}
              value={data}
              onChange={handleData}
            />
          </Form.Group>
        </Row>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <Row // DIZIMO E OFERTA 
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-culto">DIZIMO R$</Form.Label>
            <Form.Control
              className="f"
              type="text"
              placeholder="Digite o valor do dizimo"
              value={dizimo}
              onChange={(e) => setDizimo(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className="label-culto">OFERTA R$</Form.Label>
            <Form.Control
              className="f"
              type="text"
              placeholder="Digite o valor da oferta"
              value={oferta}
              onChange={(e) => setOferta(e.target.value)}
            />
          </Form.Group>
        </Row>
        <hr></hr>
        <Accordion   >
          <Accordion.Item eventKey="0" >
            <Accordion.Header>DETALHAR ENTRADAS</Accordion.Header>
            <Accordion.Body>
              <div style={{ display: 'flex', flexDirection: "column" }}>
                <label style={{ width: 100 }}>PIX
                </label>
                <input style={{ width: 200 }} value={pix} onChange={(e) => onchange_pix(e.target.value)} placeholder='R$ 0,00' />

                <label style={{ width: 100 }}>CARTÃO
                </label>
                <input style={{ width: 200 }} value={cartao} onChange={(e) => onchange_cartao(e.target.value)} placeholder='R$ 0,00' />
                <label style={{ width: 100 }}>DINHEIRO
                </label>
                <input style={{ width: 200 }} value={dinheiro} onChange={(e) => onchange_dinheiro(e.target.value)} placeholder='R$ 0,00' disabled />

              </div>

            </Accordion.Body>
          </Accordion.Item>

        </Accordion>
        <hr />
        <Row //Total
          className="mb-3"
        >

          <Form.Group as={Col}>
            <Form.Label style={{ fontSize: 36 }} className="label-culto a-f-r">TOTAL R$ {converter.ConverterReal(
              String(
                parseFloat(
                  parseFloat(String(oferta === "" ? 0 : oferta).replace('.', '').replace(',', '.')) +
                  parseFloat(String(dizimo === "" ? 0 : dizimo).replace('.', '').replace(',', '.'))
                ).toFixed(2)
              ).replace('.', ','))

            }</Form.Label>
          </Form.Group>

        </Row>

        <div className="button-culto">
          <Button
            style={{ width: 200, height: 60, fontWeight: "bold", fontSize: 24 }}
            className="button-culto-salvar"
            variant="primary"
            onClick={addTalao}
          >
            Salvar
          </Button>
          {/* <Button
            className="button-culto-cancelar"
            onClick={() => handleShow(true)}
          >
            Cancelar
          </Button> */}
        </div>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <ListarTalao listar={listTalao}></ListarTalao>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>

      </Form>

    </div>
  );
}

export default Culto;
