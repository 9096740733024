import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

export default function Private({ children }) {
    const [loading, setLoading] = useState(false);
    const [signed, setSigned] = useState(true);

    useEffect(() => {
        async function checkLogin() {
            const unsub = true;
        }
        localStorage.setItem("@detalheUser", "");
    }, [])

    if (loading) {
        return (
            <div></div>
        )
    }
    if (!signed) {
        return <Navigate to="/login" />
    }

    return children;
}