import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import "./inicio.css";
import BarNav from '../../components/NavBar'
import { useState, useEffect } from "react";
import api from "../../services/api";
import imgFundo from "../../imagens/ad_inicio.jpg";




function Inicio() {
  ////////////////////////////////////////////////////////////////////////////////
  const [congregacaoNome, setCongregacaoNome] = useState(localStorage.getItem('congregacao') || "Não Logado");
  const [setorNome, setSetorNome] = useState(localStorage.getItem('setor') || "Não Logado");
  const [setor, setSetor] = useState(localStorage.getItem('id_setor') || "Não Logado");
  const [congregacao, setCongregacao] = useState(localStorage.getItem('id_congregacao') || "Não Logado");
  const [user, setUser] = useState(localStorage.getItem('user') || "Não Logado!");
  const [geral, setGeral] = useState(localStorage.getItem('geral') || 0);
  const [cargo, setCargo] = useState(localStorage.getItem('cargo') || "Não logado");
  const [info, setInfo] = useState(JSON.parse(localStorage.getItem('config')) || []);
  const [link, setLink] = useState(JSON.parse(localStorage.getItem('link')) || []);
  const [showAviso, setShowAviso] = useState(true);
  ////////////////////////////////////////////////////////////////////////////////
  const [listSetor, setListSetor] = useState([]);
  const [listCongragacao, setListCongregacao] = useState([]);
  const getCongragacao = (val) => {
    api.postGetCongregacaoSetor({
      setor: val,
      info: info
    }).then(({ data }) => {
      if (data) {
        setListCongregacao(data);
      }
    });
  }
  const getSetor = () => {
    api.postGetSetor({ info }).then(({ data }) => {
      if (data) {
        setListSetor(data);
      }
    });
  }
  useEffect(() => {

    getCongragacao();

    //getSetor();
    // api.get('List').then(({ data }) => {
    //     setProd(data)
    //});

  }, []);
  function selectSetor(ev) {
    let v = ev.target.value;
    console.log("id setor", ev)

    if (v !== "") {
      setSetor(v);
      getCongragacao(v);
      listSetor.map((value) => {
        if (value.id_setor == v)
          setSetorNome(value.nome_setor)
      })
    }
  }
  function selectCongregacao(ev) {
    let v = ev.target.value;
    //console.log("id setor",v)
    setCongregacao(v);
    if (v !== "") {
      listCongragacao.map((value) => {
        if (value.id_setor == v)
          setSetorNome(value.nome_setor)
      })

    }

    getCongragacao(v);
  }

  return (
    <div style={{ backgroundColor: "#000", height: 720 }}>
      <div >
        <BarNav navegando="INICIO" />
      </div>

      <div style={{ display: "flex", flexDirection: "column", color: "#000", backgroundImage: `url(${imgFundo})`, width: "100%", height: "100%", fontWeight: "bold" }}>
        <label>NOME DO SETOR: {info.nome_setor} </label>
        <label>NOME DA CONGREGAÇÃO: {info.congregacao} </label>
        <label>{info.nome_cargo}: {info.nome} </label>
        {geral == 3 ?
          <Row
            className="mb-3"
          >
            <Form.Group className="tm" as={Col}>
              <Form.Label className="label-igreja">SETOR</Form.Label>
              <Form.Select
                onChange={selectSetor}
                defaultValue="0"
              >
                <option value="">{""}</option>
                {
                  listSetor.map((list, index) => (
                    <option key={index} value={"" + list.id_setor}>{list.nome_setor}</option>
                  ))
                }

              </Form.Select>
            </Form.Group>
            <Form.Group className="tm" as={Col}>
              <Form.Label className="label-igreja">CONGREGAÇÃO</Form.Label>
              <Form.Select

                onChange={(e) => setCongregacao(e.target.value)}
                defaultValue="1"
              >
                {
                  listCongragacao.map((list, index) => (
                    <option key={index} value={"" + list.id_congregacao}>{list.congregacao}</option>
                  ))
                }
              </Form.Select>
            </Form.Group>

          </Row>
          : ""
        }
        <div>

        </div>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>

      </div>
      <Modal //Editar Add

        show={showAviso} fullscreen={false} onHide={() => setShowAviso(false)}>

        <Modal.Header closeButton>
          <Modal.Title
          >
            <div style={{ display: 'flex', flexDirection: "column" }}>
              <label>Aviso</label>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div style={{ height: 300 }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <label>Teremos 3 dias para realizar os lançamento de talões e despesas!</label>
              <label>Mantenha em dia, grato!</label>
              <label>Deus abençõe!</label>
              <Button style={{ marginBottom: 10 }} onClick={() => setShowAviso(false)} >FECHAR</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>


    </div>

  );
}

export default Inicio;
