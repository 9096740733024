import React from "react";

function ListarDizimista({listar}) {
    const [total,setTotal] = React.useState(0);
    function ToReal(valor) {
        return String(parseFloat(valor).toFixed(2)).replace('.',',');
      }
    
     function ToFloat(valor) {
        return parseFloat(valor.replace('.','').replace(',','.')).toFixed(2);          
      }

        return (
            <>
              <div style={{width:"100%",display:"flex",flexDirection:"row",backgroundColor:"#1e222b"}}>  
                <label style={{width:"100%",textAlign:"center",fontSize:24,textDecorationLine:"underline",textDecorationColor:"#fff"}}>Lista de dizimista</label>
              </div>
            {
                listar.map((list,index)=>(
                    index % 2 ===0 ?
                    <div key={index} style={{width:"100%", display:"flex",flexDirection:"row",borderBottomStyle:"solid",borderBlockColor:"#1e222b"}}>
                    <label style={{color:"#000",width:"100%",fontSize:20}}>{list.membro}</label>
                    <div style= {{display:"flex",flexDirection:"row-reverse",width:"100%"}}>
                        <label style={{color:"#000",fontSize:20,width:80,textAlign:"right"}}>{ToReal(list.dizimo)}</label>
                        <label style={{color:"#000",fontSize:20}}>R$</label>
                    </div>
                    </div>
                    :
                    <div key={index} style={{width:"100%", display:"flex",flexDirection:"row",borderBottomStyle:"solid",borderBlockColor:"black"}}>
                    <label style={{color:"#000",width:"100%",fontSize:20,backgroundColor:"#c9c9c9"}}>{list.membro}</label>
                    <div style= {{display:"flex",flexDirection:"row-reverse",width:"100%",backgroundColor:"#c9c9c9"}}>
                        <label style={{color:"#000",fontSize:20,width:80,backgroundColor:"#c9c9c9",textAlign:"right"}}>{ToReal(list.dizimo)}</label>
                        <label style={{color:"#000",fontSize:20,backgroundColor:"#c9c9c9"}}>R$</label>
                    </div>
                    </div>
                  
                    
                ))

            }
         
            </>
        )
    }
export default ListarDizimista;