import axios from 'axios';
class Api
{
  
     URL= process.env.NODE_ENV == 'production' ? "http://adbelemamparo.com.br:5009/":"http://adbelemamparo.com.br:5005/"

    link =JSON.parse( localStorage.getItem('link'));
     // Excluir
async TalaoLancado(dados)
{
    return axios({
        url:this.link.gettalaolancado,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
} 
async BatismoCadUpdateCad(dados)
{
    return axios({
        url:this.URL+"batismo/update",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
} 
async BatismoCadUpdate(dados)
{
    return axios({
        url:this.URL+"batismo_pre_cad_update",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
} 

async BatismoCadExcluir(dados)
{
    return axios({
        url:this.URL+"batismo_pre_cad_excluir",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
} 
async BatismoSetor(dados)
{
    return axios({
        url:this.URL+"batismo_setor",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
} 
async BatismoRelatorio(dados)
{
    return axios({
        url:this.URL+"batismo_relatorio",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
} 
async BatismoPreLista(dados)
{
    return axios({
        url:this.URL+"batismo_pre_lista",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
} 
async BatismoPreCadastro(dados)
{
    return axios({
        url:this.URL+"batismo_pre_insert",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}  
async BatismoListarCongregacao(dados)
{
    return axios({
        url:this.URL+"batismo_lista_congregacao",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async BatismoLogin(dados)
{
    return axios({
        url:this.URL+"login_pre_batismo",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}  
async ExcluirDespesaGeral(dados)
{
    return axios({
        url:this.link.excluir_despesa,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}  
 
async ExcluirTalaoGeral(dados)
{
    return axios({
        url:this.link.excluir_talao,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
} 

async ExcluirDizimistaGeral(dados)
{
    return axios({
        url:this.link.excluir_dizimista,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}     
   // Alterar
   async UpdateDespesaGeral(dados)
   {
       return axios({
           url:this.link.alterar_despesa,
           method:"POST",
           timeout:5000,
           data:dados,
           headers:{
               Accept:'application/json'
           }
   
       }).then((data) =>{
           return Promise.resolve(data)
      
       }).catch((error) =>{
           return Promise.reject(error)
       })
       
}   
   async UpdateTalaoGeral(dados)
   {
       return axios({
           url:this.link.alterar_talao,
           method:"POST",
           timeout:5000,
           data:dados,
           headers:{
               Accept:'application/json'
           }
   
       }).then((data) =>{
        
           return Promise.resolve(data)
      
       }).catch((error) =>{
           return Promise.reject(error)
       })
       
}   
   async UpdateDizimistaGeral(dados)
   {
       return axios({
           url:this.link.alterar_dizimista,
           method:"POST",
           timeout:5000,
           data:dados,
           headers:{
               Accept:'application/json'
           }
   
       }).then((data) =>{
           return Promise.resolve(data)
      
       }).catch((error) =>{
           return Promise.reject(error)
       })
       
}   


    //geral geralrelatoriomensal
    
    async postGetRelatorioGeralSetor(dados)
    {
        return axios({
            url:this.link.geralrelatoriosetor,
            method:"POST",
            timeout:5000,
            data:dados,
            headers:{
                Accept:'application/json'
            }
    
        }).then((data) =>{
            return Promise.resolve(data)
       
        }).catch((error) =>{
            return Promise.reject(error)
        })
        
}   
    async postGetNomesDizimista(dados)
    {
        return axios({
            url:this.link.getnomesdizimista,
            method:"POST",
            timeout:5000,
            data:dados,
            headers:{
                Accept:'application/json'
            }
    
        }).then((data) =>{
            return Promise.resolve(data)
       
        }).catch((error) =>{
            return Promise.reject(error)
        })
        
}   
async postGeralRelatorioTalao(dados)
    {
      
        return axios({
           
            url:this.link.geralrelatoriotalao,
            method:"POST",
            timeout:5000,
            data:dados,
            headers:{
                Accept:'application/json'
            }
    
        }).then((data) =>{
            return Promise.resolve(data)
       
        }).catch((error) =>{
            return Promise.reject(error)
        })
        
}

async postGeralRelatorioMesal(dados)
{
    console.log(this.link.geralrelatoriomensal)
    return axios({
       
        url:this.link.geralrelatoriomensal,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
//Tesouraria set
async postSetTalao(dados)
{
    return axios({
        url:this.link.settalao,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postSetDespesa(dados)
{
    return axios({
        url:this.link.setdespesa,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
//Tesouraria get

async postGetTalaoNumero(dados)
{
    return axios({
        url:this.link.gettalaonumero,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postGetTalao(dados)
{
    return axios({
        url:this.link.gettalao,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postGetMesanoTalao(dados)
{
    return axios({
        url:this.link.getmesanotalao,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postGetMesanoTalaoGeral(dados)
{
    return axios({
        url:this.link.gettalaogeral,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postGetDespesa(dados)
{
  
    return axios({
        url:this.link.getdespesa,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postGetDespesaGeral(dados)
{
  
    return axios({
        url:this.link.getdespesageral,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

//Outros
async postGetDate(dados)
{
    return axios({
        url: `${this.URL}data`,
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postGetSetor(dados)
{
    return axios({
        url:this.link.geral+"/select_setor",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postGetCargo(dados)
{
    return axios({
        url:this.link.geral+"/select_cargo",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postGetCongregacaoSetor(dados)
{
    return axios({
        url:this.link.geral+"/select_congregacao_setor",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postGetCongregacao(dados)
{
    return axios({
        url:this.link.geral+"/select_congregacao",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postGetDespesaSoma(dados)
{
    return axios({
        url:this.link.geral+"/select_despesa_soma",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postGetTalaoDetail(dados)
{
    return axios({
        url:this.link.geral+"/select_talao_detail",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postGetTalaoRelatorio(dados)
{
    return axios({
        url:this.link.geral+"/select_talao_relatorio",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postGetDizimistaSoma(dados)
{
    return axios({
        url:this.link.geral+"/select_dizmista_soma",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postGetDizimista(dados)
{
    return axios({
        url:this.link.geral+"/select_dizimista",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postGetDizimistaMensal(dados)
{
    return axios({
        url:this.link.geral+"/select_dizimista_mensal",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postSetMembro(dados)
{
    return axios({
        url:this.link.geral+"/insert_membro",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postSetDizimista(dados)
{
    return axios({
        url:this.link.geral+"/insert_dizimista",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}

async postSetCongregacao(dados)
{
    return axios({
        url:this.link.geral+"/insert_congregacao",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postSetAdministrador(dados)
{
    return axios({
        url:this.link.geral+"/insert_administador",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async putSetAdministrador(dados)
{
    return axios({
        url:this.link.geral+"/update_administador",
        method:"PUT",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postSetSetor(dados)
{
    return axios({
        url:this.link.geral+"/insert_setor",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postGetRelatorioMensalDizimo(dados)
{
    return axios({
        url:this.link.geral+"/select_relatorio_mensal_dizimo",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postGetRelatorioMensalOrferta(dados)
{
    return axios({
        url:this.link.geral+"/select_relatorio_mensal_orfeta",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postGetRelatorioMensalDespesa(dados)
{
    return axios({
        url:this.link.geral+"/select_relatorio_mensal_despesa",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}


async postGetRelatorioMensalDespesaTotal(dados)
{
    return axios({
        url:this.link.geral+"/select_relatorio_mensal_despesa_total",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
async postGetRelatorioMensalTalao(dados)
{
    return axios({
        url:this.link.geral+"/select_relatorio_mensal_talao",
        method:"POST",
        timeout:5000,
        data:dados,
        headers:{
            Accept:'application/json'
        }

    }).then((data) =>{
        return Promise.resolve(data)
   
    }).catch((error) =>{
        return Promise.reject(error)
    })
    
}
   async postGetAdmin(dados)
   {
       return axios({
           url:this.link.geral+"/select_admin",
           method:"POST",
           timeout:5000,
           data:dados,
           headers:{
               Accept:'application/json'
           }

       }).then((data) =>{
           return Promise.resolve(data)
      
       }).catch((error) =>{
           return Promise.reject(error)
       })
       
   }
    async post(url,dados)
    {
        return axios({
            url:this.link.geral+url,
            method:"POST",
            timeout:5000,
            data:dados,
            headers:{
                Accept:'application/json'
            }

        }).then((data) =>{
            return Promise.resolve(data)
       
        }).catch((error) =>{
            return Promise.reject(error)
        })
        
    }
    async put(url,dados)
    {
        return axios({
            url:this.link.geral+url,
            method:"PUT",
            timeout:5000,
            data:dados,
            headers:{
                Accept:'application/json'
            }

        }).then((data) =>{
            return Promise.resolve(data)
       
        }).catch((error) =>{
            return Promise.reject(error)
        })
        
    }
   
    async login(dados)
    {
        return axios({   
            url:this.URL+"login",
            method:"POST",
            timeout:5000,
            data:dados,
            headers:{
                Accept:'application/json'
            }

        }).then((data) =>{
            return Promise.resolve(data)
        }).then((datas)=>{
            return  datas.data;
        }).catch((error) =>{
            return Promise.reject(error)
        })
    }
}

const api = new Api()
export default api;