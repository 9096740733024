import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import NavBar from "../../../components/NavBar";
import api from "../../../services/api";
import { useState, useEffect, useRef } from "react";
import "./dizimista.css";
import ListarDizimista from "../../../components/Listar/ListarDizimista";
import ListarDizimistaGeral from "../../../components/Listar/ListarDizimistaGeral";
import { useReactToPrint } from "react-to-print";
import ReactToPrint from 'react-to-print';
import { Link } from "react-router-dom";
import imgLogo from "../../../imagens/adbelem.png";
import converter from "../../../Util/Converter";
import { ListarData } from "../../../hooks/datas";
import { Accordion, Table } from "react-bootstrap";
import lupa from "../../../imagens/search.png";
import { Get } from "../../../Api";


function RelatorioGeralDizimistaMesal() {
  const inputRef = useRef(null);
  const componentRef = useRef();
  const componentRefvalor = useRef();

  const handlePrint = useReactToPrint({

    content: () => componentRef.current,
  });
  const handlePrintvalor = useReactToPrint({

    content: () => componentRefvalor.current,
  });
  ////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState("");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState("0");
  const [isData, setIsData] = useState("1");
  const [dataString, setDataString] = useState("1");
  const [log, setLog] = useState("log");

  const [setor, setSetor] = useState(0);
  const [congregacao, setCongregacao] = useState(0);
  const [nomeSetor, setNomeSetor] = useState("");
  const [nomeCongregacao, setNomeCongregacao] = useState("");

  ////////////////////////////////////////////////////////////////////////////////
  const [info, setInfo] = useState(JSON.parse(localStorage.getItem('config')) || []);
  const [data, setData] = useState("");
  const [dia, setDia] = useState(0);
  const [mes, setMes] = useState(0);
  const [ano, setAno] = useState(0);
  const [mesano, setMesano] = useState("0");
  const [mesResumo, setMesResumo] = useState("");
  const [mesRelatorio, setMesRelatorio] = useState("");
  const [totalEntradas, setTotalEntradas] = useState("0,00");
  const [totalSaidas, setTtotalSaidas] = useState("0,00");
  const [saldo, setSaldo] = useState("0,00");
  const [dizimo, setDizimo] = useState("0,00");
  const [oferta, setoferta] = useState("0,00");
  const [listTalao, setListTalao] = useState([]);
  const [listDespesa, setListDespesa] = useState([]);
  const nomeMes = ["", "JANEIRO", "FEVEREIRO", "MARÇO", "ABRIL", "MAIO", "JUNHO", "JULHO", "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"];
  const rowsSaidas = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
  const [listCongragacao, setListCongregacao] = useState([]);
  const [listSetor, setListSetor] = useState([]);
  const [listar_dizimista, set_listar_dizimista] = useState([]);
  //////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  const [valorDizimistaTalao, setValorDizimistaTalao] = useState(0);
  const [id_setor, setIdSetor] = useState(0);
  const [id_congregacao, setIdCongregacao] = useState(0);
  const [listDizimista, setListDizimista] = useState([]);
  const [listDizimistaValor, setListDizimistaValor] = useState([]);
  const [nomeDizimista, setNomeDizimista] = useState("");
  const [valorDizimista, setValorDizimista] = useState("");
  const [valorEntrada, setValorEntrada] = useState(0);
  const [valorTotal, setValorTotal] = useState("0,00");
  const [nome_dizimista, set_nome_dizimista] = useState("");


  const PesquisarDizimista = async () => {

    const { data } = await Get(`/ts/dizimista/pequisar/nome?setor_id=${setor}&congregacao_id=${congregacao}&nome=%${nome_dizimista}%`);
    console.log(data);
    set_listar_dizimista(data.data)


  }
  //select_dizmista_soma
  function selectSetor(ev) {
    let v = ev.target.value;
    zerarInfo();
    setSetor(v);
    if (v > 0) {
      getCongragacao(v);
      setNomeSetor(getNomeSetor(v));
    }
    inputRef.current.value = '';
  }
  function selectCongregacao(ev) {
    let v = ev.target.value;
    zerarInfo();

    setCongregacao(v);
    setNomeCongregacao(getNomeCongregacao(v));
    inputRef.current.value = '';
  }
  const getCongragacao = (val) => {
    api.postGetCongregacaoSetor({
      setor: val,
      info: info,
    }).then(({ data }) => {
      if (data) {
        setListCongregacao(data);
      }
    });
  }
  const getSetor = () => {
    api.postGetSetor({ info }).then(({ data }) => {
      if (data) {
        setListSetor(data);
      }
    });
  }
  function zerarInfo() {
    setListTalao([]);
    setListDespesa([]);
    setDizimo('0,00');
    setoferta('0,00');
    setTotalEntradas('0,00');
    setTtotalSaidas('0,00');
  }


  function getNomeSetor(s) {
    const ss = listSetor.find(setor => setor.id_setor == s);
    return ss.nome_setor;
  }
  function getNomeCongregacao(c) {
    const cc = listCongragacao.find(congregacao => congregacao.id_congregacao == c);
    return cc.congregacao;
  }

  const getDizmistaMensal = (v) => {
    api
      .postGetDizimistaMensal({
        setor: setor,
        congregacao: congregacao,
        info: info,
        mesano: v,
        grupo: 2
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setListDizimista(data[2]);
          setValorTotal(data[1][0].dizimo);
          setListDizimistaValor(data[2]);
          console.log(data);

        }
      });
  };

  const getData = () => {
    api.postGetDate({ info }).then(({ data }) => {
      if (data) {
        let date = converter.Date(data);

        setDia(date.dia);
        setMes(date.mes);
        setAno(date.ano);
      }
    });
  };

  useEffect(() => {
    getData();
    getSetor();

  }, []);

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }


  function handleLogin(e) {
    e.preventDefault();
  }

  function onchangeListarData(ev) {
    let v = ev.target.value;


    setListDizimista([{}])
    const texto = ev.target.options[ev.target.selectedIndex].text;
    setMesResumo(texto);

    getDizmistaMensal(v);

  }


  return (

    <div style={{ backgroundColor: "#000" }} className="container-culto">
      <div>
        <NavBar navegando="DIZIMISTA GERAL " />
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <Form style={{ width: "95%", marginLeft: 10 }} onSubmit={handleLogin}>
          <div //Cabeçario
            style={{
              display: "flex", flexDirection: "column", borderRadius: 15, backgroundColor: "#fff",
              width: "100%", marginLeft: 10, color: "#000"
            }}>
            <label>
              DATA DO SISTEMA: {dia}/{mes}/{ano}
            </label>
            <label>SETOR: {info.nome_setor} </label>
            <label>CONGREGAÇÃO: {info.congregacao} </label>
            <label>
              {info.nome_cargo}: {info.nome}{" "}
            </label>
          </div>
          <Row
            className="mb-3"
          >
            <Form.Group className="tm" as={Col}>
              <Form.Label style={{ color: "#FFF" }}>SETOR</Form.Label>
              <Form.Select
                style={{ maxWidth: 300 }}
                onChange={selectSetor}
                defaultValue="0"
              >
                <option value="0" >{""}</option>
                {
                  listSetor.map((list, index) => (
                    <option key={index} value={"" + list.id_setor}>{list.nome_setor}</option>
                  ))
                }

              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="tm" as={Col}>
              <Form.Label style={{ color: "#FFF" }}>CONGREGAÇÃO</Form.Label>
              <Form.Select
                style={{ maxWidth: 300 }}
                onChange={selectCongregacao}
                defaultValue="0"
              >
                <option value="0" >{""}</option>
                {
                  listCongragacao.map((list, index) => (
                    <option key={index} value={"" + list.id_congregacao}>{list.congregacao}</option>
                  ))
                }
              </Form.Select>
            </Form.Group>
          </Row>
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>

          <Accordion  >
            <Accordion.Item eventKey="0" >
              <Accordion.Header>PESQUISAR POR NOME</Accordion.Header>
              <Accordion.Body>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>

                  <input style={{ width: 400 }} value={nome_dizimista} onChange={(e) => set_nome_dizimista(e.target.value)} placeholder='Nome do Dizimista' /><img src={lupa} onClick={(e) => PesquisarDizimista()}></img>

                </div>
                <div>
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          Nome
                        </th>
                        <th>
                          Talão
                        </th>
                        <th>
                          Data
                        </th>
                        <th>
                          Valor
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listar_dizimista.map((value) => (
                        <tr>
                          <td>
                            {value.membro}
                          </td>
                          <td>
                            {value.talao}
                          </td>
                          <td>
                            {converter.Dates(value.data).date}
                          </td>
                          <td>
                            {converter.ConverterReal(value.dizimo)}
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
          <hr></hr>
          <div //Cabeçario
            style={{
              display: "flex", flexDirection: "column", borderRadius: 15,
              width: "100%", marginLeft: 10
            }}></div>
          <Row className="mb-3">
            <Form.Group className="tm" as={Col}>
              <Form.Label style={{ color: "#FFF" }}>RELATÓRIO DO MÊS</Form.Label>
              <Form.Select
                style={{ maxWidth: 300 }}
                onChange={onchangeListarData} //(e) => setTalao(e.target.value)}
                defaultValue="0"
              >
                <option ></option>
                {
                  ListarData().map((dta) => (
                    <option value={dta.mesano}>{dta.mesnome} DE {dta.ano}</option>
                  ))
                }

              </Form.Select>
            </Form.Group>

          </Row>
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>

          <div
            style={{
              marginTop: 30,
              display: "flex",
              height: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >

            <Button
              style={{ width: 200, height: 50 }}
              onClick={handlePrintvalor}
            //to={'/imprimir_dizimista'}
            >
              IMPRIMIR COM VALOR
            </Button>
            <Button
              style={{ width: 200, height: 50 }}
              onClick={handlePrint}
            //to={'/imprimir_dizimista'}
            >
              IMPRIMIR SEM VALOR
            </Button>
            {/* <ReactToPrint
              trigger={() => <button>IMPRIMIR</button>}
              content={() => componentRef.current}
              
          
           /> */}
          </div>

          <div
            style={{
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundColor: "#fff",
            }}
          >

            <ListarDizimistaGeral listar={listDizimista}></ListarDizimistaGeral>
          </div>
          <div
            style={{
              width: "100%",
              height: 60,
              display: "flex",
              flexDirection: "row-reverse",
              backgroundColor: "#1e222b",
            }}
          >
            <label style={{ fontSize: 24 }}>
              TOTAL R$ {converter.ConverterReal(valorTotal)}
            </label>
          </div>
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>
        </Form>
      </div>


      <div /////////////////////////////////////////////////////////////////////////
        style={{ display: "none" }}

      >
        <style type="text/css" media="print">{"@page { size: portrait; }"}</style>
        <div style={{ margin: 0, padding: 0, width: 800, backgroundColor: "#FFF", }} ref={componentRef}

        >

          <div
            style={{
              display: "flex",
              width: "100%",
              height: 100,
              backgroundColor: "#c9c9c9",
            }}
          >
            <div
              style={{
                display: "flex",
                width: 220,
                height: 200,
                backgroundColor: "#fff",
                justifyContent: "center",
              }}
            >
              <img src={imgLogo} width="200" height="180" />
            </div>
            <div
              style={{ width: "100%", height: "100%", backgroundColor: "#FFF" }}
            >
              <div style={{ width: "100%", height: 40, backgroundColor: "#FFF" }}>

              </div>
              <div style={{ width: "100%", height: 50, backgroundColor: "#FFF" }}>
                <label
                  style={{
                    width: 500,
                    fontSize: 24,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  IGREJA EVANGÉLICA ASSEMBLÉIA DE DEUS
                </label>
              </div>
              <div style={{ width: "100%", height: 40, backgroundColor: "#FFF" }}>
                <label style={{ width: 500, textAlign: "center", fontSize: 18 }}>
                  MINISTÉRIO DE BELÉM - AMPARO - SP
                </label>
              </div>
              <div
                style={{ width: "100%", height: 70, backgroundColor: "#FFF" }}
              ></div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "#FFF",
              width: "100%",
              marginTop: 100,
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <label
              style={{
                width: "100%",
                fontSize: 20,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              DIZIMISTAS DO MÊS DE {mesResumo}
            </label>
            <hr // Linha de separação
              style={{ color: "#000" }}
            ></hr>
            <div style={{ width: "100%", backgroundColor: "#FFF" }}>


              {listDizimista.map((list, index) => (
                <label key={index}
                  style={{
                    width: "100%",
                    fontSize: 18,
                    textAlign: "center",

                  }}
                >
                  {list.membro}
                </label>

              ))}

            </div>
          </div>

        </div>
      </div>
      <div /////////////////////////////////////////////////////////////////////////
        style={{ display: "none" }}

      >
        <style type="text/css" media="print">{"@page { size: portrait; }"}</style>
        <div style={{ margin: 0, padding: 0, width: 800, backgroundColor: "#FFF", }} ref={componentRefvalor}

        >


          <div
            style={{
              backgroundColor: "#FFF",
              width: "100%",
              marginTop: 100,
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <label
              style={{
                width: "100%",
                fontSize: 20,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              DIZIMISTAS DO MÊS DE {mesResumo}
            </label>
            <hr // Linha de separação
              style={{ color: "#000" }}
            ></hr>
            <div style={{ width: "100%", backgroundColor: "#FFF" }}>

              <table style={{ fontSize: 16 }}>
                <tr>
                  <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>DATA</td>
                  <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>Nº TALÃO </td>
                  <td style={{ width: 350, height: 20, textAlign: "center", fontWeight: "bold" }}>NOME DO MEMBRO</td>
                  <td style={{ width: 150, height: 20, textAlign: "center", fontWeight: "bold" }}>VALOR DO DIZIMO</td>
                  {/* <td style={{width:0,height:20,textAlign:"center"}}>.</td> */}
                </tr>

                {listDizimistaValor.map((list, index) => (
                  <tr>
                    <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{converter.Dates(list.data).date}</td>
                    <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{list.talao}</td>
                    <td style={{ width: 400, height: 20, textAlign: "left", fontWeight: "bold" }}>{list.membro}</td>
                    <td style={{ width: 150, height: 20, fontWeight: "bold" }}>R$ <span style={{ float: "right", color: "red" }}>{converter.ConverterReal(list.dizimo)}</span></td>

                  </tr>

                ))}
                <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>-</td>
                <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>-</td>
                <td style={{ width: 400, height: 20, textAlign: "right", fontWeight: "bold" }}>TOTAL</td>
                <td style={{ width: 150, height: 20, fontWeight: "bold" }}>R$ <span style={{ float: "right", color: "red" }}>{converter.ConverterReal(valorTotal)}</span></td>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
}

export default RelatorioGeralDizimistaMesal;
