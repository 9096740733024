
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from 'react-bootstrap/Table';
import NavBar from '../components/NavBar';
import Button from "react-bootstrap/Button";
import { useEffect, useState, useRef } from 'react';
import "./style.css";
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { useReactToPrint } from "react-to-print";
import imgLogo from "../imagens/ad.jpg";



function CadBatismo() {

  const navigate = useNavigate();
  const [relatorioSetor, setRelatorioSetor] = useState([]);
  const [relatorioCongregacao, setRelatorioCongregacao] = useState([]);
  const [total, setTotal] = useState({
    m: 0,
    f: 0,
    t: 0
  });
  const [info, setInfo] = useState(JSON.parse(localStorage.getItem("pre_batsimo")) || { acesso: false });
  const [cadInfo, setCadInfo] = useState(
    {
      id_setor: 0,
      id_congregacao: 0,
      nome_setor: "",
      nome_congregacao: "",
      codigo: 0
    });

  const [tipo, setTipo] = useState(1);
  const [idAterar, setIdAlterar] = useState(0);
  const [nomeAlterar, setNomeAlterar] = useState("");
  const [congregacao_id, setCongregacao_id] = useState(0);
  const [sexo, setSexo] = useState("M");
  const [nome, setNome] = useState("");
  const [whats, setWhats] = useState("");

  const [sexoCad, setSexoCad] = useState("M");
  const [nomeCad, setNomeCad] = useState("");
  const [whatsCad, setWhatsCad] = useState("");

  const [email, setEmail] = useState("");
  const [lista_cadastrado, setLista_cadastrado] = useState([])
  const [lista_congregacao, setLista_congregacao] = useState([{}])
  const [idSetor, setIdSetor] = useState(0);
  const [lstSetor, setLestSetor] = useState([]);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      return new Promise(() => {
        setShow(false);
      });
    }
  });
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  async function gerarRelatorio() {

    const { data } = await api.BatismoRelatorio({});
    const setor = await api.BatismoSetor({});

    const t_m = data.filter(f => f.sexo == "M");
    const t_f = data.filter(f => f.sexo == "F");
    const tt = data.length;
    setTotal(
      {
        m: t_m.length,
        f: t_f.length,
        t: tt
      }
    )
    let r_info_setor = [];
    let r_info_congregacao = [];
    let lst_setor = [];
    setor.data.map((s_value) => {

      const d = data.filter(t => t.id_setor == s_value.id_setor);
      if (d.length > 0) {
        const f = d.filter(f => f.sexo == "F");
        const m = d.filter(m => m.sexo == "M");
        r_info_setor = [...r_info_setor, {
          id_setor: s_value.id_setor,
          nome_setor: s_value.nome_setor,
          qtd_setor: d.length,
          qtd_f: f.length,
          qtd_m: m.length
        }]
        lst_setor.push(d);
      }
    });

    let lts_congregacao = [];
    for (var i = 0; i < 200; i++) {
      lst_setor.map((v) => {

        const con = v.filter(f => f.id_congregacao == i);
        if (con.length > 0) {
          const f = con.filter(f => f.sexo == "F");
          const m = con.filter(m => m.sexo == "M");
          r_info_congregacao = [...r_info_congregacao, {
            id_setor: con[0].id_setor,
            nome_setor: con[0].nome_setor,
            id_congregacao: con[0].id_congregacao,
            nome_congregacao: con[0].nome_congregacao,
            qtd_congregacao: con.length,
            qtd_f: f.length,
            qtd_m: m.length
          }]
          lts_congregacao.push(con);
        }
      });

    }

    let r = [];
    let s = "";
    r_info_setor.map((r_setor, index) => {
      s = r_setor.nome_setor;
      r_info_congregacao.map((r_congregacao, inex) => {
        if (r_congregacao.id_setor == r_setor.id_setor) {
          r = [...r,
          {

            setor: s,
            qtd_total_m: r_setor.qtd_m,
            qtd_total_f: r_setor.qtd_f,
            qtd_total_s: r_setor.qtd_setor,
            congregacao: r_congregacao.nome_congregacao,
            masculino: r_congregacao.qtd_m,
            fermino: r_congregacao.qtd_f,
            total: r_congregacao.qtd_congregacao

          }
          ];
          s = "-";
        }

      });

      r = [...r,
      {

        setor: "---",
        qtd_total_m: "-",
        qtd_total_f: "-",
        qtd_total_s: "-",
        congregacao: "- TOTAL -",
        masculino: "- " + r_setor.qtd_m + " -",
        fermino: "- " + r_setor.qtd_f + " -",
        total: "- " + r_setor.qtd_setor + " -"

      }
      ];
      s = "";
    });

    setRelatorio(r);
    setRelatorioSetor(r_info_setor);

  }
  //insert
  function Pesquisar() { }
  function Novo() { }
  function Alterar() { }
  function Excluir() { }
  function Cancelar() { }
  async function Salvar() {


    let existe = false;

    if (cadInfo.id_congregacao == 0)
      return alert('Escolha uma congregação!')
    if (nome === "")
      return alert('Digite o nome!')

    lista_cadastrado.map((list) => {
      if (list.nome == nome)
        existe = true;
    })
    if (existe)
      return alert('Já cadastrado!');

    const { data } = await api.BatismoPreCadastro({
      sexo: sexo,
      nome: nome,
      whats: whats,
      email: "",
      info: cadInfo
    });
    if (data) {
      setNome("");
      setWhats("");
      Listar(cadInfo);
    }
    else {
      alert("Erro de comunicacão recarregue a pagina!!!")

    }
    console.log("Batismo", data);
    gerarRelatorio();

  }

  async function ListarCongregacao(id) {
    let dinfo = {
      codigo: info.codigo,
      id_setor: id
    }
    const { data } = await api.BatismoListarCongregacao(dinfo);
    console.log(data);
    if (data)
      setLista_congregacao(data)
  }

  async function Listar(infocad) {
    const { data } = await api.BatismoPreLista({ info: infocad });

    if (data)
      setLista_cadastrado(data)
    console.log(data);
  }

  async function listarSetor() {
    const { data } = await api.BatismoSetor({});
    if (data)
      setLestSetor(data);

  }

  useEffect(() => {
    listarSetor();
    gerarRelatorio();

    if (!info.acesso) {
      navigate("/batismo/login");
    }
    // const v = [...setorList].sort((a,b) =>{
    //   return a.setor > b.setor ? 1: -1
    // })
    ListarCongregacao(1)
  }, []);

  async function onChangeCongregacao(e) {
    let id = e.target.value;
    setCongregacao_id(id);
    if (id == '0')
      setCadInfo(
        {
          id_setor: 0,
          id_congregacao: 0,
          nome_setor: "",
          nome_congregacao: "",
          codigo: 0

        });
    lista_congregacao.map((lst) => {

      if (lst.id_congregacao == id) {
        setCadInfo(
          {
            id_setor: lst.fk_setor,
            id_congregacao: lst.id_congregacao,
            nome_setor: lst.nome_setor,
            nome_congregacao: lst.congregacao,
            codigo: info.codigo

          });
        Listar({
          id_setor: lst.fk_setor,
          id_congregacao: lst.id_congregacao,
          nome_setor: lst.nome_setor,
          nome_congregacao: lst.congregacao,
          codigo: info.codigo
        })
      }

    });


  }

  async function onChangeSetor(e) {
    const id = e.target.value;
    setCongregacao_id(0);
    setCadInfo(
      {
        id_setor: id,
        id_congregacao: 0,
        nome_setor: "",
        nome_congregacao: "",
        codigo: info.codigo

      });
    ListarCongregacao(id);
  }
  const [showAlterar, setShowAlterar] = useState(false);
  const [showAlterarCad, setShowAlterarCad] = useState(false);

  function mostrar(lst) {

    setNomeCad(lst.nome);
    setWhatsCad(lst.whats);
    setSexoCad(lst.sexo);

    setNomeAlterar(lst.nome);
    setIdAlterar(lst.id)
    setShowAlterar(true);


  }

  async function alterarCad() {

    const { data } = await api.BatismoCadUpdateCad({
      id: idAterar,
      nome: nomeCad,
      whats: whatsCad,
      sexo: sexoCad,
      info: cadInfo
    });

    if (data) {
      Listar(cadInfo);
      console.log("alterar ", data)
      setShowAlterarCad(false);
    }
    else {
      alert("Erro de comunicação tente novamente!");
    }


  }

  async function alterar(_tipo) {

    const { data } = await api.BatismoCadUpdate({
      presente: _tipo,
      id: idAterar,
      info: cadInfo
    });

    if (data) {
      Listar(cadInfo);
      console.log("alterar ", data)
      setShowAlterar(false);
    }
    else {
      alert("Erro de comunicação tente novamente!");
    }


  }
  async function excluir() {
    console.log("id alterar: ", idAterar)
    const { data } = await api.BatismoCadExcluir({
      id: idAterar,
      info: cadInfo
    });

    if (data) {
      Listar(cadInfo);
      console.log("exclur ", data)
      setShowAlterar(false);
    }
    else {
      alert("Erro de comunicação tente novamente!");
    }

  }

  function cancelar() {
    setShowAlterarCad(false)
  }

  async function alterarInfo() {
    setShowAlterar(false);
    setShowAlterarCad(true);
  }
  const [listaTipoColor, setListaTipoColor] = useState([{
    backgroundColor: "yellow"
  }, { backgroundColor: "green" }, { backgroundColor: "red" }]
  );
  const [listaTipoColorCss, setListaTipoColorCss] = useState([
    "amarelo", "verde", "vermelho"]
  );


  const [relatorio, setRelatorio] = useState([]);
  //ButtonFace
  return (
    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#FFF' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: "100%", alignItems: 'center', fontSize: 30, fontWeight: 'bold' }}>
        <Modal //modal de pesquisa
          show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Button
                style={{ height: 60, width: 200, borderRadius: 5 }}
                onClick={handlePrint}
              >IMPRIMIR
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div //////////////////////////Impresãoooooooooo
              style={{ flexDirection: "column", width: 1133, height: "100%", background: "#FFF" }} >
              <style type="text/css" media="print">{"@page { size: portrait; } @media print{#noprint{display:none}}"}</style>
              <div style={{ display: "flex", flexDirection: "column", width: 1333, height: "100%", background: "#FFF" }} ref={componentRef}>
                <div style={{ display: "flex", flexDirection: "rows" }}>
                  <div style={{ marginLeft: 70 }}> <img src={imgLogo} width="100" height="90" /></div>
                  <div style={{ marginLeft: 20 }} className="geral-cabecario-text">
                    <label style={{ fontSize: 24, fontWeight: "bold" }} className="ft-c">IGREJA EVANGELICA  ASSEMBLÉIA DE DEUS MINISTÉRIO DO BELÉM  </label>
                    <label style={{ fontSize: 20, fontWeight: "bold" }} className="ft-c">Campo Circuito das Águas Amparo/SP</label>
                    <label style={{ fontSize: 20, fontWeight: "bold" }} className="ft-c">CNPJ: 01.139.613/0001-77  Inscrição Estadual ( INSENTA)</label>
                    <label style={{ fontSize: 19, fontWeight: "bold", color: "blue" }} className="ft-c">Presidente - Pr. Nilson Marcos Lima </label>
                    <label style={{ fontSize: 24, fontWeight: "bold" }} className="ft-c">III Batismo 2024</label>
                    <label style={{ fontSize: 24, fontWeight: "bold" }} className="ft-c">01/SETEMBRO/2024</label>
                  </div>
                </div>

                <div>
                  <Table style={{ width: "80%", marginTop: 10 }} striped>
                    <thead>
                      <tr>
                        {/* <td style={{width:0,height:20,textAlign:"center"}}>.</td> */}
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>SETOR</td>
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>CONGREGAÇÃO</td>
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>M</td>
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>F</td>
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>TOTAL</td>


                      </tr>
                    </thead>
                    <tbody>
                      {
                        relatorio.map((value, index) => (

                          <tr key={index}>
                            {/* <td style={{width:0,height:20,textAlign:"center"}}>.</td> */}
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{value.setor}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{value.congregacao}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{value.masculino}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{value.fermino}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{value.total}</td>

                          </tr>
                        ))
                      }
                    </tbody>


                  </Table>
                  <Table style={{ width: "80%", marginTop: 10 }} striped>
                    <thead>
                      <tr>
                        {/* <td style={{width:0,height:20,textAlign:"center"}}>.</td> */}
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>SETOR</td>
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>M</td>
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>F</td>
                        <td style={{ textAlign: "center", fontWeight: "bold" }}>TOTAL</td>


                      </tr>
                    </thead>
                    <tbody>
                      {
                        relatorioSetor.map((r, index) => (

                          <tr key={index}>
                            {/* <td style={{width:0,height:20,textAlign:"center"}}>.</td> */}
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{r.nome_setor}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{r.qtd_m}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{r.qtd_f}</td>
                            <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>{r.qtd_setor}</td>
                          </tr>
                        ))
                      }
                      <tr>
                        {/* <td style={{width:0,height:20,textAlign:"center"}}>.</td> */}
                        <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>---</td>
                        <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>- {total.m} -</td>
                        <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>- {total.f} -</td>
                        <td style={{ width: 100, height: 20, textAlign: "center", fontWeight: "bold" }}>- {total.t} -</td>
                      </tr>
                    </tbody>
                  </Table>

                </div>

              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={showAlterar} fullscreen={false} onHide={() => setShowAlterar(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <label>{nomeAlterar}</label>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <button className="b-btn-presente" onClick={() => alterar(1)} >PRESENTE</button>
              {/* <button className="b-btn-ausente" onClick={()=>alterar(2)} disabled >AUSENTE</button>
                <button className="b-btn-confirmar" onClick={()=>alterar(0)} disabled></button> */}
              <hr></hr>
              <button onClick={() => alterarInfo()} >ALTERAR</button>
              <button className="b-btn-excluir" onClick={() => excluir()}>EXCLUIR</button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showAlterarCad} fullscreen={false} onHide={() => setShowAlterarCad(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <label>{nomeAlterar}</label>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

              <hr></hr>
              <Row className="mb-3">
                <Form.Group className="tm" as={Col}>
                  <Form.Label className="tm">SEXO</Form.Label>
                  <Form.Select
                    style={{ maxWidth: 200 }}
                    onChange={(e) => setSexoCad(e.target.value)}
                    value={sexoCad}
                  >
                    <option value={"M"}>MASCULINO</option>
                    <option value={"F"}>FEMININO</option>


                  </Form.Select>
                </Form.Group>
              </Row>
              <hr></hr>
              <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                <label>NOME COMPLETO</label>
                <input type='text' value={nomeCad} onChange={(e) => setNomeCad(e.target.value.toUpperCase())} />
              </div>

              <hr></hr>
              <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 500 }}>
                <label>WHATSAPP</label>
                <input type='text' value={whatsCad} onChange={(e) => setWhatsCad(e.target.value)} />
              </div>

              <hr></hr>


              <hr></hr>
              <button onClick={() => alterarCad()} >SALVAR</button>
              <button className="b-btn-excluir" onClick={() => cancelar()}>CANCELAR</button>
            </div>
          </Modal.Body>
        </Modal>
        <label>III Batismo 2024</label>
        <label>01/SETEMBRO/2024</label>
        <label style={{ fontSize: 16 }}>{cadInfo.nome_setor}</label>
        <label style={{ fontSize: 16 }}>{cadInfo.nome_congregacao}</label>
      </div>
      <hr></hr>
      <fieldset>
        <legend style={{ paddingLeft: 10, width: "100%", borderBottomWidth: 1, borderStyle: 'solid', backgroundColor: "orange", textAlign: 'center' }}>
          Cadastro para Batismo
        </legend>

        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, borderRadius: 10, backgroundColor: 'ButtonFace' }}>
          <Row className="mb-3">
            <Form.Group className="tm" as={Col}>
              <Form.Label className="tm">TIPO DE CADASTRO</Form.Label>
              <Form.Select
                style={{ maxWidth: 500 }}
                onChange={setTipo}
                defaultValue="1"
                disabled

              >
                <option value="0">COM CONFIRMAÇÂO</option>
                <option value="1" selected>PRESENTE </option>
              </Form.Select>
            </Form.Group>
          </Row>
          <hr></hr>
          <Row className="mb-3">
            <Form.Group className="tm" as={Col}>
              <Form.Label className="tm">SETOR</Form.Label>
              <Form.Select
                style={{ maxWidth: 500 }}
                onChange={onChangeSetor}
                defaultValue="1"

              >
                {
                  lstSetor.map((list, index) => (
                    <option value={list.id_setor}>{list.nome_setor}</option>
                  ))
                }


              </Form.Select>
            </Form.Group>
          </Row>
          <hr></hr>
          <Row className="mb-3">
            <Form.Group className="tm" as={Col}>
              <Form.Label className="tm">CONGREGAÇÃO</Form.Label>
              <Form.Select
                style={{ maxWidth: 500 }}
                onChange={onChangeCongregacao}
                value={congregacao_id}

              >
                <option value={"0"}></option>
                {
                  lista_congregacao.map((list) => (
                    <option value={list.id_congregacao}>{list.congregacao}</option>
                  ))
                }


              </Form.Select>
            </Form.Group>
          </Row>
          <hr></hr>
          <Row className="mb-3">
            <Form.Group className="tm" as={Col}>
              <Form.Label className="tm">SEXO</Form.Label>
              <Form.Select
                style={{ maxWidth: 200 }}
                onChange={(e) => setSexo(e.target.value)}
                defaultValue="M"
              >
                <option value={"M"}>MASCULINO</option>
                <option value={"F"}>FEMININO</option>


              </Form.Select>
            </Form.Group>
          </Row>
          <hr></hr>
          <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <label>NOME COMPLETO</label>
            <input type='text' value={nome} onChange={(e) => setNome(e.target.value.toUpperCase())} />
          </div>

          <hr></hr>
          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 500 }}>
            <label>WHATSAPP</label>
            <input type='text' value={whats} onChange={(e) => setWhats(e.target.value)} />
          </div>
          <div>
            <hr></hr>
            <Button style={{ marginRight: 10 }}
              onClick={Salvar}
            >
              SALVAR
            </Button>
            <Button
              onClick={() => setShow(true)}
            >
              RELATÓRIO
            </Button>
          </div>



        </div>
        <hr></hr>

      </fieldset>
      <div style={{ width: "100%", display: "flex" }}>
        <label className={listaTipoColorCss[1]} style={{ width: 120 }}>{"Presente"}</label>
        <label className={listaTipoColorCss[0]} style={{ width: 120 }}>{""}</label>
        <label className={listaTipoColorCss[2]} style={{ width: 120 }}>{"Ausente"}</label>
      </div>
      <hr></hr>
      <Table style={{ backgroundColor: 'ButtonShadow' }} striped bordered hover>
        <thead>
          <tr>
            <th>NOME</th>
          </tr>
        </thead>
        <tbody>
          {
            lista_cadastrado.map((list) => (
              <tr className={listaTipoColorCss[list.presente]} >
                <td onClick={() => mostrar(list)}>{list.nome}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  );
}

export default CadBatismo;