import Modal from "react-bootstrap/Modal";
import { useState, useRef, useEffect } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Chart } from "react-google-charts";
import './style.css'
import { useReactToPrint } from "react-to-print";
import api from "../services/api";

function RelatorioBatimo(){
    const [lstRelatorio,setLstRelatorio] = useState([]);
    const [show, setShow]=useState(true);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      onAfterPrint: () => {
        return new Promise(() => {
          setShow(false);
        });
      }
    });


     
    const data = [
        ["Element", "Density",{ role: "style" }],
        ["Sede", 8.94, "#b87333"], // RGB value
        ["Pedreira", 10.49, "silver"], // English color name
        ["Monte Alegre do Sul", 19.3, "gold"],
        ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
        ["Copper", 8.94, "#b87333"], // RGB value
        ["Silver", 10.49, "silver"], // English color name
        ["Gold", 19.3, "gold"],
        ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
        ["Copper", 8.94, "#b87333"], // RGB value
        ["Silver", 10.49, "silver"], // English color name
        ["Gold", 19.3, "gold"],
        ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
        ["Copper", 8.94, "#b87333"], // RGB value
        ["Silver", 10.49, "silver"], // English color name
        ["Gold", 19.3, "gold"],
        ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
        ["Copper", 8.94, "#b87333"], // RGB value
        ["Silver", 10.49, "silver"], // English color name
        ["Gold", 19.3, "gold"],
        ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
      ];
    function Imprinir(){
          getMembro();
          handlePrint();
    }

async  function  getMembro(){
    const {data} =await api.BatismoRelatorio({});
    if(data){
    console.log("relatorio ",data);
    setLstRelatorio(data);
    }
}
 
useEffect(()=>{
    getMembro();
},[]);

return(
    <div className="relatorio-principal">
        <div><label style={{fontSize:30,color:"blanchedalmond"}}>SECRETARIA BATISMO</label></div>
        <div style={{display:"-moz-initial"}}>
        <style type="text/css" media="print">{"@page { size: portrait; } @media print{#noprint{display:none}}"}</style>
            <div ref={componentRef} style={{backgroundColor:"#FFF"}} >
            {
             lstRelatorio.map((valor)=>(
           
           <p><label style={{color:"#000",backgroundColor:"#FFF"}}> Nome: {valor.nome} nome_congregacao:{valor.nome_congregacao}</label></p>
             ))
             }
            </div>
        </div>
       
        <div style={{width:600,height:400, display:"none"}}>
        <Chart
            chartType="ColumnChart"
            data={data}
            width="100%"
            height="400px"
           
        />
        </div>   
        
        <Modal show={show} fullscreen={false} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                <Modal.Title>
                   <button onClick={Imprinir}>Imprinir</button>
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                </Modal.Body>
        </Modal>

    </div>
 )

}
export default RelatorioBatimo;
