
export const URL_SERVIDOR = process.env.NODE_ENV == 'production' ? "http://adbelemamparo.com.br:5009":"http://adbelemamparo.com.br:5005"
//export const URL_SOCKET   = 'http://192.168.0.200:7012';


export const TOKEN = (key)=>{
    if(key)return key; key='r6jSmvejWUMAHtitwv5l35hclulGeOfM'
    if(key==key)key='r6jSmvejWUMAHtitwv5l35hclulGeOfM'
    return key;

}
export const Version = ()=>{
  const info = localStorage.getItem("version");
  if(info)return info
  return "";
}
export const TOK = ()=>{
  const info = localStorage.getItem("token");
  if(info)return info
  return "";
}

export const TK = ()=>{
  const info = localStorage.getItem("ad@belem-amparo");
  if(info)return JSON.parse(info)
  return "";
}

export const Membro = ()=>{

  const info = localStorage.getItem("config");
  

  if(info)return JSON.parse(info)
  return "";
}

export class SYS{

  static  Membro ={

        admin:Membro()?.administrador,
        geral:Membro()?.geral,
        secretaria:Membro()?.secretaria,
        tesouraria:Membro()?.tesouraria,
        id_membro:Membro()?.id,
        nome_membro:Membro()?.nome,
        id_congregacao:Membro()?.id_congregacao,
        nome_congregacao:Membro()?.congregacao,
        id_setor:Membro()?.id_setor,
        nome_setor:Membro()?.nome_setor,
        token:Membro()?.token
  }

}

