import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import NavBar from '../components/nav';
import Button from "react-bootstrap/Button";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useEffect, useState } from 'react';
import "./cadastro.css";
import  logo from '../img/logo.png';
import { Container, InputGroup, Row } from 'react-bootstrap';
 
import ConvertDate from '../util/converter_date';
import axios from 'axios';

import carteira_membro_frente from '../img/carteira/membro_frente.png';
import carteira_membro_verso from '../img/carteira/membro_verso.png';
import converter_date from '../util/converter_date';
import ReactInputMask from 'react-input-mask';
import { Get, Post, Put } from '../../Api';
import { URL_SERVIDOR } from '../../Api/env';
 

function CadastroMembro() {
   
    const [info,setinfo]=useState([]);
 
        const [text, setText] = useState('www.adbelemamparo.com.br');
        const [generatedQRCode, setGeneratedQRCode] = useState(null);
      
        const generateQRCode = () => {
          setGeneratedQRCode(text);
        };
      
 
    const [tabs, setTabs] = useState("pesquisar");
    const [lbl_ativo, setLbl_ativo] = useState("Membro Ativo");
    const [bloquear, setBloquear] = useState({
        novo: false,
        alterar: true,
        excluir: true,
        cancela: true,
        salvar: true,
        formulario: true,
        update: true
    });
    const [tipo_lista,setTipo_lista]=useState([]);
    const [funcao_lista,setFuncao_lista]=useState([]);
    const [tipo_desc,setTipo_desc]=useState("");
    const [funcao_desc,setFuncao_desc]=useState("");
    ///////////// variveis info do membro

   

    const [membro_lista_pesuisa, setMembro_lista_pesquisa] = useState([]);
    const [membro_pesquisa, setMembro_pesquisa] = useState([]);
    const [membro_lista, setMembro_lista] = useState([]);
    const [membro_lista_pesquisa, setMembro_lista_pesquia] = useState([]);
    const [membro_id, setMembro_id] = useState("");
    const [membro_tipo, setMembro_tipo] = useState(1);
    const [membro_ativo, setMembro_ativo] = useState(1);
    const [membro_setor_id_fk, setMembro_setor_id_fk] = useState(0);
    const [membro_congregacao_id_fk, setMembro_congregacao_id_fk] = useState(0);
    const [membro_cargo_id_fk, setMembro_cargo_id_fk] = useState(0);
    const [membro_nome, setMembro_nome] = useState(" ");
    const [membro_sexo, setMembro_sexo] = useState("");
    const [membro_pai, setMembro_pai] = useState("");
    const [membro_mae, setMembro_mae] = useState("");
    const [membro_natural, setMembro_natural] = useState("");
    const [membro_estado, setMembro_estado] = useState("");
    const [membro_nacionalidade, setMembro_nacionalidade] = useState("");
    const [membro_batizado, setMembro_batizado] = useState("");
    const [membro_civil, setMembro_civil] = useState("");
    const [membro_rg, setMembro_rg] = useState("");
    const [membro_cpf, setMembro_cpf] = useState("");
    const [membro_cel, setMembro_cel] = useState("");
    const [membro_email, setMembro_email] = useState("");
    const [membro_rua, setMembro_rua] = useState("");
    const [membro_bairro, setMembro_bairro] = useState("");
    const [membro_cidade, setMembro_cidade] = useState("");
    const [membro_uf, setMembro_uf] = useState("");
    const [membro_cep, setMembro_cep] = useState("");
    const [membro_teologia, setMembro_teologia] = useState("");
    const [membro_efo, setMembro_efo] = useState("");
    const [membro_carteirinha, setMembro_carteirinha] = useState("");
    const [membro_capelania, setMembro_capelania] = useState("");
    const [membro_acesso, setMembro_acesso] = useState("");
    const [membro_link, setMembro_link] = useState("");
    const [membro_dt_nascimento, setMembro_dt_nascimento] = useState("");
    const [membro_dt_batismo, setMembro_dt_batismo] = useState("");
    const [membro_dt_b_espirito_santo, setMembro_dt_b_espirito_santo] = useState("");
    const [membro_dt_cooperador, setMembro_dt_cooperador] = useState("");
    const [membro_dt_diacono, setMembro_dt_diacono] = useState("");
    const [membro_dt_missionaria, setMembro_dt_missionaria] = useState("");
    const [membro_dt_prebitero, setMembro_dt_prebitero] = useState("");
    const [membro_dt_evangelista, setMembro_dt_evangelista] = useState("");
    const [membro_dt_pastor, setMembro_dt_pastor] = useState("");
    const [membro_registrador, setMembro_registrador] = useState("");
    const [membro_alterador, setMembro_alterador] = useState("");
    const [membro_foto, setMembro_foto] = useState("");
    const [membro_igreja_bastismo, setMembro_igreja_bastismo] = useState("");
    const [membro_igreja_b_espirito_santo, setMembro_igreja_b_espirito_santo] = useState("");
    const [membro_igreja_cooperador, setMembro_igreja_cooperador] = useState("");
    const [membro_igreja_diacono, setMembro_igreja_diacono] = useState("");
    const [membro_igreja_missionario, setMembro_igreja_missionario] = useState("");
    const [membro_igreja_presbitero, setMembro_igreja_presbitero] = useState("");
    const [membro_igreja_evangelista, setMembro_igreja_evangelista] = useState("");
    const [membro_igreja_pastor, setMembro_igreja_pastor] = useState("");
    const [membro_conjuge_nome, setMembro_conjuge_nome] = useState("");
    const [membro_conjuge_dt_nasc, setMembro_conjuge_dt_nasc] = useState("");
    const [membro_filho, setMembro_filho] = useState("");
    const [membro_filho_idade, setMembro_filho_idade] = useState("");
    const [membro_escolaridade, setMembro_escolaridade] = useState("");
    const [membro_profissao, setMembro_profissao] = useState("");
    const [membro_teologia_desc, setMembro_teologia_desc] = useState("");
    const [membro_escola_teologia, setMembro_escola_teologia] = useState("");
    const [membro_funcao, setMembro_funcao] = useState("");
    const [membro_motivo, setMembro_motivo] = useState("");
    const [membro_obs, setMembro_obs] = useState("");
    const [membro_qrcode, setMembro_qrcode] = useState("");
    ///////////////////////////////////////////////////////////
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [foto,setFoto] = useState("");
    const [fotoPerfil,setFotoPerfil] = useState("");
    const handleFileChange = (e) => {
      setSelectedFile(e.target.files[0]);
      setFoto(e.target.files[0]);
    };
  
    const handleUpload = async (ID_MEMBRO) => {
      if (!selectedFile) {
       
        return;
      }
      var id = ID_MEMBRO;
  
      if(!id)
         id=membro_id;
        
  
      const formData = new FormData();  
      formData.append('file', selectedFile);
      formData.append('tabela', 'membro');

      try {
        const response = await axios.post(`${URL_SERVIDOR}/secretaria/upload/${info.fk_id_congregacao}/membro/${id}/img`, formData, {
  
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setUploadProgress(progress);
          },
        });
  
        console.log(response.data);
         
        setSelectedFile(null);
        setUploadProgress(0);
      } catch (error) {
        console.error('Erro ao fazer o upload do arquivo:', error.message);
        alert('Erro ao fazer o upload do arquivo. Por favor, tente novamente.');
        setUploadProgress(0);
      }
   
    };

    //// function buttons
    function Limpar() {
        setBloquear({
            novo: false,
            alterar: true,
            excluir: true,
            cancela: true,
            salvar: true,
            formulario: true,
            update: true
        });

        setLbl_ativo("Membro Ativo")
        setMembro_id(0);
        setMembro_tipo(1);
        setMembro_ativo(1);
        setMembro_setor_id_fk(0);
        setMembro_congregacao_id_fk(0);
        setMembro_cargo_id_fk(0);
        setMembro_nome("");
        setMembro_sexo("");
        setMembro_pai("");
        setMembro_mae("");
        setMembro_natural("");
        setMembro_estado("");
        setMembro_nacionalidade("");
        setMembro_batizado("");
        setMembro_civil("");
        setMembro_rg("");
        setMembro_cpf("");
        setMembro_cel("");
        setMembro_email("");
        setMembro_rua("");
        setMembro_bairro("");
        setMembro_cidade("");
        setMembro_uf("");
        setMembro_cep("");
        setMembro_teologia("");
        setMembro_efo("");
        setMembro_carteirinha("");
        setMembro_capelania("");
        setMembro_acesso("");
        setMembro_link("");
        setMembro_dt_nascimento("");
        setMembro_dt_batismo("");
        setMembro_dt_b_espirito_santo("");
        setMembro_dt_cooperador("");
        setMembro_dt_diacono("");
        setMembro_dt_missionaria("");
        setMembro_dt_prebitero("");
        setMembro_dt_evangelista("");
        setMembro_dt_pastor("");
        setMembro_registrador("");
        setMembro_alterador("");
        setMembro_foto("");
        setMembro_igreja_bastismo("");
        setMembro_igreja_b_espirito_santo("");
        setMembro_igreja_cooperador("");
        setMembro_igreja_diacono("");
        setMembro_igreja_missionario("");
        setMembro_igreja_presbitero("");
        setMembro_igreja_evangelista("");
        setMembro_igreja_pastor("");
        setMembro_conjuge_nome("");
        setMembro_conjuge_dt_nasc("");
        setMembro_filho("");
        setMembro_filho_idade("");
        setMembro_escolaridade("");
        setMembro_profissao("");
        setMembro_teologia_desc("");
        setMembro_escola_teologia("");
        setMembro_funcao("");
        setMembro_motivo("");
        setMembro_obs("");
        setMembro_qrcode("");
        setFuncao_desc("");
        setTipo_desc("");
        setFoto(null);

    }


    function Novo() {
        Limpar();
        setBloquear({
            novo: true,
            alterar: true,
            excluir: true,
            cancela: false,
            salvar: false,
            formulario: false,
            update: true
        });
    }
    function Alterar() {
        setBloquear({
            novo: true,
            alterar: true,
            excluir: true,
            cancela: false,
            salvar: false,
            formulario: false,
            update: false
        });
    }
    function Excluir() {
        const msg = alert("Excluir o membro qual é o motivo?");
        if (msg)
            Limpar();
    }
    function Cancelar() {
        Limpar();
    }
    function Salvar() {
   

   
        alert(membro_cpf.length);
    
   if(membro_nome=="")
        return alert("Digite o nome do membro");
     if(membro_cpf=="")
        return alert("Digite o CPF do membro");
       if(bloquear.update)
         InsertMembro();
        else
         UpdatetMembro();

        setBloquear({
            novo: false,
            alterar: false,
            excluir: true,
            cancela: true,
            salvar: true,
            formulario: true,
            update: true
        });
    }
    ///////////////////
    //// function tabs
    function handleSelect(key) {
        setTabs(key);
    }

    function IrCadastro(v) {
        setTabs("membro");

        setBloquear({
            novo: true,
            alterar: false,
            excluir: false,
            cancela: false,
            salvar: true,
            formulario: true,
            update: true
        });

        setMembro_id(v.membro_id);
        setMembro_tipo(v.membro_tipo);
        setMembro_ativo(v.membro_ativo);
        setMembro_setor_id_fk(v.membro_setor_id_fk);
        setMembro_congregacao_id_fk(v.membro_congregacao_id_fk);
        setMembro_cargo_id_fk(v.membro_cargo_id_fk);
        setMembro_nome(v.membro_nome);
        setMembro_sexo(v.membro_sexo);
        setMembro_pai(v.membro_pai);
        setMembro_mae(v.membro_mae);
        setMembro_natural(v.membro_natural);
        setMembro_estado(v.membro_estado);
        setMembro_nacionalidade(v.membro_nacionalidade);
        setMembro_batizado(v.membro_batizado);
        setMembro_civil(v.membro_civil);
        setMembro_rg(v.membro_rg);
        setMembro_cpf(v.membro_cpf);
        setMembro_cel(v.membro_cel);
        setMembro_email(v.membro_email);
        setMembro_rua(v.membro_rua);
        setMembro_bairro(v.membro_bairro);
        setMembro_cidade(v.membro_cidade);
        setMembro_uf(v.membro_uf);
        setMembro_cep(v.membro_cep);
        setMembro_teologia(v.membro_teologia);
        setMembro_efo(v.membro_efo);
        setMembro_carteirinha(v.membro_carteirinha);
        setMembro_capelania(v.membro_capelania);
        setMembro_acesso(v.membro_acesso);
        setMembro_link(v.membro_link);
        setMembro_dt_nascimento(ConvertDate.Date_Us(v.membro_dt_nascimento).date_us);
        setMembro_dt_batismo(ConvertDate.Date_Us(v.membro_dt_batismo).date_us);
        setMembro_dt_b_espirito_santo(ConvertDate.Date_Us(v.membro_dt_b_espirito_santo).date_us);
        setMembro_dt_cooperador(ConvertDate.Date_Us(v.membro_dt_cooperador).date_us);
        setMembro_dt_diacono(ConvertDate.Date_Us(v.membro_dt_diacono).date_us);
        setMembro_dt_missionaria(ConvertDate.Date_Us(v.membro_dt_missionaria).date_us);
        setMembro_dt_prebitero(ConvertDate.Date_Us(v.membro_dt_prebitero).date_us);
        setMembro_dt_evangelista(ConvertDate.Date_Us(v.membro_dt_evangelista).date_us);
        setMembro_dt_pastor(ConvertDate.Date_Us(v.membro_dt_pastor).date_us);
        setMembro_registrador(v.membro_registrador);
        setMembro_alterador(v.membro_alterador);
        setMembro_foto(v.membro_foto);
        setMembro_igreja_bastismo(v.membro_igreja_bastismo);
        setMembro_igreja_b_espirito_santo(v.membro_igreja_b_espirito_santo);
        setMembro_igreja_cooperador(v.membro_igreja_cooperador);
        setMembro_igreja_diacono(v.membro_igreja_diacono);
        setMembro_igreja_missionario(v.membro_igreja_missionario);
        setMembro_igreja_presbitero(v.membro_igreja_presbitero);
        setMembro_igreja_evangelista(v.membro_igreja_evangelista);
        setMembro_igreja_pastor(v.membro_igreja_pastor);
        setMembro_conjuge_nome(v.membro_conjuge_nome);
        setMembro_conjuge_dt_nasc(ConvertDate.Date_Us(v.membro_conjuge_dt_nasc).date_us);
        setMembro_filho(v.membro_filho);
        setMembro_filho_idade(v.membro_filho_idade);
        setMembro_escolaridade(v.membro_escolaridade);
        setMembro_profissao(v.membro_profissao);
        setMembro_teologia_desc(v.membro_teologia_desc);
        setMembro_escola_teologia(v.membro_escola_teologia);
        setMembro_funcao(v.membro_funcao);
        setMembro_motivo(v.membro_motivo);
        setMembro_obs(v.membro_obs);
        setMembro_qrcode(v.membro_qrcode);
        setGeneratedQRCode(v.membro_nome);
        setTipo_desc(v.tipo_desc);
        setFuncao_desc(v.funcao_desc);

    }   
    ///////////////
    ///// function dados api
    async function ListarTipo() {

        const { data } = await Get("/secretaria/cargo");
        if(data?.sucess)
        setTipo_lista(data.data);
        else
        setTipo_lista([]);
       
    }

    async function ListarFuncao() {

        const { data } = await Get("/secretaria/funcao");
        if(data?.sucess)
            setFuncao_lista(data.data);
        else
        setFuncao_lista([]);
    }

    async function ListarMembro() {

        const { data } = await Get(`/secretaria/membro?congregacao=${info.fk_id_congregacao}&ativo=${true}`);
        if(data?.sucess){
            setMembro_lista(data.data);
            setMembro_lista_pesquisa(data.data);
        }
       //console.log(data);
    }
 
    async function InsertMembro() {
        
        const { data } = await Post("/secretaria/membro",{
          
            membro_tipo,
            membro_ativo, 
            membro_setor_id_fk,
            membro_congregacao_id_fk,
            membro_cargo_id_fk,
            membro_nome,
            membro_sexo,
            membro_pai,
            membro_mae,
            membro_natural,
            membro_estado,
            membro_nacionalidade,
            membro_batizado,
            membro_civil,
            membro_rg,
            membro_cpf,
            membro_cel,
            membro_email,
            membro_rua,
            membro_bairro,
            membro_cidade,
            membro_uf,
            membro_cep,
            membro_teologia,
            membro_efo,
            membro_carteirinha,
            membro_capelania,
            membro_acesso,
            membro_link,
            membro_dt_nascimento,
            membro_dt_batismo,
            membro_dt_b_espirito_santo,
            membro_dt_cooperador,
            membro_dt_diacono,
            membro_dt_missionaria,
            membro_dt_prebitero,
            membro_dt_evangelista,
            membro_dt_pastor,
            membro_registrador,
            membro_alterador,
            membro_foto,
            membro_igreja_bastismo,
            membro_igreja_b_espirito_santo,
            membro_igreja_cooperador,
            membro_igreja_diacono,
            membro_igreja_missionario,
            membro_igreja_presbitero,
            membro_igreja_evangelista,
            membro_igreja_pastor,
            membro_conjuge_nome,
            membro_conjuge_dt_nasc,
            membro_filho,
	        membro_filho_idade,
	        membro_escolaridade,
	        membro_profissao,
	        membro_teologia_desc,
	        membro_escola_teologia,
	        membro_funcao,
	        membro_motivo,
	        membro_obs,
	        membro_qrcode,
        });

        
            console.log(data)
           if(data?.sucesso){ 
            if(selectedFile)
             handleUpload(data.id);
            ListarMembro();
             alert(data.msg);
            
           }
            else
             alert("Ocorreu um erro tente novamente");
       

    }

    async function UpdatetMembro() {
      
        const { data } = await Put("/secretaria/membro",{
            info,
            membro_id: membro_id,
            membro_tipo: membro_tipo,
            membro_ativo: membro_ativo,
            membro_setor_id_fk: membro_setor_id_fk,
            membro_congregacao_id_fk: membro_congregacao_id_fk,
            membro_cargo_id_fk: membro_cargo_id_fk,
            membro_nome: membro_nome,
            membro_sexo: membro_sexo,
            membro_pai: membro_pai,
            membro_mae: membro_mae,
            membro_natural: membro_natural,
            membro_estado: membro_estado,
            membro_nacionalidade: membro_nacionalidade,
            membro_batizado: membro_batizado,
            membro_civil: membro_civil,
            membro_rg: membro_rg,
            membro_cpf: membro_cpf,
            membro_cel: membro_cel,
            membro_email: membro_email,
            membro_rua: membro_rua,
            membro_bairro: membro_bairro,
            membro_cidade: membro_cidade,
            membro_uf: membro_uf,
            membro_cep: membro_cep,
            membro_teologia: membro_teologia,
            membro_efo: membro_efo,
            membro_carteirinha: membro_carteirinha,
            membro_capelania: membro_capelania,
            membro_acesso: membro_acesso,
            membro_link: membro_link,
            membro_dt_nascimento: membro_dt_nascimento ,
            membro_dt_batismo: membro_dt_batismo,
            membro_dt_b_espirito_santo: membro_dt_b_espirito_santo ,
            membro_dt_cooperador: membro_dt_cooperador,
            membro_dt_diacono: membro_dt_diacono,
            membro_dt_missionaria: membro_dt_missionaria,
            membro_dt_prebitero: membro_dt_prebitero,
            membro_dt_evangelista: membro_dt_evangelista,
            membro_dt_pastor: membro_dt_pastor,
            membro_registrador: membro_registrador,
            membro_alterador: membro_alterador,
            membro_foto: membro_foto,
            membro_igreja_bastismo: membro_igreja_bastismo,
            membro_igreja_b_espirito_santo: membro_igreja_b_espirito_santo,
            membro_igreja_cooperador: membro_igreja_cooperador,
            membro_igreja_diacono: membro_igreja_diacono,
            membro_igreja_missionario: membro_igreja_missionario,
            membro_igreja_presbitero: membro_igreja_presbitero,
            membro_igreja_evangelista: membro_igreja_evangelista,
            membro_igreja_pastor: membro_igreja_pastor,
            membro_conjuge_nome:membro_conjuge_nome,
	        membro_conjuge_dt_nasc:membro_conjuge_dt_nasc ,
            membro_filho:membro_filho,
	        membro_filho_idade:membro_filho_idade,
	        membro_escolaridade:membro_escolaridade,
	        membro_profissao:membro_profissao,
	        membro_teologia_desc:membro_teologia_desc,
	        membro_escola_teologia:membro_escola_teologia,
	        membro_funcao:membro_funcao,
	        membro_motivo:membro_motivo,
	        membro_obs:membro_obs,
	        membro_qrcode:membro_qrcode,
        });
        if(data){
            console.log(data)
           if(data?.sucesso){ 
            if(selectedFile)
                handleUpload();
             alert(data.msg);

           }
            else
             alert("Ocorreu um erro tente novamente");
        }
        ListarMembro();

    }

    //////////////////////////////////
    function AtivarMembro(e) {

        if (membro_ativo == 1) {
            const msg = window.prompt("Desativar membro qual é o motivo?");
            if (msg) {
                setMembro_ativo(0);
                setLbl_ativo("Membro Desativado")
            }
        }
        else {
            const msg = window.prompt("Ativar membro qual é o motivo?");
            if (msg) {
                setMembro_ativo(1);
                setLbl_ativo("Membro Ativado")
            }
        }
    }

function PesquisarMembro(ev){
    
    var  lista= membro_lista.filter((membro)=> membro.membro_nome.toLowerCase().includes(ev.toLowerCase()) || String( membro.membro_id).toLowerCase().includes(ev.toLowerCase()));

      
       
    if(ev.length>0)
    setMembro_lista_pesquisa(lista);
else
    setMembro_lista_pesquisa(membro_lista);
      

}


const Carregar = () => {

    const data = localStorage.getItem("config");
    if(data)
    setinfo(JSON.parse( data));


}
    useEffect(() => {
        Carregar();
        ListarMembro();
        ListarTipo();
        ListarFuncao();
    
    }, []);

    return (
        <div className='div-p-cadastro'>
            <NavBar navagando={"CASDASTRO DE MEMBRO"} />

            <Tabs
                onSelect={handleSelect}
                id="tab-principal"
                className="mb-3  tab-membro"
                activeKey={tabs}
            

            >
                <Tab eventKey="pesquisar" title="Pesquisar" >
                  
                    <div className='div-membro-listar'>
                        <div>
                            <label className='lbl-info-membro'>{info.setor_nome} / {info.congregacao_nome}</label>
                        </div>
                        <label style={{fontSize:22,fontWeight:'bold'}}>Pesquisar Membro
                            <Form.Control  onChange={(ev) => PesquisarMembro(ev.target.value)} type="text" placeholder="Digite o nome ou numero" style={{ width:300, marginBottom: 10 }} />

                        </label>
                        <Table striped bordered hover>
                              <thead>
                                <tr >
                                    <td style={{ width: 50 }}>Nº</td>
                                    <td style={{ width: 50 }}>Foto</td>
                                    <td>Nome</td>
                                </tr>
                                </thead>
                            <tbody>
                                {
                                    membro_lista_pesuisa.map((value, key) =>
                                    (
                                        <tr key={key} style={{ width: 150 }} onClick={() => IrCadastro(value)}>
                                            <td>{value.membro_id}</td>
                                            <td>{"foto"}</td>
                                            {/* <td><img className='foto-membro-listar' src={+"uploads/"+value.membro_foto} /></td> */}
                                            <td>{value.membro_nome}</td>

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </Tab>

                <Tab eventKey="membro" title="Cadastro"  >
                    <BotaoCadastro />
                    <div className='div-cadastro'>
                           
                                <fieldset className='fieldset'>
                                    <legend className='legend-cadastro'>Dados Cadastrais</legend>
                                    <div style={{ width: 400, display: 'flex', marginBottom: 20 }}>
                                        <div style={{ marginRight: 15, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >

                                           
                                            {/* {foto ? (
                                                    <img className='foto-membro-cad' src={URL.createObjectURL(foto)} />  
                                                    ) : (
                                                    <img className='foto-membro-cad' src={membro_foto ?api.url+"uploads/"+membro_foto : logo}/>
                                                    )
                                            } */}
                                            <div style={{height:60,width:100}}>
                                            {!bloquear.formulario?
                                            <label className='lbl-file' >Escolher Foto
                                            <input type='file' onChange={handleFileChange}/>
                                            </label>:""
                                            }
                                            </div>
                                          
                                        </div>
                                        <div>
                                            <Form.Check
                                                type="switch"
                                                label={lbl_ativo}
                                                onChange={(e) => AtivarMembro(e)}
                                                checked={membro_ativo}
                                                disabled={bloquear.formulario}
                                            />
                                            <hr></hr>
                                            <label className='lbl-cadastro'>TIPO
                                                <select  style={{ width: 120 }} className='input-cadastro' value={membro_tipo} onChange={(e)=>setMembro_tipo(e.target.value)}  disabled={bloquear.formulario}>
                                                    
                                                    {
                                                     tipo_lista.map((val)=>(
                                                        <option value={val.id_cargo}>{val.nome_cargo}</option>
                                                     ))
                                                    }
                                                   
                                                  

                                                </select>
                                            </label>{" "}
                                            <label className='lbl-cadastro'>FUNÇÃO
                                                <select style={{ width: 120 }} className='input-cadastro' value={membro_cargo_id_fk} onChange={(e)=>setMembro_cargo_id_fk(e.target.value)}  disabled={bloquear.formulario}>
                                                    <option value="0" selected>NÃO</option>
                                            
                                                    {
                                                     funcao_lista.map((val)=>(
                                                        <option value={val.funcao_id}>{val.funcao_desc}</option>
                                                     ))
                                                    }

                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div>
                                        <label style={{ width: "100%" }} className='lbl-cadastro' >NOME
                                            <Form.Control onChange={(e) => setMembro_nome(e.target.value)} value={membro_nome} className='input-cadastro input-nome' type='text'
                                             disabled={bloquear.formulario}
                                            />
                                        </label>{" "}
                                    </div>
                                    <div>
                                        <label className='lbl-cadastro'>SEXO
                                            <select style={{ width: 150 }} className='input-cadastro'  value={membro_sexo} onChange={(e)=>setMembro_sexo(e.target.value)}  disabled={bloquear.formulario}>
                                                <option value="M" selected>MASCULINO</option>
                                                <option value="F">FEMENINO</option>
                                            </select>
                                        </label>{" "}
                                        <label className='lbl-cadastro'>ESTADO CIVIL
                                            <select style={{ width: 150 }} className='input-cadastro'  value={membro_civil} onChange={(e)=>setMembro_civil(e.target.value)}  disabled={bloquear.formulario}>
                                                <option value="SOLTEIRO" selected>SOLTERIO(A)</option>
                                                <option value="CASADO">CASADO(A)</option>
                                                <option value="VIUVO">VIUVO(A)</option>
                                                <option value="SEPARADO">SEPARADO(A)</option>
                                            </select>
                                        </label>{" "}

                                    </div>
                                    <div>
                                        <label className='lbl-cadastro'>CPF
                                            <ReactInputMask mask="999.999.999-99"
                                             value={membro_cpf} onChange={(e)=>setMembro_cpf(e.target.value)} 
                                            style={{ width: 120 }} className='input-cadastro' type='text'
                                            disabled={bloquear.formulario}
                                            />
                                        </label> {" "}
                                        <label className='lbl-cadastro'>RG
                                            <Form.Control
                                             value={membro_rg} onChange={(e)=>setMembro_rg(e.target.value)} 
                                            style={{ width: 100 }} className='input-cadastro' type='text'
                                            disabled={bloquear.formulario}
                                            />
                                        </label>{" "}
                                        <label className='lbl-cadastro'>ORG. EXP
                                            <Form.Control
                                            //  value={membro_or} onChange={(e)=>setMembro_(e.target.value)} 
                                             style={{ width: 80 }} className='input-cadastro' type='text'
                                             disabled={bloquear.formulario}
                                             />
                                        </label>
                                    </div>
                                    <div>
                                        <label className='lbl-cadastro'>DT. NASC.
                                            <Form.Control
                                             value={membro_dt_nascimento} onChange={(e)=>setMembro_dt_nascimento(e.target.value)} 
                                            style={{ minWidth: 100 }} className='input-cadastro' type='date'
                                            disabled={bloquear.formulario}
                                            />
                                        </label>{" "}
                                        <label className='lbl-cadastro'>DT. BATISMO
                                            <Form.Control
                                             value={membro_dt_batismo} onChange={(e)=>setMembro_dt_batismo(e.target.value)} 
                                            style={{ minWidth: 100 }} className='input-cadastro' type='date' 
                                            disabled={bloquear.formulario}
                                            />
                                        </label>
                                    </div>

                                    <div>
                                        <label className='lbl-cadastro'>MÃE
                                            <Form.Control
                                             value={membro_mae} onChange={(e)=>setMembro_mae(e.target.value)} 
                                             style={{ width: 300 }} className='input-cadastro' type='text'
                                             disabled={bloquear.formulario}
                                             />
                                        </label> {" "}
                                        <label className='lbl-cadastro'>PAI
                                            <Form.Control
                                             value={membro_pai} onChange={(e)=>setMembro_pai(e.target.value)} 
                                            style={{ width: 300 }} className='input-cadastro' type='text'
                                            disabled={bloquear.formulario}
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <label className='lbl-cadastro'>CIDADE NASC.
                                            <Form.Control
                                            //  value={membro_} onChange={(e)=>setMembro_(e.target.value)} 
                                            style={{ width: 300 }} className='input-cadastro' type='text'
                                            disabled={bloquear.formulario}
                                            />
                                        </label> {" "}
                                        <label className='lbl-cadastro'>NACIONALIDADE
                                            <Form.Control
                                             value={membro_nacionalidade} onChange={(e)=>setMembro_nacionalidade(e.target.value)} 
                                             style={{ width: 300 }} className='input-cadastro' type='text'
                                             disabled={bloquear.formulario}
                                             />
                                        </label>
                                    </div>
                                </fieldset>
                      
                    </div>
                    <hr></hr>
                    <div className='div-cadastro'>
                            <fieldset className='fieldset'>
                                <legend className='legend-cadastro'>Endereço</legend>
                                <div>
                                    <label className='lbl-cadastro'>RUA
                                        <Form.Control
                                         value={membro_rua} onChange={(e)=>setMembro_rua(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}
                                         />
                                    </label> {" "}
                                    <label className='lbl-cadastro'>BAIRRO
                                        <Form.Control
                                         value={membro_bairro} onChange={(e)=>setMembro_bairro(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text'
                                         disabled={bloquear.formulario}
                                         />
                                    </label>{" "}

                                </div>
                                <div>
                                    <label className='lbl-cadastro'>CIDADE
                                        <Form.Control
                                         value={membro_cidade} onChange={(e)=>setMembro_cidade(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text'
                                         disabled={bloquear.formulario}
                                         />
                                    </label> {" "}
                                    <label className='lbl-cadastro'>ESTADO
                                        <Form.Control
                                         value={membro_estado} onChange={(e)=>setMembro_estado(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='text'
                                         disabled={bloquear.formulario}
                                         />
                                    </label>{" "}
                                    <label className='lbl-cadastro'>CEP
                                        <Form.Control
                                         value={membro_cep} onChange={(e)=>setMembro_cep(e.target.value)} 
                                         style={{ width: 100 }} className='input-cadastro' type='text'
                                         disabled={bloquear.formulario}
                                         />
                                    </label>

                                </div>
                                <div>
                                    <label className='lbl-cadastro'>EMAIL
                                        <Form.Control
                                        
                                        value={membro_email} onChange={(e)=>setMembro_email(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text'
                                         disabled={bloquear.formulario}
                                         />
                                    </label> {" "}
                                    <label className='lbl-cadastro'>CELULAR
                                        <Form.Control
                                         value={membro_cel} onChange={(e)=>setMembro_cel(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='text'
                                         disabled={bloquear.formulario}
                                         />
                                    </label>
                                </div>
                            </fieldset>
                    </div>
                    <hr></hr>
                    <div className='div-cadastro'>

                <fieldset className='fieldset'>

                    <legend className='legend-cadastro'>Dados Eclesiastico</legend>
                    <div>
                        <label className='lbl-cadastro'>DT. BATISMO
                            <Form.Control
                            value={membro_dt_batismo} onChange={(e)=>setMembro_dt_batismo(e.target.value)} 
                            style={{ width: 150 }} className='input-cadastro' type='date' 
                            disabled={bloquear.formulario}
                            />
                        </label>{" "}
                        <label className='lbl-cadastro'>IGREJA
                            <Form.Control
                            value={membro_igreja_bastismo} onChange={(e)=>setMembro_igreja_bastismo(e.target.value)} 
                            style={{ width: 300 }} className='input-cadastro' type='text'
                            disabled={bloquear.formulario}
                            />
                        </label>
                    </div>
                    <hr></hr>
                    <div>
                        <label className='lbl-cadastro'>B. ESPIRITO SANTO
                            <Form.Control
                            value={membro_dt_b_espirito_santo} onChange={(e)=>setMembro_dt_b_espirito_santo(e.target.value)} 
                            style={{ width: 150 }} className='input-cadastro' type='date'
                            disabled={bloquear.formulario}
                            />
                        </label>{" "}
                        <label className='lbl-cadastro'>IGREJA
                            <Form.Control
                            value={membro_igreja_b_espirito_santo} onChange={(e)=>setMembro_igreja_b_espirito_santo(e.target.value)} 
                            style={{ width: 300 }} className='input-cadastro' type='text'
                            disabled={bloquear.formulario}
                            />
                        </label>
                    </div>
                    <hr></hr>
                    <div>
                        <label className='lbl-cadastro'>COOPERADOR
                            <Form.Control
                            value={membro_dt_cooperador} onChange={(e)=>setMembro_dt_cooperador(e.target.value)} 
                            style={{ width: 150 }} className='input-cadastro' type='date'
                            disabled={bloquear.formulario}
                            />
                        </label>{" "}
                        <label className='lbl-cadastro'>IGREJA
                            <Form.Control
                            value={membro_igreja_cooperador} onChange={(e)=>setMembro_igreja_cooperador(e.target.value)} 
                            style={{ width: 300 }} className='input-cadastro'  type='text' 
                            disabled={bloquear.formulario} />
                        </label>
                    </div>
                    <hr></hr>
                    <div>
                        <label className='lbl-cadastro'>DIACONO
                            <Form.Control
                            value={membro_dt_diacono} onChange={(e)=>setMembro_dt_diacono(e.target.value)} 
                            style={{ width: 150 }} className='input-cadastro' type='date'
                            disabled={bloquear.formulario}
                            />
                        </label>{" "}
                        <label className='lbl-cadastro'>IGREJA
                            <Form.Control
                            value={membro_igreja_diacono} onChange={(e)=>setMembro_igreja_diacono(e.target.value)} 
                            style={{ width: 300 }} className='input-cadastro'  type='text' 
                            disabled={bloquear.formulario} />
                        </label>
                    </div>
                    <hr></hr>
                    <div>
                        <label className='lbl-cadastro'>MISSONÁRIO(a)
                            <Form.Control
                            value={membro_dt_missionaria} onChange={(e)=>setMembro_dt_missionaria(e.target.value)} 
                            style={{ width: 150 }} className='input-cadastro' type='date'
                            disabled={bloquear.formulario}
                            />
                        </label>{" "}
                        <label className='lbl-cadastro'>IGREJA
                            <Form.Control
                            value={membro_igreja_missionario} onChange={(e)=>setMembro_igreja_missionario(e.target.value)} 
                            style={{ width: 300 }} className='input-cadastro'  type='text' 
                            disabled={bloquear.formulario} />
                        </label>
                    </div>
                    <hr></hr>
                    <div>
                        <label className='lbl-cadastro'>PRESBÍTERO
                            <Form.Control
                            value={membro_dt_prebitero} onChange={(e)=>setMembro_dt_prebitero(e.target.value)} 
                            style={{ width: 150 }} className='input-cadastro' type='date'
                            disabled={bloquear.formulario}
                            />
                        </label>{" "}
                        <label className='lbl-cadastro'>IGREJA
                            <Form.Control
                            value={membro_igreja_presbitero} onChange={(e)=>setMembro_igreja_presbitero(e.target.value)} 
                            style={{ width: 300 }} className='input-cadastro'  type='text' 
                            disabled={bloquear.formulario} />
                        </label>
                    </div>
                    <hr></hr>
                    <div>
                        <label className='lbl-cadastro'>EVANGELISTA
                            <Form.Control
                            value={membro_dt_evangelista} onChange={(e)=>setMembro_dt_evangelista(e.target.value)} 
                            style={{ width: 150 }} className='input-cadastro' type='date'
                            disabled={bloquear.formulario}
                            />
                        </label>{" "}
                        <label className='lbl-cadastro'>IGREJA
                            <Form.Control
                            value={membro_igreja_evangelista} onChange={(e)=>setMembro_igreja_evangelista(e.target.value)} 
                            style={{ width: 300 }} className='input-cadastro'  type='text'
                            disabled={bloquear.formulario} />
                        </label>
                    </div>
                    <hr></hr>
                    <div>
                        <label className='lbl-cadastro'>PASTOR
                            <Form.Control
                            value={membro_dt_pastor} onChange={(e)=>setMembro_dt_pastor(e.target.value)} 
                            style={{ width: 150 }} className='input-cadastro' type='date' 
                            disabled={bloquear.formulario}
                            />
                        </label>{" "}
                        <label className='lbl-cadastro'>IGREJA

                            <Form.Control
                            value={membro_igreja_pastor} onChange={(e)=>setMembro_igreja_pastor(e.target.value)} 
                            style={{ width: 300 }} className='input-cadastro'  type='text'
                            disabled={bloquear.formulario} />
                        </label>
                    </div>
                    <hr style={{ minWidth: 600 }}></hr>
                </fieldset>

                      
                    </div>
                    <hr></hr>
                    <div className='div-cadastro'>
                            <fieldset className='fieldset'>
                                <legend className='legend-cadastro'>Formação</legend>
                                <div>
                                    <label className='lbl-cadastro'>ESCOLARIDADE
                                        <Form.Control
                                        value={membro_escolaridade} onChange={(e)=>setMembro_escolaridade(e.target.value)} 
                                        style={{ width: 300 }} className='input-cadastro'  type='text' 
                                        disabled={bloquear.formulario} />
                                    </label> {" "}
                                    <label className='lbl-cadastro'>PORFISSÃO
                                        <Form.Control 
                                        value={membro_profissao} onChange={(e)=>setMembro_profissao(e.target.value)} 
                                        style={{ width: 300 }} className='input-cadastro'  type='text' 
                                        disabled={bloquear.formulario} />
                                    </label>{" "}
                                </div>
                                <div>
                                    <label className='lbl-cadastro'>TEOLOGIA
                                        <Form.Control 
                                        value={membro_teologia_desc} onChange={(e)=>setMembro_teologia_desc(e.target.value)} 
                                        style={{ width: 300 }} className='input-cadastro'  type='text' 
                                        disabled={bloquear.formulario} />
                                    </label> {" "}
                                    <label className='lbl-cadastro'>ESCOLA TEOLOGIA
                                        <Form.Control 
                                        value={membro_escola_teologia} onChange={(e)=>setMembro_escola_teologia(e.target.value)} 
                                        style={{ width: 300 }} className='input-cadastro'  type='text' 
                                        disabled={bloquear.formulario} />
                                    </label>{" "}
                                </div>
                            </fieldset>
                    </div>
                    <hr></hr>
                    <div className='div-cadastro'>
                            <fieldset className='fieldset'>
                                <legend className='legend-cadastro'>Dados Familiar</legend>
                                <div>
                                    <label className='lbl-cadastro'>CONJUGE
                                        <Form.Control
                                        value={membro_conjuge_nome} onChange={(e)=>setMembro_conjuge_nome(e.target.value)} 
                                         style={{ width: 350 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label> {" "}
                                    <label className='lbl-cadastro'>DT. NASC.
                                        <Form.Control 
                                        value={membro_conjuge_dt_nasc} onChange={(e)=>setMembro_conjuge_dt_nasc(e.target.value)} 
                                        style={{ width: 150 }} className='input-cadastro' type='date' 
                                        disabled={bloquear.formulario}/>
                                    </label>{" "}
                                </div>
                                <div>
                                    <label className='lbl-cadastro'>FILHOS
                                        <Form.Control 
                                        value={membro_filho} onChange={(e)=>setMembro_filho(e.target.value)} 
                                        style={{ width: 100 }} className='input-cadastro' type='text' 
                                        disabled={bloquear.formulario}/>
                                    </label> {" "}
                                    <label className='lbl-cadastro'>IDADE
                                        <Form.Control 
                                        value={membro_filho_idade} onChange={(e)=>setMembro_filho_idade(e.target.value)} 
                                        style={{ width: 300 }} className='input-cadastro' type='text' 
                                        disabled={bloquear.formulario}/>
                                    </label>{" "}
                                </div>
                            </fieldset>
                    </div>
                    <hr></hr>
                </Tab>
                <Tab eventKey="dados" title="D. Membro"  >
                    <div>
                        <BotaoCadastro />
                        <div className='div-cadastro'>
                            
                                <fieldset className='fieldset'>
                                    <legend className='legend-cadastro'>Dados Cadastrais</legend>
                                    <div style={{ width: 400, display: 'flex', marginBottom: 20 }}>
                                        <div style={{ marginRight: 15, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >

                                           
                                            {/* {foto ? (
                                                    <img className='foto-membro-cad' src={URL.createObjectURL(foto)} />  
                                                    ) : (
                                                    <img className='foto-membro-cad' src={membro_foto ?api.url+"uploads/"+membro_foto : logo} />
                                                    )
                                            } */}
                                            <div style={{height:60,width:100}}>
                                            {!bloquear.formulario?
                                            <label className='lbl-file' >Escolher Foto
                                            <input type='file' onChange={handleFileChange}/>
                                            </label>:""
                                            }
                                            </div>
                                          
                                        </div>
                                        <div>
                                            <Form.Check
                                                type="switch"
                                                label={lbl_ativo}
                                                onChange={(e) => AtivarMembro(e)}
                                                checked={membro_ativo}
                                                disabled={bloquear.formulario}
                                            />
                                            <hr></hr>
                                            <label className='lbl-cadastro'>TIPO
                                                <select style={{ width: 120 }} className='input-cadastro' value={membro_tipo} onChange={(e)=>setMembro_tipo(e.target.value)} disabled={bloquear.formulario}>
                                                    
                                                    {
                                                     tipo_lista.map((val)=>(
                                                        <option value={val.tipo_id}>{val.tipo_desc}</option>
                                                     ))
                                                    }
                                                   
                                                  

                                                </select>
                                            </label>{" "}
                                            <label className='lbl-cadastro'>FUNÇÃO
                                                <select style={{ width: 120 }} className='input-cadastro' value={membro_cargo_id_fk} onChange={(e)=>setMembro_cargo_id_fk(e.target.value)} disabled={bloquear.formulario}>
                                                    <option value="0" selected>NÃO</option>
                                            
                                                    {
                                                     funcao_lista.map((val)=>(
                                                        <option value={val.funcao_id}>{val.funcao_desc}</option>
                                                     ))
                                                    }

                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div>
                                        <label style={{ width: "100%" }} className='lbl-cadastro' >NOME
                                            <Form.Control onChange={(e) => setMembro_nome(e.target.value)} value={membro_nome} className='input-cadastro input-nome' type='text' 
                                            disabled={bloquear.formulario} />
                                        </label>{" "}
                                    </div>
                                    <div>
                                        <label className='lbl-cadastro'>SEXO
                                            <select style={{ width: 150 }} className='input-cadastro'  value={membro_sexo} onChange={(e)=>setMembro_sexo(e.target.value)} disabled={bloquear.formulario}>
                                                <option value="M" selected>MASCULINO</option>
                                                <option value="F">FEMENINO</option>
                                            </select>
                                        </label>{" "}
                                        <label className='lbl-cadastro'>ESTADO CIVIL
                                            <select style={{ width: 150 }} className='input-cadastro'  value={membro_civil} onChange={(e)=>setMembro_civil(e.target.value)} disabled={bloquear.formulario}>
                                                <option value="SOLTEIRO" selected>SOLTERIO(A)</option>
                                                <option value="CASADO">CASADO(A)</option>
                                                <option value="VIUVO">VIUVO(A)</option>
                                                <option value="SEPARADO">SEPARADO(A)</option>
                                            </select>
                                        </label>{" "}

                                    </div>
                                    <div>
                                        <label className='lbl-cadastro'>CPF
                                             <ReactInputMask mask="999.999.999-99"
                                             value={membro_cpf} onChange={(e)=>setMembro_cpf(e.target.value)} 
                                            style={{ width: 100 }} className='input-cadastro'  type='text' 
                                            disabled={bloquear.formulario} />
                                        </label> {" "}
                                        <label className='lbl-cadastro'>RG
                                            <Form.Control
                                             value={membro_rg} onChange={(e)=>setMembro_rg(e.target.value)} 
                                            style={{ width: 100 }} className='input-cadastro'  type='text' 
                                            disabled={bloquear.formulario} />
                                        </label>{" "}
                                        <label className='lbl-cadastro'>ORG. EXP
                                            <Form.Control
                                            //  value={membro_or} onChange={(e)=>setMembro_(e.target.value)} 
                                             style={{ width: 80 }} className='input-cadastro'  type='text' 
                                             disabled={bloquear.formulario} />
                                        </label>
                                    </div>
                                    <div>
                                        <label className='lbl-cadastro'>DT. NASC.
                                            <Form.Control
                                             value={membro_dt_nascimento} onChange={(e)=>setMembro_dt_nascimento(e.target.value)} 
                                            style={{ minWidth: 100 }} className='input-cadastro' type='date' 
                                            disabled={bloquear.formulario}/>
                                            
                                        </label>{" "}
                                        <label className='lbl-cadastro'>DT. BATISMO
                                            <Form.Control
                                             value={membro_dt_batismo} onChange={(e)=>setMembro_dt_batismo(e.target.value)} 
                                            style={{ minWidth: 100 }} className='input-cadastro' type='date'
                                            disabled={bloquear.formulario}
                                            />
                                        </label>
                                    </div>

                                    <div>
                                        <label className='lbl-cadastro'>MÃE
                                            <Form.Control
                                             value={membro_mae} onChange={(e)=>setMembro_mae(e.target.value)} 
                                             style={{ width: 300 }} className='input-cadastro' type='text' 
                                             disabled={bloquear.formulario}/>
                                        </label> {" "}
                                        <label className='lbl-cadastro'>PAI
                                            <Form.Control
                                             value={membro_pai} onChange={(e)=>setMembro_pai(e.target.value)} 
                                            style={{ width: 300 }} className='input-cadastro' type='text' 
                                            disabled={bloquear.formulario}/>
                                        </label>
                                    </div>
                                    <div>
                                        <label className='lbl-cadastro'>CIDADE NASC.
                                            <Form.Control
                                            //  value={membro_} onChange={(e)=>setMembro_(e.target.value)} 
                                            style={{ width: 300 }} className='input-cadastro' type='text' 
                                            disabled={bloquear.formulario} />
                                        </label> {" "}
                                        <label className='lbl-cadastro'>NACIONALIDADE
                                            <Form.Control
                                             value={membro_nacionalidade} onChange={(e)=>setMembro_nacionalidade(e.target.value)} 
                                             style={{ width: 300 }} className='input-cadastro' type='text' 
                                             disabled={bloquear.formulario} />
                                        </label>
                                    </div>
                                </fieldset>
                          
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="endereco" title="Endereço"  >
                    <div>
                        <BotaoCadastro />
                        <div className='div-cadastro'>
                            <fieldset className='fieldset'>
                                <legend className='legend-cadastro'>Endereço</legend>
                                <div>
                                    <label className='lbl-cadastro'>RUA
                                        <Form.Control
                                         value={membro_rua} onChange={(e)=>setMembro_rua(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label> {" "}
                                    <label className='lbl-cadastro'>BAIRRO
                                        <Form.Control
                                         value={membro_bairro} onChange={(e)=>setMembro_bairro(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>{" "}

                                </div>
                                <div>
                                    <label className='lbl-cadastro'>CIDADE
                                        <Form.Control
                                         value={membro_cidade} onChange={(e)=>setMembro_cidade(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label> {" "}
                                    <label className='lbl-cadastro'>ESTADO
                                        <Form.Control
                                         value={membro_estado} onChange={(e)=>setMembro_estado(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>{" "}
                                    <label className='lbl-cadastro'>CEP
                                        <Form.Control
                                         value={membro_cep} onChange={(e)=>setMembro_cep(e.target.value)} 
                                         style={{ width: 100 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>

                                </div>
                                <div>
                                    <label className='lbl-cadastro'>EMAIL
                                        <Form.Control
                                        
                                        value={membro_email} onChange={(e)=>setMembro_email(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label> {" "}
                                    <label className='lbl-cadastro'>CELULAR
                                        <Form.Control
                                         value={membro_cel} onChange={(e)=>setMembro_cel(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="eclesiastico" title="D. Eclesiastico" >
                    <div>
                        <BotaoCadastro />
                        <div className='div-cadastro'>

                            <fieldset className='fieldset'>

                                <legend className='legend-cadastro'>Dados Eclesiastico</legend>
                                <div>
                                    <label className='lbl-cadastro'>DT. BATISMO
                                        <Form.Control
                                         value={membro_dt_batismo} onChange={(e)=>setMembro_dt_batismo(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='date' 
                                         disabled={bloquear.formulario}/>
                                    </label>{" "}
                                    <label className='lbl-cadastro'>IGREJA
                                        <Form.Control
                                        value={membro_igreja_bastismo} onChange={(e)=>setMembro_igreja_bastismo(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>
                                </div>
                                <hr></hr>
                                <div>
                                    <label className='lbl-cadastro'>B. ESPIRITO SANTO
                                        <Form.Control
                                         value={membro_dt_b_espirito_santo} onChange={(e)=>setMembro_dt_b_espirito_santo(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='date' 
                                         disabled={bloquear.formulario}/>
                                    </label>{" "}
                                    <label className='lbl-cadastro'>IGREJA
                                        <Form.Control
                                         value={membro_igreja_b_espirito_santo} onChange={(e)=>setMembro_igreja_b_espirito_santo(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>
                                </div>
                                <hr></hr>
                                <div>
                                    <label className='lbl-cadastro'>COOPERADOR
                                        <Form.Control
                                         value={membro_dt_cooperador} onChange={(e)=>setMembro_dt_cooperador(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='date' 
                                         disabled={bloquear.formulario}/>
                                    </label>{" "}
                                    <label className='lbl-cadastro'>IGREJA
                                        <Form.Control
                                         value={membro_igreja_cooperador} onChange={(e)=>setMembro_igreja_cooperador(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>
                                </div>
                                <hr></hr>
                                <div>
                                    <label className='lbl-cadastro'>DIACONO
                                        <Form.Control
                                         value={membro_dt_diacono} onChange={(e)=>setMembro_dt_diacono(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='date' 
                                         disabled={bloquear.formulario}/>
                                    </label>{" "}
                                    <label className='lbl-cadastro'>IGREJA
                                        <Form.Control
                                         value={membro_igreja_diacono} onChange={(e)=>setMembro_igreja_diacono(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>
                                </div>
                                <hr></hr>
                                <div>
                                    <label className='lbl-cadastro'>MISSONÁRIO(a)
                                        <Form.Control
                                         value={membro_dt_missionaria} onChange={(e)=>setMembro_dt_missionaria(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='date' 
                                         disabled={bloquear.formulario}/>
                                    </label>{" "}
                                    <label className='lbl-cadastro'>IGREJA
                                        <Form.Control
                                         value={membro_igreja_missionario} onChange={(e)=>setMembro_igreja_missionario(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>
                                </div>
                                <hr></hr>
                                <div>
                                    <label className='lbl-cadastro'>PRESBÍTERO
                                        <Form.Control
                                         value={membro_dt_prebitero} onChange={(e)=>setMembro_dt_prebitero(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='date' 
                                         disabled={bloquear.formulario}/>
                                    </label>{" "}
                                    <label className='lbl-cadastro'>IGREJA
                                        <Form.Control
                                         value={membro_igreja_presbitero} onChange={(e)=>setMembro_igreja_presbitero(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>
                                </div>
                                <hr></hr>
                                <div>
                                    <label className='lbl-cadastro'>EVANGELISTA
                                        <Form.Control
                                          value={membro_dt_evangelista} onChange={(e)=>setMembro_dt_evangelista(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='date' 
                                         disabled={bloquear.formulario}/>
                                    </label>{" "}
                                    <label className='lbl-cadastro'>IGREJA
                                        <Form.Control
                                         value={membro_igreja_evangelista} onChange={(e)=>setMembro_igreja_evangelista(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>
                                </div>
                                <hr></hr>
                                <div>
                                    <label className='lbl-cadastro'>PASTOR
                                        <Form.Control
                                        value={membro_dt_pastor} onChange={(e)=>setMembro_dt_pastor(e.target.value)} 
                                         style={{ width: 150 }} className='input-cadastro' type='date' 
                                         disabled={bloquear.formulario}/>
                                    </label>{" "}
                                    <label className='lbl-cadastro'>IGREJA
    
                                        <Form.Control
                                        value={membro_igreja_pastor} onChange={(e)=>setMembro_igreja_pastor(e.target.value)} 
                                         style={{ width: 300 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label>
                                </div>
                                <hr style={{ minWidth: 600 }}></hr>
                            </fieldset>

                        </div>
                    </div>
                </Tab>
                <Tab eventKey="formacao" title="Formação" >
                    <div>
                        <BotaoCadastro />
                        <div className='div-cadastro'>
                            <fieldset className='fieldset'>
                                <legend className='legend-cadastro'>Formação</legend>
                                <div>
                                    <label className='lbl-cadastro'>ESCOLARIDADE
                                        <Form.Control
                                        value={membro_escolaridade} onChange={(e)=>setMembro_escolaridade(e.target.value)} 
                                        style={{ width: 300 }} className='input-cadastro' type='text' 
                                        disabled={bloquear.formulario}/>
                                    </label> {" "}
                                    <label className='lbl-cadastro'>PORFISSÃO
                                        <Form.Control 
                                        value={membro_profissao} onChange={(e)=>setMembro_profissao(e.target.value)} 
                                        style={{ width: 300 }} className='input-cadastro' type='text' 
                                        disabled={bloquear.formulario}/>
                                    </label>{" "}
                                </div>
                                <div>
                                    <label className='lbl-cadastro'>TEOLOGIA
                                        <Form.Control 
                                        value={membro_teologia_desc} onChange={(e)=>setMembro_teologia_desc(e.target.value)} 
                                        style={{ width: 300 }} className='input-cadastro' type='text' 
                                        disabled={bloquear.formulario}/>
                                    </label> {" "}
                                    <label className='lbl-cadastro'>ESCOLA TEOLOGIA
                                        <Form.Control 
                                        value={membro_escola_teologia} onChange={(e)=>setMembro_escola_teologia(e.target.value)} 
                                        style={{ width: 300 }} className='input-cadastro' type='text' 
                                        disabled={bloquear.formulario}/>
                                    </label>{" "}
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="familiar" title="D. Familiar" >
                  
                        <BotaoCadastro />
                        <div className='div-cadastro'>
                            <fieldset className='fieldset'>
                                <legend className='legend-cadastro'>Dados Familiar</legend>
                                <div>
                                    <label className='lbl-cadastro'>CONJUGE
                                        <Form.Control
                                        value={membro_conjuge_nome} onChange={(e)=>setMembro_conjuge_nome(e.target.value)} 
                                         style={{ width: 350 }} className='input-cadastro' type='text' 
                                         disabled={bloquear.formulario}/>
                                    </label> {" "}
                                    <label className='lbl-cadastro'>DT. NASC.
                                        <Form.Control 
                                        value={membro_conjuge_dt_nasc} onChange={(e)=>setMembro_conjuge_dt_nasc(e.target.value)} 
                                        style={{ width: 150 }} className='input-cadastro' type='date' 
                                        disabled={bloquear.formulario}/>
                                    </label>{" "}
                                </div>
                                <div>
                                    <label className='lbl-cadastro'>FILHOS
                                        <Form.Control 
                                        value={membro_filho} onChange={(e)=>setMembro_filho(e.target.value)} 
                                        style={{ width: 100 }} className='input-cadastro' type='text' 
                                        disabled={bloquear.formulario}/>
                                    </label> {" "}
                                    <label className='lbl-cadastro'>IDADE
                                        <Form.Control 
                                        value={membro_filho_idade} onChange={(e)=>setMembro_filho_idade(e.target.value)} 
                                        style={{ width: 300 }} className='input-cadastro' type='text' 
                                        disabled={bloquear.formulario}/>
                                    </label>{" "}
                                </div>
                            </fieldset>
                        </div>
                   
                </Tab>
                <Tab eventKey="documentos" title="Documentos"  >
                    <BotaoCadastro />
                    <Button style={{marginBottom:10,marginLeft:10}}>Imprimir Carteirinha</Button>
                    <div>
                    <div className='div-carteira-p' style={{backgroundImage:`url(${carteira_membro_frente})`}}>
                    {/* <img className='img-carteira-perfil' src={membro_foto ?api.url+"uploads/"+membro_foto : logo} /> */}
                    <div style={{display:'flex',flexDirection:'column', marginTop:10,paddingLeft:17}}>
                        <label>{membro_nome}</label>
                        <label>{membro_id}</label>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{display:'flex',flexDirection:'column',marginInlineStart:17}}>
                            <label style={{fontSize:10}}>CARGO:</label>
                            <label>{tipo_desc}</label>
                        </div>
                        <div style={{display:'flex',flexDirection:'column',marginLeft:100}}>
                            <label style={{fontSize:10}}>FUNÇÃO:</label>
                            <label>{funcao_desc}</label>
                        </div>
                        <div style={{display:'flex',flexDirection:'column',marginLeft:120}}>
                            <label style={{fontSize:10}}>VALIDADE:</label>
                            <label>01/2024</label>
                        </div>
                    </div>
                    </div>
               
                    <div className='div-carteira-p' style={{backgroundImage:`url(${carteira_membro_verso})`}}>
                    <div style={{display:'flex',flexDirection:'row'}}>
                    <div style={{display:'flex',flexDirection:'row',marginLeft:17,width:250}}>
                        <div style={{display:'flex',flexDirection:'column',marginTop:40,marginLeft:17,width:200}}>
                            <label >SETOR: <span>{info.setor_nome}</span></label>
                            <label >RG: <span>{membro_rg}</span></label>
                            <label >CPF: <span>{membro_cpf}</span></label>
                            <label >DATA NASC.: <span>{converter_date.Date_Us( membro_dt_nascimento).date||""}</span></label>
                            <label >DATA BATISMO: <span>{converter_date.Date_Us( membro_dt_batismo).date||""}</span></label>
                        </div>
                       
                        </div>
                    {/* <img className='img-carteira-perfil' src={api.url+"uploads/"+membro_foto} /> */}
                    <div>
                    {/* <QRCode className='img-qrcode' value={generatedQRCode} /> */}
                    </div>

                    </div>
                    </div>
                    </div>
                </Tab>
            </Tabs>
            
        </div>
    );
    function BotaoCadastro() {
        return (
            <div className='div-button-cadastro'>
                <div>
                    <label className='lbl-info-membro'>{info.setor_nome}/ {info.congregacao_nome}</label>
                </div>
                <hr></hr>
                <div className='div-button-cadastro-btn'>
                    <Button className='btn-cadastro' onClick={Novo} disabled={bloquear.novo}>NOVO</Button>{" "}
                    <Button className='btn-cadastro' onClick={Alterar} disabled={bloquear.alterar}>ALTERAR</Button>{" "}
                    <Button className='btn-cadastro' onClick={Excluir} disabled={bloquear.excluir}>EXCLUIR</Button>{" "}
                    <Button className='btn-cadastro' onClick={Cancelar} disabled={bloquear.cancela}>CANCELAR</Button>{" "}
                    <Button className='btn-cadastro' onClick={Salvar} disabled={bloquear.salvar}>SALVAR</Button>{" "}
                </div>
                <hr></hr>
            </div>

        )
    }

}
export default CadastroMembro;