
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './style.css';
import { Button, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import { Get, Post, Put } from '../Api';
import { useNavigate } from 'react-router-dom';
import { TK } from '../Api/env';
import { DiaSemana } from './hook'

function Membro() {
    const navegar = useNavigate();
    const [listar_tipo, set_listar_tipo] = useState(["Tipo de Membro", "Visitante", "F.Assiduo", "M.Igreja", "Anfritrião", "L. Treinamento", "Lider"])
    const [m_congregacao_id, set_m_congregacao_id] = useState(TK().congregracao_id);
    const [m_grupo_id, set_m_grupo_id] = useState("");
    const [m_membro, set_m_membro] = useState("");
    const [m_tipo, set_m_tipo] = useState(0);
    const [m_tel, set_m_tel] = useState("");
    const [m_id, set_m_id] = useState("");
    const [showListar, setShowListar] = useState(false);
    const [showListarGrupo, setShowListarGrupo] = useState(false);
    const [ativo, set_ativo] = useState(1);
    const [is_alterar, set_is_alterar] = useState(false);
    const [lista, set_lista] = useState([]);
    const [lista_grupo, set_lista_grupo] = useState([]);
    const [grupo_nome, set_grupo_nome] = useState("");

    const CarregarMembro = async (grupo_id) => {

        const { data } = await Get(`/pg/membro?grupo_id=${grupo_id}`);
        if (data.sucess) {
            console.log('lista membro', data, "Id", grupo_id);
            set_lista(data?.data);
        }
    }

    const Listar = () => {

        setShowListar(true)
    }

    const ListarGrupo = async () => {
        const { data } = await Get(`/pg/grupo/listar?g_lider_id=${TK().id}`);
        //const { data } = await Get(`/pg/membro/listar/grupo?g_lider_id=${TK()?.id}`);
        console.log("lista ", data, "id_lider", TK()?.id);
        if (data?.data) {
            set_lista_grupo(data?.data)
            setShowListarGrupo(true);
        }

    }

    const Novo = () => {
        Limpar();

    }

    const Salvar = async () => {
   

        if (!m_membro)
            return alert('Digite um nome ');
        if (m_tipo==0)
            return alert('Selecione um tipo ');

        const { data } = await Post('/pg/membro/', {
            m_congregacao_id,
            m_grupo_id,
            m_membro,
            m_tipo,
            m_tel
        });
        if (data.sucess) {
            console.log('salvando membro', data);
            alert('Salvo com sucesso!');
            CarregarMembro(m_grupo_id);
            
        }
    }

    const Cancelar = () => {
        set_is_alterar(false);
        Limpar();
    }

    const Alterar = async() => {
        if (!m_membro)
            return alert('Digite um nome ');
        if (m_tipo==0)
            return alert('Selecione um tipo ');

        const { data } = await Put('/pg/membro/', {
            m_id,
            m_congregacao_id,
            m_grupo_id,
            m_membro,
            m_tipo,
            m_tel
        });
        if (data.sucess) {
            console.log('alterando membro', data);
            alert('Alterado com sucesso!');
            CarregarMembro(m_grupo_id);
            Limpar();
            
        }
    }

    const Limpar = () => {
      
        set_m_membro("");
        set_m_tipo(0);
        set_m_tel("");
        set_is_alterar(false);
    }

    const SelecionarGrupo = (grupo) => {
        set_lista([]);
        if (!grupo.g_id) {
            alert('Selecione um grupo!');
            return false;
        }
        set_grupo_nome(grupo.g_nome);
        set_m_grupo_id(grupo.g_id);
        setShowListarGrupo(false);
        CarregarMembro(grupo.g_id);
        Limpar();
        return true;

    }

     const SelecioneMembro = (value) =>{
        set_m_tipo(value.m_tipo);
        set_m_membro(value.m_membro);
        set_m_tel(value.m_tel);
        set_m_id(value.m_id);
        setShowListar(false);
        set_is_alterar(true);
     }

    const onHideShowListarGrupo = () => {
        if (SelecionarGrupo(false))
            setShowListarGrupo(false);

    }
    
    useEffect(() => {

        ListarGrupo();
        set_m_congregacao_id(TK().congregracao_id);


    }, []);



    return (
        <div>


            <div className='lider-body'>


                {/* Adição de Novo Membro */}
                <div className='lider-form'>
                    <Button onClick={() => navegar('/pg/inicio')}>Voltar</Button>
                    <label className='lider-cabecario'>Adicionar Membro</label>
                    <label className='membro-label-grupo' onClick={() => ListarGrupo()}>{grupo_nome}</label>
                    <label className='lider-label-dados'>Dados do Membro</label>
                    <select
                        className='grupo-select'
                        value={m_tipo}
                        onChange={(e) => set_m_tipo(e.target.value)}
                    >
                        <option value={0}>Tipo do Membro</option>
                        <option value={1}>Visitante</option>
                        <option value={2}>F.Assiduo</option>
                        <option value={3}>M.Igreja</option>
                        <option value={4}>Anfitrião</option>
                        <option value={5}>L.Treina</option>
                        <option value={6}>Lider</option>
                    </select>
                    <input type="text" name="l_membro_id" placeholder="Nome Completo" value={m_membro} onChange={(e) => set_m_membro(e.target.value)} />

                    <input type="text" name="l_tel" placeholder="Celular" value={m_tel} onChange={(e) => set_m_tel(e.target.value)} />


                    {is_alterar ?

                        <div style={{ paddingRight: 10, marginBottom: 30, display: "flex", flexDirection: "row-reverse", gap: 10 }}>
                            <Button style={{ width: 100 }} variant='success' onClick={() => Alterar()}>Alterar</Button>
                            <Button style={{ width: 100 }} variant='warning' onClick={() => Cancelar()}>Cancelar</Button>
                            <Button style={{ width: 100 }} variant='danger'>Excluir</Button>
                        </div>
                        :
                        <div style={{ paddingRight: 10, marginBottom: 30, display: "flex", flexDirection: "row-reverse", gap: 10 }}>

                            <Button style={{ width: 100 }} variant='success' onClick={() => Salvar()}>Salvar</Button>
                            <Button style={{ width: 100 }} onClick={() => Novo()} >Novo</Button>
                            <Button style={{ width: 100 }} variant='warning' onClick={() => Listar()}>Listar</Button>
                        </div>

                    }

                </div>
                <Modal //Show Grupo
                    show={showListarGrupo} fullscreen={false} onHide={() => onHideShowListarGrupo()}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <label>Lista dos Grupos</label> <button style={{ width: 80, backgroundColor: 'lightseagreen' }} onClick={() => navegar('/pg/inicio')}>Voltar</button>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table hover striped>
                            <thead>
                                <th>
                                    <tr>Nome do Grupo</tr>
                                </th>
                                <th>
                                    <tr>Dia</tr>
                                </th>
                                <th>
                                    <tr>horario</tr>
                                </th>
                            </thead>
                            <tbody>
                                {lista_grupo.map((value) => (
                                    !value.motivo?
                                    <tr className="hover" onClick={() => SelecionarGrupo(value)}>
                                        <td>
                                            {value.g_nome}
                                        </td>
                                        <td>
                                            {DiaSemana(value.g_dia)}
                                        </td>
                                        <td>
                                            {value.g_hora}
                                        </td>
                                    </tr>:""))
                                }
                            </tbody>
                        </Table>

                    </Modal.Body>
                </Modal>


                <Modal //modal Despesa
                    show={showListar} fullscreen={false} onHide={() => setShowListar(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <label>PG - {grupo_nome} </label>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Lista de Membro </label>

                        <Table hover striped>
                            <thead>
                                <tr>
                                    <th>
                                        Membro
                                    </th>
                                    <th>
                                        Tipo
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {lista.map((value) => (

                                    <tr onClick={()=>SelecioneMembro(value)}>
                                        <td>
                                            {value.m_membro}
                                        </td>
                                        <td>
                                            {listar_tipo[value.m_tipo]}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>

            </div>
        </div>
    );
};


export default Membro;