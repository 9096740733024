import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import NavBar from "../../../components/NavBar";
import "./setor.css";
import api from "../../../services/api";
import { useState, useEffect } from "react";
import ListarSetor from "../../../components/Listar/ListarSetor";

function Setor() {
  const [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
  const [id, setId] = useState("");
  const [setor, setSetor] = useState("");
  const [congregacao, setCongregacao] = useState("");
  const [rua, setRua] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [pastor, setPastor] = useState("");
  const [cel, setCel] = useState("");
  const [cep, setCep] = useState("");
  const [complemento, setComplemento] = useState("");
  const [tipo, setTipo] = useState("");
  const [listSetor,setListSetor]=useState([]);

  const addSetor=()=>{
    api.postSetSetor({setor:setor,info:info}).then(async({ data }) => {
      if(data){
       console.log(await data)
       
      }
    });
    setListSetor([
      ...listSetor,
      {
        setor: setor,
      },
    ]);
  
    setSetor("");

}
const getSetor=()=>
{
    api.postGetSetor({info}).then(({ data }) => {
      if(data){
       setListSetor(data);
      }
    });
}

useEffect(() => {
    getSetor();
  }, []);

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  function handleLogin(e) {
    e.preventDefault();
  }

  return (
    <div style={{backgroundColor:"#000"}} className="container-igreja">
      <NavBar navegando="CADASTRO DE SETOR" />
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
      <Form className="container-form" onSubmit={handleLogin}>
   
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
         ></hr>
        <Row //Nome da congragação
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-igreja">NOME DO SETOR</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o nome do setor"
              value={setor}
              onChange={(e) => setSetor(e.target.value)}
            />
          </Form.Group>

        </Row>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
         ></hr>
        <div className="button-igreja">
          <Button
            className="button-igreja-salvar"
            variant="primary"
            onClick={addSetor}
          >
            ADICIONAR
          </Button>
          
        </div>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
         ></hr>
         <ListarSetor listar={listSetor}></ListarSetor>
       
      </Form>
      <Modal //modal de pesquisa
       show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
          <Row //Nome do igreja, data de batismo
          className="mb-3"
        >
            <Form.Group as={Col}>
              <Form.Label className="label-igreja">PESQUISAR igreja</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome"
                style={{ width: 400 }}
                value={congregacao}
                onChange={(e) => setCongregacao(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="label-igreja">SETOR</Form.Label>
              <Form.Select defaultValue="1">
                <option value="1">AMPARO</option>
                <option value="2">MONTE ALEGRE DO SUL</option>
              </Form.Select>
            </Form.Group>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Lista de Igreja</Modal.Body>
      </Modal>
    </div>
  );
}

export default Setor;
