
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './style.css';
import { Button, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import { Get, Post, Put } from '../Api';
import { useNavigate } from 'react-router-dom';
import { TK } from '../Api/env';
import { CenturyView } from 'react-calendar';
function Grupo() {
    const navegar = useNavigate();
    const [listar_tipo, set_listar_tipo] = useState(["Tipo Grupo", "Familia", "Mulheres", "Homens", "Jovens", "Adolecentes", "Kids"]);
    const [listar_fase, set_listar_fase] = useState(["Fase Atual", "Comunhão", "Edificação", "Evangelismo", "Multiplicação"]);
    const [listar_Dia, set_listar_Dia] = useState(["Dia do Grupo", "Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta"]);
    const [g_id, set_g_id] = useState("");
    const [g_setor_id, set_g_setor_id] = useState("");
    const [g_congregacao_id, set_g_congregacao_id] = useState("");
    const [g_lider_id, set_g_lider_id] = useState("");
    const [g_nome, set_g_nome] = useState("");
    const [g_tipo, set_g_tipo] = useState("");
    const [g_fase, set_g_fase] = useState("");
    const [g_dia, set_g_dia] = useState("");
    const [g_hora, set_g_hora] = useState("");
    const [g_tel_lider, set_g_tel_lider] = useState("");
    const [g_tel_anfitreao, set_g_tel_anfitreao] = useState("");
    const [g_cep, set_g_cep] = useState("");
    const [g_rua, set_g_rua] = useState("");
    const [g_numero, set_g_numero] = useState("");
    const [g_complemento, set_g_complemento] = useState("");
    const [g_bairro, set_g_bairro] = useState("");
    const [g_cidade, set_g_cidade] = useState("");
    const [g_uf, set_g_uf] = useState("");
    const [showListar, setShowListar] = useState(false);
    const [is_alterar, set_is_alterar] = useState(false);
    const [info, setInfo] = useState([]);
    const [listar, set_listar] = useState([]);




    const Limpar = () => {
        set_g_id(0);
        set_g_nome("");
        set_g_tipo("");
        set_g_fase("");
        set_g_dia("");
        set_g_hora("");
        set_g_tel_lider("");
        set_g_tel_anfitreao("");
        set_g_cep("");
        set_g_rua("");
        set_g_numero("");
        set_g_complemento("");
        set_g_bairro("");
        set_g_cidade("");
        set_g_uf("");

    }
    const Novo = () => {
        Limpar();
    }
    const isVazio = () => {

        if (!g_nome) {
            alert("Digite um nome do grupo!");
            return false;

        }
        if (!g_tipo) {
            alert("Selecione um tipo!");
            return false;
        }
        if (!g_fase) {
            alert("Selecione uma fase!");
            return false;
        }
        if (!g_dia) {
            alert("Selecione um dia!");
            return false;
        }

        if (!g_hora) {
            alert("Selecione um horario!");
            return false;
        }
        if (!g_rua) {
            alert("Digite uma rua!");
            return false;
        }
        if (!g_bairro) {
            alert("Digite uma bairro!");
            return false;
        }
        if (!g_cidade) {
            alert("Digite uma cidade!");
            return false;
        }
        return true;
    }
    const Salvar = async () => {

        if (isVazio()) {
            const { data } = await Post('/pg/grupo', {
                g_setor_id,
                g_congregacao_id,
                g_lider_id,
                g_nome,
                g_tipo,
                g_fase,
                g_dia: parseInt(g_dia - 1),
                g_hora,
                g_tel_lider,
                g_tel_anfitreao,
                g_cep,
                g_rua,
                g_numero,
                g_complemento,
                g_bairro,
                g_cidade,
                g_uf
            });
            console.log("grupo post.. ", data)
            if (data) {
                Limpar();
                alert("Cadastrado com sucesso!!!");
            }
        }
    }
    const Alterar = async () => {

        const { data } = await Put('/pg/grupo', {
            g_id,
            g_setor_id,
            g_congregacao_id,
            g_lider_id,
            g_nome,
            g_tipo,
            g_fase,
            g_dia: parseInt(g_dia - 1),
            g_hora,
            g_tel_lider,
            g_tel_anfitreao,
            g_cep,
            g_rua,
            g_numero,
            g_complemento,
            g_bairro,
            g_cidade,
            g_uf
        });
        console.log("grupo post.. ", data)
        if (data) {
            alert("Alterado com sucesso!!!");
            set_is_alterar(false);
            Limpar();
        }
    }
    const Cancelar = () => {
        set_is_alterar(false);
        Limpar();
    }

    const Finalizar = async () => {

        const motivo = window.prompt("Digite o motivo.");

        if (motivo == null)
            return ;
        
        if (!motivo) 
            return alert("Digite um motivo!");

        const { data } = await Post('/pg/grupo/finalizado', {
            motivo,
            id_grupo: g_id
        });
        // console.log("grupo post.. ", data)
        if (data) {
             
            alert("Grupo finalizado com sucesso!!! ");
            navegar('/pg/inicio');
        }
    }


const Listar = async () => {
   
    const { data } = await Get(`/pg/grupo/listar?g_lider_id=${TK().id}`);
    //const { data } = await Get(`/pg/membro/listar/grupo?g_lider_id=${TK().id}`);
    console.log("lista ", data);
    if (data?.data) {
        set_listar(data?.data)
        setShowListar(true);
    }

}

const Inicio = () => {

    const _info = localStorage.getItem('config');
    if (_info) {

        let config = JSON.parse(_info)
        setInfo(config);
        console.log(config);
        set_g_congregacao_id(config?.congregracao_id);
        set_g_setor_id(config?.setor_id);
        set_g_lider_id(config?.id);
    }


}

const Selecionar = (value) => {
    set_g_id(value.g_id);
    set_g_setor_id(value.g_setor_id);
    set_g_congregacao_id(value.g_congregacao_id);
    set_g_lider_id(value.g_lider_id);
    set_g_nome(value.g_nome);
    set_g_tipo(value.g_tipo);
    set_g_fase(value.g_fase);
    set_g_dia(parseInt(value.g_dia) + 1);
    set_g_hora(value.g_hora);
    set_g_tel_lider(value.g_tel_lider);
    set_g_tel_anfitreao(value.g_tel_anfitreao);
    set_g_cep(value.g_cep);
    set_g_rua(value.g_rua);
    set_g_numero(value.g_numero);
    set_g_complemento(value.g_complemento);
    set_g_bairro(value.g_bairro);
    set_g_cidade(value.g_cidade);
    set_g_uf(value.g_uf);
    setShowListar(false);
    set_is_alterar(true)

}
useEffect(() => {
    Inicio();
}, []);



return (
    <div>

        <div className='lider-body'>

            {/* Adição de Novo Membro */}
            <div className='lider-form'>

                <div style={{ height: 50 }}>
                    <Button onClick={() => navegar('/pg/inicio')}>Voltar</Button>
                </div>
                <label className='lider-cabecario'>Adicionar Grupo</label>
                {is_alterar ?

                    <div style={{ paddingRight: 10, marginBottom: 30, display: "flex", flexDirection: "row-reverse", gap: 10 }}>
                        <Button style={{ width: 100 }} variant='success' onClick={() => Alterar()}>Alterar</Button>
                        <Button style={{ width: 100 }} variant='warning' onClick={() => Cancelar()}>Cancelar</Button>
                        <Button style={{ width: 100 }} variant='danger' onClick={() => Finalizar()}>Finalizar</Button>
                    </div>
                    :
                    <div style={{ paddingRight: 10, marginBottom: 30, display: "flex", flexDirection: "row-reverse", gap: 10 }}>

                        <Button style={{ width: 100 }} variant='success' onClick={() => Salvar()}>Salvar</Button>
                        <Button style={{ width: 100 }} onClick={() => Novo()} >Novo</Button>
                        <Button style={{ width: 100 }} variant='warning' onClick={() => Listar()}>Listar</Button>
                    </div>

                }
                <label className='grupo-label-dados'>Dados do Grupo</label>
                <input className='grupo-input' type="text" name="l_setor_id" placeholder="Nome do Gupo" value={g_nome} onChange={(e) => set_g_nome(e.target.value)} />
                <select
                    className='grupo-select'
                    value={g_tipo}
                    onChange={(e) => set_g_tipo(e.target.value)}
                >
                    {listar_tipo.map((value, index) => (<option value={index}>{value}</option>))}

                </select>
                <select
                    className='grupo-select'
                    value={g_fase}
                    onChange={(e) => set_g_fase(e.target.value)}
                >
                    <option value={0}>Fase Atual</option>
                    <option value={1}>Comunhão</option>
                    <option value={2}>Edificação</option>
                    <option value={3}>Evangelismo</option>
                    <option value={4}>Multiplicação</option>

                </select>
                <div>
                    <select
                        className='grupo-select'
                        value={g_dia}
                        onChange={(e) => set_g_dia(e.target.value)}
                    >
                        <option value={0}>Dia do Grupo</option>
                        <option value={1}>Domingo</option>
                        <option value={2}>Segunda</option>
                        <option value={3}>Terça</option>
                        <option value={4}>Quarta</option>
                        <option value={5}>Quinta</option>
                        <option value={6}>Sexta</option>
                        <option value={7}>Sábado</option>
                    </select>
                    <input style={{ width: 150,height:45,borderRadius:10,padding:5,backgroundColor:"rgba(0, 140, 255, 0.384)"}} type='time' value={g_hora} onChange={(e) => set_g_hora(e.target.value)} />
                </div>
                <input className='grupo-input' type="text" placeholder="Telefone (Lider)" value={g_tel_lider} onChange={(e) => set_g_tel_lider(e.target.value)} />
                <input className='grupo-input' type="text" placeholder="Telefone (Anfitreão)" value={g_tel_anfitreao} onChange={(e) => set_g_tel_anfitreao(e.target.value)} />
                <label className='grupo-label-dados'>Endereço</label>
                <input className='grupo-input' type="text" placeholder="Nome da Rua, N°" value={g_rua} onChange={(e) => set_g_rua(e.target.value)} />
                <input className='grupo-input' type="text" placeholder="Bairro" value={g_bairro} onChange={(e) => set_g_bairro(e.target.value)} />
                <input className='grupo-input' type="text" placeholder="Cidade" value={g_cidade} onChange={(e) => set_g_cidade(e.target.value)} />
                <input className='grupo-input' type="text" placeholder="Estado" value={g_uf} onChange={(e) => set_g_uf(e.target.value)} />

            </div>

            <Modal //modal Despesa
                show={showListar} fullscreen={false} onHide={() => setShowListar(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <p>Lista de Grupos</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Hora</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listar.map((value) => (
                                    value.motivo ?
                                    <tr style={{background:"#8B0000",color:"white"}}>
                                    <td>{value.g_nome}</td>
                                    <td>{value.g_hora}</td>
                                    </tr>
                                    :
                                    <tr className='hover' onClick={() => Selecionar(value)}>
                                        <td>{value.g_nome}</td>
                                        <td>{value.g_hora}</td>
                                    </tr>
                                    
                                ))
                            }
                        </tbody>
                    </Table>

                </Modal.Body>
            </Modal>

        </div>
    </div>
);
};




export default Grupo;