import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routesTesouraria } from './tesouraria';
import { routesPG } from './pg';
import Login from '../Login';
import { routesSecretaria } from './secretaria';

export const Routes = () => {

 // const { token } = useAuth();
  const login = [
    {
      path:'/login',
      element:<Login/>
    },
    {
      path:'/',
      element:<Login/>
    },
  ]

  const router = createBrowserRouter([
    ...login,
    ...routesTesouraria,
    ...routesPG,
    ...routesSecretaria
  ]);

  return <RouterProvider router={router} />;
}