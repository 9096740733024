import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import NavBar from "../../../components/NavBar";
import "./igreja.css";

import api from "../../../services/api";
import { useState, useEffect, useRef } from "react";

function Igreja() {
  
  const [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
  const inputRef = useRef(null);
  const [id, setId] = useState("");
  const [setor, setSetor] = useState("");
  const [congregacao, setCongregacao] = useState("");
  const [rua, setRua] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("sp");
  const [pastor, setPastor] = useState("");
  const [cel, setCel] = useState("");
  const [cep, setCep] = useState("");
  const [complemento, setComplemento] = useState("");
  const [tipo, setTipo] = useState("");
  const [listSetor,setListSetor] =useState([]);
  //////////////////////////////////////////////////
  const salvar=()=>{
    if(setor==0)
      return alert("Selecione o setor")
    if(congregacao==="")
    return alert("Digite o nome da congregação")
    api.postSetCongregacao({
       fk_setor :setor,
       congregacao:congregacao,
       rua:rua,
       complemento:complemento,
       bairro:bairro,
       cidade:cidade,
       uf:uf,
       cep:cep,
       situacao:tipo,
       nome_pastor:pastor,
       cel_pastor:cel,
       info:info
     
    })
    .then((response) => {

      alert(response.data.msg);
      console.log(response);
    });
      setCongregacao("");
      setRua("");
      setComplemento("");
      setBairro("");
      setCidade("");
      setCep("");
      setPastor("");
      setCel("");
      inputRef.current.focus();

   
  }

  const getSetor=()=>
  {
      api.postGetSetor({info}).then(({ data }) => {
        if(data){
         setListSetor(data);
        }
      });
  }
  useEffect(() => {
    getSetor();
   
  }, []);

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  function handleLogin(e) {
    e.preventDefault();
  }
  function selectSetor(e){
    let v = e.target.value;
    console.log(v);
    setSetor(v);
  }
    
  return (
    <div style={{backgroundColor:"#000"}} className="container-igreja">
      <NavBar navegando="CADASTRO DE IGREJA" />
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
      <Form className="container-form" onSubmit={handleLogin}>
        <Row
         className="mb-3"
        >
        <Form.Group className="tm" as={Col}>
            <Form.Label className="label-igreja">SETOR</Form.Label>
            <Form.Select
            onChange={selectSetor} 
             defaultValue="1">
                <option value={"0"}>{""}</option>
              {    
                  listSetor.map((list,index)=>(
                  <option key={index} value={""+list.id_setor}>{list.nome_setor}</option>
                  ))
              }
            
            </Form.Select>
          </Form.Group>
        </Row>
     
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
         ></hr>
        <Row //Nome da congragação
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-igreja">NOME DA CONGREGAÇÃO</Form.Label>
            <Form.Control
             ref={inputRef}
              type="text"
              placeholder="Digite o da congregação"
              value={congregacao}
              onChange={(e) => setCongregacao(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="tm" as={Col}>
            <Form.Label className="label-igreja">TIPO DO IMOVEL</Form.Label>
            <Form.Select  defaultValue="1">
              <option value="1">PROPRIO</option>
              <option value="2">ALUGADO</option>
              <option value="3">COMODATO</option>
            </Form.Select>
          </Form.Group>
        </Row>

        <Row // rua, complemento
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-igreja">NOME DA RUA</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o endereço"
              value={rua}
              onChange={(e) => setRua(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className="label-igreja">COMPLEMENTO</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o complemento"
              value={complemento}
              onChange={(e) => setComplemento(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row // bairro, cidade, uf, cep
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-igreja">BAIRRO</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o bairro"
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className="label-igreja">CIDADE</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite a cidade"
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
            />
          </Form.Group>
          </Row>
          <Row>
          <Form.Group as={Col}>
            <Form.Label className="label-igreja">UF</Form.Label>
            <Form.Select
            onChange={(e) => setUf(e.target.value)}
             defaultValue="sp">
              <option value="ac">Acre</option>
              <option value="al">Alagoas</option>
              <option value="am">Amazonas</option>
              <option value="ap">Amapá</option>
              <option value="ba">Bahia</option>
              <option value="ce">Ceará</option>
              <option value="df">Distrito Federal</option>
              <option value="es">Espírito Santo</option>
              <option value="go">Goiás</option>
              <option value="ma">Maranhão</option>
              <option value="mt">Mato Grosso</option>
              <option value="ms">Mato Grosso do Sul</option>
              <option value="mg">Minas Gerais</option>
              <option value="pa">Pará</option>
              <option value="pb">Paraíba</option>
              <option value="pr">Paraná</option>
              <option value="pe">Pernambuco</option>
              <option value="pi">Piauí</option>
              <option value="rj">Rio de Janeiro</option>
              <option value="rn">Rio Grande do Norte</option>
              <option value="ro">Rondônia</option>
              <option value="rs">Rio Grande do Sul</option>
              <option value="rr">Roraima</option>
              <option value="sc">Santa Catarina</option>
              <option value="se">Sergipe</option>
              <option value="sp">São Paulo</option>
              <option value="to">Tocantins</option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className="label-igreja">CEP</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o cep"
              value={cep}
              onChange={(e) => setCep(e.target.value)}
            />
          </Form.Group>
        </Row>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
         ></hr>
        <Row //Nome do pastor
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-igreja">NOME DO PASTOR</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o nome do pastor"
              value={pastor}
              onChange={(e) => setPastor(e.target.value)}
            />
        
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="label-igreja">CELULAR</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite o numero do celular"
              value={cel}
              onChange={(e) => setCel(e.target.value)}
            />
          </Form.Group>
        </Row>
        <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
         ></hr>
        <div className="button-igreja">
          <Button
            className="button-igreja-salvar"
            variant="primary"
            onClick={salvar}
          >
            Salvar

            
          </Button>
          <Button
            className="button-igreja-cancelar"
            onClick={() => handleShow(true)}
          >
            Cancelar
          </Button>
        </div>
      </Form>
      <Modal //modal de pesquisa
       show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
          <Row //Nome do igreja, data de batismo
          className="mb-3"
        >
            <Form.Group as={Col}>
              <Form.Label className="label-igreja">PESQUISAR igreja</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o nome"
                style={{ width: 400 }}
               
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="label-igreja">SETOR</Form.Label>
              <Form.Select defaultValue="1">
                <option value="1">AMPARO</option>
                <option value="2">MONTE ALEGRE DO SUL</option>
              </Form.Select>
            </Form.Group>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Lista de Igreja</Modal.Body>
      </Modal>
    </div>
  );
}

export default Igreja;
