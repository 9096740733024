
import './styles.css'
import logo from './adbelem.png';
import logo_bg from './adbelem-bg.png';
import { useState } from 'react';

export const LayoutCongregacao = ({ lista, mes }) => {
    const [list, setlist] = useState(lista);
    return (
        <div className='a4'>
            <div className='lay-head'>
                <label style={{ marginLeft: -410, width: 700, height: 5, backgroundColor: "rgb(129, 148, 46)" }}></label>
            </div>
            <div className='lay-row'>
                <div className='lay-col1'>
                    <div style={{ height: 250 }}>
                        <img src={logo_bg} width={180} height={200} />
                    </div>
                    <div style={{ fontSize: 12, display: "flex", flexDirection: "column", padding: 10, color: "#000" }}>
                        <label style={{ padding: 10, fontWeight: "bold" }}>SETORES</label>
                        <label>• Amparo SP
                        </label><label>• Pedreira SP
                        </label><label>• Mostardas SP
                        </label><label>• Monte Alegre do Sul SP
                        </label><label>• Serra Negra SP
                        </label><label>• Lindóia SP
                        </label><label>• Águas de Lindóia SP
                        </label><label>• Socorro SP
                        </label><label>• Pinhalzinho SP
                        </label><label>• Pedra Bela SP
                        </label><label>• Bragança Paulista SP
                        </label><label>• Itatiba SP
                        </label><label>• Morungaba SP
                        </label><label>• Tuiuti SP
                        </label>
                        <label style={{ padding: 10, fontWeight: "bold" }}> SETORES MISSIONÁRIOS</label>

                        <label>• Extrema MG
                        </label><label>• Itapeva MG
                        </label><label>• Camanducaia MG
                        </label><label>• Lambari MG
                        </label><label>• Jesuania MG
                        </label><label>•  Cambuquira MG
                        </label><label>• Olimpio Noronha MG
                        </label><label>• Guaxupé MG
                        </label><label>• Iraquara BA
                        </label><label>• Brejo de João Alves  BA
                        </label><label>• Coaracy  BA
                        </label><label>• Itamotinha  BA
                        </label><label>• Itapitanga  BA
                        </label><label>• Assunção PY
                        </label>

                    </div>
                </div>
                <div className='lay-col2'>
                    <div style={{ fontSize: 12, display: "flex", flexDirection: "column", padding: 10, backgroundColor: "#FFF", color: "#000" }}>
                        <label style={{ fontSize: 28, textAlign: "center", fontWeight: "bold" }}>IGREJA ASSEMBLEIA DE DEUS</label>
                        <label style={{ fontSize: 18, textAlign: "center", fontWeight: "bold" }}>MINISTÉRIO DO BELÉM - AMPARO  SP</label>
                        <label style={{ fontSize: 12, textAlign: "center", fontWeight: "bold", color: "blue" }}> Pastor Nilson Marcos Lima - Presidente</label>
                    </div>
                    <label style={{ backgroundColor: "#000", color: "#FFF", fontWeight: "bold", fontSize: 20, textAlign: "center", width: 590 }}>DIZIMISTA DO MÊS DE {mes} </label>
                    <div className='lay-list'>

                        {
                            list?.map((value, index) => (
                           !String( value.membro).includes("ANÔNIMO") &&
                                    <label key={index} style={{ width: "100%", color: "#000" }}>
                                    {value.membro}
                                    </label>
                                     
                                
                            ))
                        }


                    </div>
                    <div style={{ backgroundColor: "#fff" }}>
                        <label style={{ fontWeight: "bold", marginLeft: 10, color: "#000",textAlign:"justify" }}>
                            Agradecemos aos irmãos que, com seus dízimos, honraram a Deus e abençoaram a sua obra neste mês. Que Deus os abençoe!
                         </label>
                         <label style={{ fontWeight: "bold", marginLeft: 10,marginBottom:10, color: "#000",textAlign:"justify" }}>
                            "Honra ao Senhor com os teus bens, e com as primícias de toda a tua renda; e se encherão os teus celeiros de fartura, e transbordarão de vinho novo os teus lagares." (Provérbios 3:9-10).
                        </label>
                        <div style={{
                            color: "#FFF", backgroundColor: "#000", fontSize: 12, textAlign: "center",fontWeight:"bold" ,padding: 5, width: 595, marginLeft: -5
                        }}>
                            <label>Avenida Bernardino de Campos, nº 901 – Centro – Amparo – SP – CEP 13900-400 </label>
                            <label>E-mail: secretariageral.adamparo@outlook.com</label>

                        </div>
                    </div>

                </div>
                {/* // {lista?.map((value)=>(<label>{value.membro}</label>))} */}
            </div>

        </div>
    )

}