import fundo from '../img/fundo.png';
import fundo_geral from '../img/fundo_geral.png';
import fundo_inicial from '../img/fundo_inicial.png';
import logo from '../img/logo.png';
import label_secretaria from '../img/label_secretaria.png';
export default{
    fundo:fundo,
    fundo_geral:fundo_geral,
    fundo_inicial:fundo_inicial,
    logo:logo,
    label_secretaria:label_secretaria

    };