export function gerarSenha() {
    const caracteresPermitidos = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let senha = '';
  
    for (let i = 0; i < 8; i++) {
      const indiceAleatorio = Math.floor(Math.random() * caracteresPermitidos.length);
      senha += caracteresPermitidos.charAt(indiceAleatorio);
    }
  
    return senha;
  }

  export function DiaSemana(dia){
    const dia_semana=["Domingo","Segunda","Terça","Quarta","Quinta","Sexta","Sabado"]
    return dia_semana[dia]||"";
  }