import axios from 'axios';
import { TOK, URL_SERVIDOR } from './env';


export async function Post(link, dados) {
    return axios({
        url: URL_SERVIDOR + link,
        method: "POST",
        timeout: 5000,
        data: dados,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${TOK()}`
        }

    }).then((data) => {
        return Promise.resolve(data)

    }).catch((error) => {
        return Promise.reject(error)
    })
}


export async function Put(link, dados) {
    return axios({
        url: URL_SERVIDOR + link,
        method: "PUT",
        timeout: 5000,
        data: dados,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${TOK()}`
        }

    }).then((data) => {
        return Promise.resolve(data)

    }).catch((error) => {
        return Promise.reject(error)
    })
}

export async function Get(link) {
  
    return axios({
        url: URL_SERVIDOR + link,
        method: "GET",
        timeout: 5000,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${TOK()}`
        }

    }).then((data) => {
        return Promise.resolve(data)

    }).catch((error) => {
        return Promise.reject(error)
    })
}

export async function Delete(link) {
    
    return axios({
        url: URL_SERVIDOR + link,
        method: "DELETE",
        timeout: 5000,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${TOK()}`
        }

    }).then((data) => {
        return Promise.resolve(data)

    }).catch((error) => {
        return Promise.reject(error)
    })
}

