import { Form,Col,Row, Table } from "react-bootstrap";
import converter from "../../../Util/Converter";
import NavBar from "../../../components/NavBar";
import { InfoUser } from "../../../components/info/login";
import api from "../../../services/api";
import { useState, useEffect,useRef } from "react";

export default function TalaoLancado(){
    
 
  const inputRef = useRef(null);
  ////////////////////////////////////////////////////////////////////////////////
  const [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
  const [id_setor, setIdSetor] = useState();

   const [dates, setDates] = useState("");
   const [dataSistem, setDataSistem] = useState("");
   const [dia,setDia]=useState(0);
   const [mes,setMes]=useState(0);
   const [ano,setAno]=useState(0);
   const [mesano,setMesano] =useState("0");
   const [todos,setTodos] =useState(0);
   const [listaTalao,setListaTalao]=useState([]);
   const [listPendente,setListPendente]=useState([]);
   


   //////////////////////////////////////////////////////////////////////////////
  
  
  const [listSetor,setListSetor] =useState([]);

  const getCongragacao=async(setor)=>
{
    const dt = await api.postGetCongregacaoSetor({
        setor:setor,
        info:info,
      });
      console.log("setor ",dt.data)
       
}
const getCongragacaoAll=async()=>
{
    const {data} = await api.postGetCongregacao({
        info:info
      });
      console.log("Congregacao ",data)
       
}

 async function getTalao(datta,settor,ttodos){
    const lancado=[];
    const pendente=[];
   const {data} =await api.TalaoLancado({
        dat:datta,
        setor:settor,
        todos:ttodos,
        info:info
        });
        if(data){
          data.map((valor)=>{
                if(converter.Dates( valor.data).data==dates)
                    lancado.push(valor);
                else
                    pendente.push(valor);
                    
          })

        }
        setListaTalao(lancado);
        setListPendente(pendente);
        console.log("Lançado ",lancado);
        console.log("Pendente ",pendente);

  }
  const getData=()=>
  {
      api.postGetDate({}).then(({ data }) => {
        if(data){
          const date =converter.Date(data);
          setDataSistem(date);
          setDates(date.data);
          setDia(date.dia);
          setMes(date.mes);
          setAno(date.ano);
          setMesano(date.mes+""+date.ano);
          console.log(dates);
        }
      });
  }
    
   
  useEffect(() => {
      getData();
      getSetor();
      //getCongragacao(2);
      //getCongragacaoAll();
  
    }, []);
  
   
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
  
    function handleShow(breakpoint) {
      setFullscreen(breakpoint);
      setShow(true);
    }
  
    function handleData(e) {
     let date = e.target.value;
      setDates(date);
      getTalao(date,id_setor,todos);
     
    }
  
    function handleLogin(e) {
      e.preventDefault();
    }
  
    function selectSetor(e){
      let v = e.target.value;
      let t=0;
      setIdSetor(v);
      if(v>0)
       t=0;
      else
      t=1;
      setTodos(t);
      getTalao(dates,v,t);



   }

  
  const getSetor=()=>
  {
       api.postGetSetor({info}).then(({data}) => {
         if(data){
          setListSetor(data);
          
         }
       });
   }

  
    return (
      <div style={{backgroundColor:"#000"}}  className="container-culto">
        <NavBar navegando="TALÕES LANÇADOS " />
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <Form className="container-form" onSubmit={handleLogin}>
          <InfoUser info={info} data={dataSistem.date}/>
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>
          <div style={{minHeight:150}}>
          <Row //Nome da congragação
            className="mb-3"
          >
          <Form.Group className="tm" as={Col}>
          <Form.Label style={{color:"#FFF"}}>SETOR</Form.Label>
         
              <Form.Select
              style={{width:300}}
              onChange={selectSetor} 
               defaultValue="0">
                  <option  value="0">TODOS</option>
                {    
                    listSetor.map((list,index)=>(
                    <option key={index} value={""+list.id_setor}>{list.nome_setor}</option>
                    ))
                }
              
            </Form.Select>
            </Form.Group>
            <Form.Group as={Col}>
            <Form.Label className="label-culto">DATA</Form.Label>
            <Form.Control
                style={{maxWidth:300}}
                className="f"
                type="date"
                min="2023-01-01"
                value={dates}
                onChange={handleData}
                onSelect={handleData}
              />                 
            </Form.Group>     
            </Row>  
            </div>
           
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>
    <label style={{width:"100%",textAlign:"center",fontSize:36,fontWeight:"bold"}}>TALÃO LANÇADO NA DATA</label>
     
<Table style={{backgroundColor:'ButtonShadow'}} striped bordered hover>
      <thead>
        <tr>
          <th>SETOR</th>
          <th>CONGREGAÇÃO</th>
          <th>DATA</th>
          <th>DATA REG</th>
        </tr>
      </thead>
      <tbody>
         {
         listaTalao.map((value)=>(
            <tr>
                <td>{value.nome_setor}</td>
                <td> {value.congregacao}</td>
                <td>{converter.Dates( value.data).date}</td>
                <td>{converter.Dates( value.data_reg).date}</td>
            </tr>
         ))
         }
        </tbody>
      </Table>
      
  
          
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>      
            <label style={{width:"100%",textAlign:"center",fontSize:36,fontWeight:"bold"}}>ULTIMO LANÇAMENTO DO TALÃO</label>
   <Table style={{backgroundColor:'ButtonShadow'}} striped bordered hover>
      <thead>
        <tr>
          <th>SETOR</th>
          <th>CONGREGAÇÃO</th>
          <th>DATA</th>
          <th>DATA REG</th>
        </tr>
      </thead>
      <tbody>
         {
         listPendente.map((value)=>(
            <tr>
                <td>{value.nome_setor}</td>
                <td> {value.congregacao}</td>
                <td>{converter.Dates( value.data).date}</td>
                <td>{converter.Dates( value.data_reg).date}</td>
            </tr>
         ))
         }
        </tbody>
      </Table>
      
  
          
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>

      
        </Form>
       
      </div>
    );
}
