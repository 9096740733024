import "./professor.css";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import NavBar from "../../../components/NavBar";
import { Modal } from "react-bootstrap";
import pontinho from "../../../imagens/3p.png";

function Professor() {
  const [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
  const [name, setName] = useState("VALTER JESUS RIBEIRO");
  const [cpf, setCpf] = useState("123456789-01");
  const [nasc, setNasc] = useState("");
  const [tel, setTel] = useState("19-9-99-9999");
  const [login, setLogin] = useState("123456789-01");
  const [senha, setSenha] = useState("");
  const [list, setList]=useState([{}]);
 
  function teste()
  {
    console.log("informações");
    api.postGeralRelatorioMesal({
      info:info
    }).then(({data}) => {
     
      console.log(data[0]);
      console.log(data[1]);
      console.log(data[2][0].total);
      console.log(data[3][0].valor);
    });
  }
  function listarProf()
  {
    api.postGetDizimista({
      talao: "214254",
      info:info,
    }).then(({ data }) => {
      if(data){
        setList(data);
      }
      console.log(data);
    });
  }

  const getEmployees = () => {
   
  };
  // const deleteEmployee = (id) => {
  
  //     setEmployeeList(
  //       employeeList.filter((val) => {
  //         return val.id != id;
  //       })
  //     );
   
  // };
  function salvar()
  {
    alert("Salvo com sucesso!");
  }

  function getPassword() {
    var chars = "0123456789";
    var passwordLength = 6;
    var password = "";

    for (var i = 0; i < passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    setSenha( password);
  }
  useEffect(() => {
    teste();
    listarProf();
    getPassword();

  }, []);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(true);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  return (
    <div className="App">
      <NavBar navegando="CADASTRO DE PROFESSOR"/>
      <div className="body-professor"></div>
            <div className="principal-professor">
              {
                  list.map((v,index)=>(
                  <div key={index} className="listar">
                    <label style={{width:500}}>{v.id} " - " {v.membro}</label> <img className="pontinho" src={pontinho} />
                  </div>
                ))
              }
            </div>
      <Modal //modal de pesquisa    
       show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <label style={{textAlign:"center",width:"100%"}}>CADASTRO DE PROFESSOR</label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-professor">
            <div className="principal-professor">
          <label>NOME DO PROFESSOR: </label>
          <input
            value={name}  
            type="text"
            onChange={(event) => {
              setName(event.target.value.toUpperCase());
            }}
          />
          <label>CPF: </label>
          <input
            style={{maxWidth:300}}
            value={cpf}
            type="text"
            onChange={(event) => {
              setCpf(event.target.value);
            }}
          />
          <label>DATA NASC:</label>
          <input
            style={{maxWidth:300}}
            type="date"
            onChange={(event) => {
              setNasc(event.target.value);
            }}
          />
          <label>TEL:</label>
          <input
            style={{maxWidth:300}}
            value={tel}
            type="text"
            onChange={(event) => {
              setTel(event.target.value);
            }}
          />
          <label>LOGIN:</label>
          <input
           style={{maxWidth:300}}
            value={cpf}
            type="text"
            onChange={(event) => {
              setLogin(event.target.value);
            }}
          />
            <label>SENHA:</label>
          <input
          style={{maxWidth:300}}
            value={senha}
            type="text"
            onChange={(event) => {
              setSenha(event.target.value);
            }}
          />
          <div className="btn-professor">
              <button onClick={salvar}>SALVAR</button>
            </div>
              </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Professor;
