export const nomeMes = ["", "JANEIRO", "FEVEREIRO", "MARÇO", "ABRIL", "MAIO", "JUNHO", "JULHO", "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"];
export const ListarData = () => {

    var d = new Date("2023-01-01");

    const list = [];

    console.log(d.toISOString());

    while (d < new Date()) {
        d.setMonth(d.getMonth()+1);
        var mes = d.getMonth()+1;
        mes = mes <10 ? "0"+mes : mes;
        list.push({ mesano:mes + "" + d.getFullYear(), mesnome: nomeMes[d.getMonth()+1],ano:d.getFullYear() })  
       
    }
   
    return list;

}