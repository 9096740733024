import { useEffect, useRef, useState } from "react";
import { InfoUser } from "../components/info/login";
import NavBar from "../components/NavBar";
import InputAuto from "../components/InputAuto";
import { Get, Post } from "../Api";
import converter from "../Util/Converter";
import { Button, Table } from "react-bootstrap";
import { SYS } from "../Api/env";


function Dizimista() {
  const refValorDizimo = useRef(null);
  const [faltando, set_faltando] = useState(true);
  const [ano_atual, set_ano_atual] = useState(true);
  const [nome, set_nome] = useState("");
  const [nomes, set_nomes] = useState([]);
  const [talao, set_talao] = useState({});
  const [list_talao, set_list_talao] = useState([]);
  const [list_dizimista, set_list_dizimista] = useState([]);
  const [talao_id, set_talao_id] = useState(0);
  const [valor_label, set_valor_label] = useState(0);
  const [valor_input, set_valor_input] = useState("");
  const [valor_total, set_valor_total] = useState(0);
  const [lbl_desc_talao, set_lbl_desc_talao] = useState("");
  const [lbl_desc_talao2, set_lbl_desc_talao2] = useState("");



  //input
  const getSelectedVal = value => {
    console.log("value val ",value);
    set_nome(value);
  };

  const getChanges = value => {
    console.log("value ",value);
    set_nome(value);
  };
  //-------------------------------
  //Metodos 
  const compararValor = (valor1, valor2) => {

    if (valor1 == valor2) {
      return true;
    } else if (valor1 > valor2) {
      alert("O valor é maior do que o saldo!!!")
      return false;
    } else {
      return true;
    }
  }


  const Adicionar = async () => {
    if (talao_id == 0)
      return alert("Selecione um talão!!!");
  
    if (!nome)
      return alert("Digite o nome do membro!");
    if (!valor_label)
      return alert("Digite o valor do dizimo!");


    if (compararValor((parseFloat(valor_total) + parseFloat(valor_label)), parseFloat(talao.dizimo))) {
      await Inserir();

    }
    // else
    // if (parseFloat(talao.dizimo)- parseFloat(valor_total) ==0  )
    // return alert("Selecione outro talão!!!");

  }


  const SelecionaTalao = async id => {

    set_talao_id(id);
    if (id > 0) {

      const resultado = await list_talao.find(objeto => objeto.id == id);
      set_talao(resultado);
      ListaDizimista(id);
      set_lbl_desc_talao(`Nº ${resultado?.talao} | TOTAL: ${converter.Br(resultado?.dizimo)}`);
      set_lbl_desc_talao2(`DATA: ${converter.Dates(resultado?.data).date} \n | FALTA: ${converter.Br(resultado?.falta)}`);
    }
    else
      set_lbl_desc_talao("");

  }

  const DigiteValor = (e) => {
    let valor = e;
    set_valor_input(valor)
    valor = String(valor).replace('.', '')
    valor = String(valor).replace(',', '.')

    if (valor > 0) set_valor_label(parseFloat(valor));
    else set_valor_label(0);

  }
  //----------------------------------
  // backend
  const Inserir = async () => {
    const { data } = await Post('/tesouraria/dizimista', {

      talao_id: talao.id,
      membro_id: 0,
      setor: SYS.Membro.id_setor,
      talao: talao.talao,
      congregacao: SYS.Membro.id_congregacao,
      membro: nome,
      dizimo: valor_label,
      oferta: 0,
      data: talao.data,
      mesano: talao.mesano,
      admin: SYS.Membro.token
    });



    if (data.sucess) {
      alert(`
            O dizimista foi adicionado com sucesso!!!
            ${nome} Valor: ${converter.Br(valor_label)}
      `);
      set_nome("");
      set_valor_input("");
      set_valor_label(0);

      await ListaTalao(faltando, ano_atual);
      await SelecionaTalao(talao_id);



    }


  }

  const NomesDizimista = async () => {
    const { data } = await Get(`/tesouraria/dizimista/nomes?congregacao=${SYS.Membro.id_congregacao}`);

    if (data?.sucess) {
      console.log("Nomes ", data.data)
      const n = [];
      data?.data.map((value) => n.push(value.membro));
      set_nomes(n);
    }

  }
  const ListaDizimista = async (id) => {
    const { data } = await Get(`/tesouraria/dizimista?congregacao=${SYS.Membro.id_congregacao}&talao_id=${id}`);

    if (data?.sucess) {
      set_list_dizimista(data?.data);
      const registros = data?.data;
      const suma = registros.reduce((acumulador, registro) => acumulador + parseFloat(registro?.dizimo), 0);
      set_valor_total(suma)

    }

  }
  const ListaTalao = async (falta, atual) => {

    const { data } = await Get(`/tesouraria/dizimista/talao?congregacao=${SYS.Membro.id_congregacao}&faltando=${falta}&atual=${atual}`);
    console.log(data);
    if (data?.sucess) {
      set_list_talao(data?.data);
    }

  }

  useEffect(() => {
    ListaTalao(true, true);
    NomesDizimista();
  }, []);

  return (
    <>
      <NavBar />
      <InfoUser />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", color: "white", paddingLeft: 20, gap: 10 }}>
          <label>
            <input checked={faltando} onClick={() => { set_faltando(!faltando); ListaTalao(!faltando, ano_atual) }} type="checkbox" />
            FALTANDO
          </label>
          <label>
            <input checked={ano_atual} onClick={() => { set_ano_atual(!ano_atual); ListaTalao(faltando, !ano_atual) }} type="checkbox" />
            ANO ATUAL
          </label>
        </div>
        <label style={{ marginLeft: 20, fontSize: 20, fontWeight: "bold", color: "white" }}>TALÃO</label>
        <select
          style={{ height: 50, marginLeft: 20, marginRight: 20, fontSize: 16, fontWeight: "bold", color: "black" }}
          value={talao_id}
          onChange={(e) => SelecionaTalao(e.target.value)}
        >
          <option value={0}></option>
          {
            list_talao.map((value) =>
            (<option value={value.id}>
              N° {value.talao} | FALTA {converter.Br(value.falta)} | {converter.Dates(value.data).date}</option>)
            )
          }
        </select>
        <label
          style={{ color: "white", paddingTop: 10, paddingLeft: 20, fontSize: 20 }}
        >{lbl_desc_talao}</label>

      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: 10, padding: 20 }}>
        <label style={{ fontSize: 24, fontWeight: "bold", color: "white" }}>Nome do dizimista</label>
        <InputAuto
          value={nome}
          data={nomes}
          onSelected={getSelectedVal}
          onChange={getChanges}
        />
        <label style={{ fontSize: 24, fontWeight: "bold", color: "white" }} >Digite o valor </label>
        <div style={{ display: "flex", flexDirection: "column" }}>

          <input value={valor_input} style={{ height: 50, width: 180 }} placeholder="R$" onChange={(e) => DigiteValor(e.target.value)} />{" "}
          <label style={{ fontSize: 24, fontWeight: "bold", color: "white" }} >{converter.Br(valor_label)} </label>

        </div>
        <Button onClick={() => Adicionar()}>Adicionar</Button>
      </div>

      <div >
        <label style={{ width: "100%", fontSize: 24, fontWeight: "bold", color: "white", textAlign: "right", paddingRight: 20 }}>TOTAL {converter.Br(valor_total)}</label>
      </div>
      <div style={{ padding: 20 }}>
        <Table variant="light" striped>
          <thead>
            <tr>
              <th>
                NOME
              </th>
              <th>
                VALOR
              </th>
            </tr>
          </thead>
          <tbody>
            {
              list_dizimista.map((value) => (
                <tr>
                  <td>{value?.membro}</td>
                  <td>{converter.Br(value?.dizimo)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default Dizimista;
