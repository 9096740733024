import React, { useState } from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import NavBar from '../components/nav';



const Calendario = () => {

  const [events, setEvents] = useState([]);

  const [value] = useState(new Date());

  const onChange = (e) => {

  //  const title = window.prompt('Informe o título do evento:');
  //   if (title) {
  //     const newEvent = {
  //       title,
  //       start,
  //       end,
  //     };
  //     setEvents([...events, newEvent]);
  //   }
    console.log("enventos ",events);
  };

  return (
    <div className="div-calendario-princial">
        <NavBar navegando={"AGENDA"} />
      <h1 style={{marginTop:10}}>Agenda de Evento</h1>
      <Calendar 
        className="div-calendario-princial"
       
        events={events}
        startAccessor="start"
        endAccessor="end"
       
        selectable
       
        onChange={(e)=>onChange(e)} 
        value={value}
      />
    </div>
  );
};

export default Calendario;
