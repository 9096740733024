import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import NavBar from "../../components/NavBar";
import "./despesa.css";
import api from "../../services/api";
import { useState, useEffect } from "react";
import "../style.css";
import ListarDespesa from "../../components/Listar/ListarDespesa";
import converter from "../../Util/Converter";

function Despesa() { 
  function ConverterReal(valor) {
    var t = valor.replace(",","");
    var resposta="";
    for (var i = t.length - 1; i >= 0; i--) {
      var j = t.length - i;

      // Adiciona o caractere na resposta.
      var resposta = t.charAt(i) + resposta;

      // Colocar uma vírgula ou um ponto se for o caso.
      if (j == 2) {
          resposta = "," + resposta;
      } else if (j % 3 == 2 && i != 0) {
          resposta = "." + resposta;
      }
    }
    return resposta;
  }
  function ToReal(valor) {
    return String(parseFloat(valor).toFixed(2)).replace(".", ",");
  }

  function ToFloat(valor) {
    return parseFloat(valor.replace(".", "").replace(",", ".")).toFixed(2);
  }
  const getDespesa=()=>
  {
      api.postGetDespesa({
        setor:id_setor,
        congregacao:id_congregacao,
        mesano:mesano,
        info:info
      })
      .then(({ data }) => {
        if(data){ 
          setList(data);
         
        }
      });

  }
  const getDespesaSoma=()=>
  {
      api.postGetDespesaSoma({
        setor:id_setor,
        congregacao:id_congregacao,
        mesano:mesano,
        info:info
      })
      .then(({ data }) => {
        if(data[0].total !== null){ 
  
        setTotal(ToReal(data[0].total));
         
        }
      });

  }
  const getDespesaMesAno=(mesAno)=>
  {
      api.postGetDespesa({
        setor:id_setor,
        congregacao:id_congregacao,
        mesano:mesAno,
        info:info
      })
      .then(({ data }) => {
        if(data){ 
          setList(data);
         
        }
      });

  }
  const getDespesaSomaMesAno=(mesAno)=>
  {
      api.postGetDespesaSoma({
        setor:id_setor,
        congregacao:id_congregacao,
        mesano:mesAno,
        info:info
      })
      .then(({ data }) => {
        if(data[0].total !== null){ 

        setTotal(ToReal(data[0].total));
         
        }
      });

  }
  const handleRegisterDespesa = () => {
    api.postSetDespesa({
      setor:id_setor,
      congregacao:id_congregacao,
      tipo:"0",
      descricao:descricao, 
      valor: ToFloat(valor),
      data:data,
      mesano:mesano,
      info:info
    })
    .then((response) => {
       
         alert("Despesas registrada com sucesso!");
    });
   
  }

  ////////////////////////////////////////////////////////////////////////////////
  const [info,setInfo]=useState(JSON.parse( localStorage.getItem('config'))||[]);
  const [id_setor, setIdSetor] = useState( localStorage.getItem('id_setor')||"0");
  const [id_congregacao, setIdCongregacao] = useState(localStorage.getItem('id_congregacao')||"0");
  const [id_cargo, setIdCargo] = useState( localStorage.getItem('id_cargo')||"0");
  const [id, setId] = useState(localStorage.getItem('id')||"Não Logado");
  const [setor, setSetor] = useState(localStorage.getItem('setor')||"Não Logado");
  const [congregacao, setCongregacao] = useState(localStorage.getItem('congregacao')||"Não Logado");
  const [user, setUser] = useState(localStorage.getItem('user')||"Não Logado!");
  const [geral,setGeral]=useState(localStorage.getItem('geral')||0);
  const [cargo,setCargo]=useState(localStorage.getItem('cargo')||"Não logado");
  ////////////////////////////////////////////////////////////////////////////////
  const [mesano, setMesano] = useState(localStorage.getItem('mesano')||"0");
  const [data, setData] = useState("");
  const [data_min, setData_min] = useState("");
  const [data_max, setData_max] = useState("");
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState("");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState("0");
  const [dia,setDia]=useState(0);
  const [mes,setMes]=useState(0);
  const [ano,setAno]=useState(0);
  const [isData,setIsData]=useState("1");
  const [dataString,setDataString]=useState("1");
  


  //////////////////////////////////////////////////////////////////////////////
  const addDespesa=()=>{
   if( descricao==="")
   {
    alert("O campo descrição da conta está vazio!");
        return;
   }
   if(ToReal(valor)==="NaN")
   {
    alert("O valor está incorreto!")
        return;
   }
   if( valor==="" || ToReal( valor) ==="0,00")
   {
    alert("O campo valor da despesa está vazio ou igual a 0!")
        return;
   }
   if( isData=="0")
   {
    alert("Não é possivel fazer lançamento neste mês entre em contato com os administradores!")
    return;
   }

    Somar();
  
    setList([
      ...list,
      {
        id: id,
        setor: id_setor,
        congregacao: id_congregacao,
        tipo: "0",
        descricao: descricao,
        valor:ToFloat(valor),
        data:data,
        mesano: mesano,
        data_reg: "",
        data_alt: "",
        admin: "",
        total:""
      },
    ]);
    handleRegisterDespesa();

    setDescricao("");
    setValor("");

}
const Somar=()=>
{   

       setTotal(String(
          parseFloat(
              parseFloat(String(total).replace('.',',').replace(',','.')) +
              parseFloat(String(valor===""?0: valor).replace('.','').replace(',','.'))
              ).toFixed(2)
           ).replace('.',',')
       );
      
}


  
  const getData=()=>
  {
      api.postGetDate({info}).then(({ data }) => {
        if(data){
         let date= converter.Date( data);
          setData(date.data);
          setDia(date.dia);
          setMes(date.mes);
          setAno(date.ano);
          setMesano(date.mes+""+date.ano);
          getDespesaMesAno(date.mes+""+date.ano);
          getDespesaSomaMesAno(date.mes+""+date.ano);
          
          setData_max(date.data);

          const dt = new Date(date.data)
          dt.setDate(dt.getDate() - 3);
          if (info.geral == 3 || info.geral == 2)
            setData_min("2023-05-01");
          else
            setData_min(converter.Dates(dt.toISOString()).data);
        }
      });
  }


  useEffect(() => {
    getData();
    
    
    }, []);

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  function handleData(e) {
    let date =  converter.Dates(e.target.value);
    setData(date.data);
    setTotal("0,00");
    setMesano( date.mes+""+date.ano);

    //  if(mes==date.mes && ano==date.ano)
    //    setIsData("1")
    //   else
    //   setIsData("0")

      getDespesaMesAno(date.mes+""+date.ano);
      getDespesaSomaMesAno(date.mes+""+date.ano);
   
  }
  function handleLogin(e) {
    e.preventDefault();
  }
//////////////////////////////////////////////////////////////////////////////
  return (
    <div style={{backgroundColor:"#000"}} className="container-culto">
      <NavBar navegando="DESPESAS" />
      
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
      <Form className="container-form" onSubmit={handleLogin}>
      <div //Cabeçario
        style={{
            display:"flex",flexDirection:"column",borderRadius:15,backgroundColor:"#fff",
            width:"100%",marginLeft:10,color:"#000"
            }}>
      <label>DATA DO SISTEMA: {dia}/{mes}/{ano}</label>
      <label>SETOR: {info.nome_setor} </label>
      <label>CONGREGAÇÃO: {info.congregacao} </label>
      <label>{info.nome_cargo}: {info.nome} </label>
      </div>
      <hr // Linha de separação
        style={{ color: "yellow", fontWeight: "bold" }}
      ></hr>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label className="label-culto">Data</Form.Label>
            <Form.Control
              style={{width:210}}
              className="f"
              type="date"
              min={data_min}
              max={data_max}
              value={data}
              onChange={handleData}
            />
          </Form.Group>
        </Row>
        
        { isData==="1"? "" :
            <label style={{color:"red",backgroundColor:"#FFF",fontSize:24,fontWeight:"bold"}} >Não é possivel fazer lançamento neste mês entre em contato com os administradores</label> 
        }
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <Row //Nome da congragação
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label className="label-culto">DESCRIÇÃO</Form.Label>
            <Form.Control
              className="f"
              type="text"
              placeholder="Digite o descrição da conta"
           
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
          </Form.Group>

        </Row>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <Row // DIZIMO E OFERTA 
          className="mb-3"
        >
          <Form.Group as={Col}>
            <Form.Label  className="label-culto">VALOR R$</Form.Label>
            <Form.Control
            style={{width:210}}
              className="f"
              type="text"
              placeholder="Digite o valor da despesa"
          
              value={valor}
              onChange={(e) =>setValor(e.target.value) }
            />
          </Form.Group>
          

        </Row>
       
        <div >
          <Button
           onClick={addDespesa}
          >
            ADICIONAR
          </Button>
        </div>
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <ListarDespesa listar={list}></ListarDespesa>
        <Row // tOTAL
          className="mb-3"
        >   
            <Form.Group as={Col}>
              <Form.Label style={{fontSize:36}} className="label-culto a-f-r">TOTAL R$ {
                 converter.ConverterReal(total)
               }</Form.Label>
            </Form.Group>
       
        </Row>
      
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
      
      </Form>
     
    </div>
  );
}

export default Despesa;
