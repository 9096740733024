import React, { useEffect, useState } from "react";
import { SYS } from "../../Api/env";
import { Get, Post } from "../../Api";

// This is named export.
export const InfoUser = () => {
  const [datasys,set_datasys]=useState("");
  const GetData =async () => {
    const {data }=await Post('/data');
  set_datasys(data);
  }

  useEffect(()=>{
    GetData();
  },[])
 
  return (
    <div style={{padding:10}}>
      <div //Cabeçario
        style={{
          display: "flex", flexDirection: "column", borderRadius: 15, backgroundColor: "#fff",
          width: "100%", color: "#000", paddingLeft: 15
        }}>
        <label>DATA DO SISTEMA: {datasys}</label>
        <label>SETOR: {SYS.Membro.nome_setor} </label>
        <label>CONGREGAÇÃO: {SYS.Membro.nome_congregacao} </label>
        <label>{SYS.Membro.nome_membro} </label>
      </div>


    </div>
  )
};

