import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import NavBar from "../../components/NavBar";
import api from "../../services/api";
import { useState, useEffect, useRef } from "react";
import "../style.css";
import "./dizimista.css";
import ListarDizimista from "../../components/Listar/ListarDizimista";
import { useReactToPrint } from "react-to-print";
import ReactToPrint from 'react-to-print';
import { Link } from "react-router-dom";
import imgLogo from "../../imagens/adbelem.png";
import converter from "../../Util/Converter";
import InputAuto from "../../components/InputAuto";

function Dizimista() {
  //input auto
  const getSelectedVal = value => {
    console.log("value ",value);
    setNomeDizimista(value);

  };

  const getChanges = value => {
    console.log("value change ",value);
    setNomeDizimista(value)
  };

  function getNamesDizimista() {
    var valor = [];
    api.postGetNomesDizimista({
      info: info,
    })
      .then(({ data }) => {
        if (data) {
          data.map((value) => {
            valor.push(value.membro)
          })
          setNomesDizimista(valor);

        }
      });
  };

  const [nomesDizimista, setNomesDizimista] = useState([]);
  const componentRef = useRef();
  //print 
  const handlePrint = useReactToPrint({

    content: () => componentRef.current,
  });

  ////////////////////////////////////////////////////////////////////////////////
  const [setor, setSetor] = useState(
    localStorage.getItem("setor") || "Não Logado"
  );
  const [congregacao, setCongregacao] = useState(
    localStorage.getItem("congregacao") || "Não Logado"
  );
  const [user, setUser] = useState(
    localStorage.getItem("user") || "Não Logado!"
  );
  const [geral, setGeral] = useState(localStorage.getItem("geral") || 0);
  const [cargo, setCargo] = useState(
    localStorage.getItem("cargo") || "Não logado"
  );
  const [id_setor, setIdSetor] = useState(
    localStorage.getItem("id_setor") || "0"
  );
  const [id_congregacao, setIdCongregacao] = useState(
    localStorage.getItem("id_congregacao") || "0"
  );
  const [id_cargo, setIdCargo] = useState(
    localStorage.getItem("id_cargo") || "0"
  );
  const [id, setId] = useState(localStorage.getItem("id") || "Não Logado");
  ////////////////////////////////////////////////////////////////////////////////
  const [info, setInfo] = useState(JSON.parse(localStorage.getItem('config')) || []);
  const [data, setData] = useState("");
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState("");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState("0");
  const [dia, setDia] = useState(0);
  const [mes, setMes] = useState(0);
  const [ano, setAno] = useState(0);
  const [mesano, SetMesAno] = useState(0);
  const [isData, setIsData] = useState("1");
  const [dataString, setDataString] = useState("1");
  const [log, setLog] = useState("log");

  //////////////////////////////////////////////////////////////////////////////
  //
  /////////////////////////////////////////////////////////////////////////////
  const [valorDizimistaTalao, setValorDizimistaTalao] = useState(0);
  const [talao, setTalao] = useState(0);
  const [dizimo, setDizimo] = useState("");
  const [oferta, setOferta] = useState(0);
  const [numero, setNumero] = useState("");
  const [talao_id, set_talao_id] = useState("");
  const [listDizimista, setListDizimista] = useState([]);
  const [nomeDizimista, setNomeDizimista] = useState("");
  const [valorDizimista, setValorDizimista] = useState("");
  const [valorEntrada, setValorEntrada] = useState(0);
  const [valorTotalDizimistaString, setValorTotalDizimistaString] = useState("0");
  const [listTalao, setListTalao] = useState([]);
  //select_dizmista_soma
  const getValorDizimista = () => {
    api
      .postGetDizimistaSoma({
        talao: talao,
        setor: id_setor,
        congregacao: id_congregacao,
        info: info
      })
      .then(({ data }) => {
        if (data) {
          setValorTotalDizimistaString(data[0].total);
        }
      });
  };
  const getTalao = () => {
    api
      .postGetTalao({
        setor: id_setor,
        congregacao: id_congregacao,
        info: info
      })
      .then(({ data }) => {
        if (data) {
          setListTalao(data);
          console.log("Taloes",data)

        }
      });
  };
  const getDizmista = (talao) => {
    api.postGetDizimista({
        talao: talao,
        setor: id_setor,
        congregacao: id_congregacao,
        info: info,
        grupo: '0',


      })
      .then(({ data }) => {
        if (data.length > 0) {
          setListDizimista(data);
          getValorDizimista();
        }
      });
  };
  const addDizmista = () => {
    api
      .postSetDizimista({
        setor: id_setor,
        congregacao: id_congregacao,
        talao: talao,
        membro: nomeDizimista,
        data: data.data,
        mesano: mesano,
        dizimo: converter.ToFloat(valorDizimista),
        oferta: oferta,
        info: info,
        talao_id

      })
      .then(({ data }) => {
        if (data) {
          console.log("Dizimista adicionado com sucesso!");
        }
      });
  };
  const adicionar = () => {

    if (nomeDizimista === "") return alert("Digite o nome do dizimista!");
    if (valorDizimista.replace(",", "").replace(".", "") > 0) {
      let valorAntigo = valorTotalDizimistaString;
      let valorAtual = valorDizimista;
      let valorTalao = String(
        parseFloat(valorDizimistaTalao).toFixed(2)
      ).replace(".", ",");
      let valorNovo = String(
        parseFloat(
          parseFloat(String(valorAntigo).replace(",", ".")) +
          parseFloat(
            String(valorAtual === "" ? 0 : valorAtual)
              .replace(".", "")
              .replace(",", ".")
          )
        ).toFixed(2)
      ).replace(".", ",");
      if (
        parseInt(valorNovo.replace(",", "")) >
        parseInt(valorTalao.replace(",", ""))
      ) {

        return alert("O dizimo ultrapassa o valor total!");
      } else {
        setValorTotalDizimistaString(valorNovo);
        setListDizimista([
          ...listDizimista,
          {
            membro: nomeDizimista,
            dizimo: valorAtual === "" ? 0 : valorAtual.replace(".", ""),
          },
        ]);
        addDizmista();
        setNomeDizimista("");
        setValorDizimista("");
      }
    } else {
      return alert("Valor do dizimo incorreto!");
    }
  };

  const getData = () => {
    api.postGetDate({ info }).then(({ data }) => {
      if (data) {
        let date = converter.Date(data);
        setDia(date.dia);
        setMes(date.mes);
        setAno(date.ano);
      }
    });
  };

  useEffect(() => {
    getData();
    getTalao();
    getNamesDizimista();
  }, []);

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }


  function handleLogin(e) {
    e.preventDefault();
  }
  function setNumeroTalao(e) {
    let v = e.target.value;

    if (v !== "") {
      let date = converter.Dates(listTalao[v].data);
      SetMesAno(date.mes + "" + date.ano);
      setTalao(listTalao[v].talao);
      setValorDizimistaTalao(listTalao[v].dizimo);
      setData(date);
      setValorTotalDizimistaString("0,00");
      setListDizimista([]);
      getDizmista(listTalao[v].talao);
      set_talao_id(listTalao[v].id)
    } else {
      setTalao(0);
      setValorDizimistaTalao("0,00");
      setListDizimista([]);
      setData("");
    }
  }

  const getPageMargins = () => {
    return "size:portrait";

  };
  return (

    <div style={{ backgroundColor: "#000" }} className="container-culto">
      <div>
        <NavBar navegando="DIZIMISTA DO CULTO " />
        <hr // Linha de separação
          style={{ color: "yellow", fontWeight: "bold" }}
        ></hr>
        <Form style={{ width: "95%", marginLeft: 10 }} onSubmit={handleLogin}>
          <div //Cabeçario
            style={{
              display: "flex", flexDirection: "column", borderRadius: 15, backgroundColor: "#fff",
              width: "100%", marginLeft: 10, color: "#000"
            }}>
            <label>
              DATA DO SISTEMA: {dia}/{mes}/{ano}
            </label>
            <label>SETOR: {info.nome_setor} </label>
            <label>CONGREGAÇÃO: {info.congregacao} </label>
            <label>
              {info.nome_cargo}: {info.nome}{" "}
            </label>
          </div>

          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>
          <div //Cabeçario
            style={{
              display: "flex", flexDirection: "column", borderRadius: 15,
              width: "100%", marginLeft: 10
            }}></div>
          <Row className="mb-3">
            <Form.Group className="tm" as={Col}>
              <Form.Label className="label-igreja">Nº DO TALÃO</Form.Label>
              <Form.Select
                style={{ maxWidth: 300 }}
                onChange={setNumeroTalao} //(e) => setTalao(e.target.value)}
                defaultValue="0"
              >
                <option value={""}></option>
                {listTalao.map((list, index) => (
                  list.dizimo > 0.00 &&
                  <option key={index} value={"" + index}>
                    {converter.Dates(list.data).date} | {list.talao} - R$ {converter.ConverterReal(list.dizimo)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="tm" as={Col}>
              <Form.Label className="label-igreja">N° Talão: {talao}</Form.Label>
              <Form.Label className="label-igreja"></Form.Label>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group className="tm" as={Col}>
              <Form.Label className="label-igreja">DATA: {data.date}</Form.Label>
              <Form.Label className="label-igreja"></Form.Label>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="tm" as={Col}> 
              <Form.Label className="label-igreja">
                DIZIMO R$ {converter.ConverterReal(valorDizimistaTalao)}
              </Form.Label>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="tm" as={Col}> 
              <Form.Label className="label-igreja">
                FALTA R$ { converter.SubTrair(valorDizimistaTalao,valorTotalDizimistaString)}
              </Form.Label>
            </Form.Group>
          </Row>

          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>
          <Row // Dizimista
            className="mb-3"
          >
            <Form.Group as={Col}>
              <Form.Label className="label-culto">NOME DO DIZIMISTA</Form.Label>
            </Form.Group>
            <InputAuto
              pholder="Digite o nome do dizimista"
              value={nomeDizimista}
              data={nomesDizimista}
              onSelected={getSelectedVal}
              onChange={getChanges}

            />

          </Row>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="label-culto">VALOR DO DIZIMO R$</Form.Label>
              <Form.Control
                style={{ maxWidth: 200 }}
                className="f"
                type="text"
                placeholder="Digite o valor do dizimo"
                value={valorDizimista}
                onChange={(e) => setValorDizimista(e.target.value)}
              />
            </Form.Group>
          </Row>
          <div
            style={{
              marginTop: 30,
              display: "flex",
              height: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button style={{ width: 200, height: 50 }} onClick={adicionar}>
              ADICIONAR
            </Button>
            <Button
              style={{ width: 200, height: 50 }}
              onClick={handlePrint}
            //to={'/imprimir_dizimista'}
            >
              IMPRIMIR
            </Button>
            {/* <ReactToPrint
              trigger={() => <button>IMPRIMIR</button>}
              content={() => componentRef.current}
              
          
           /> */}
          </div>

          <div
            style={{
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <ListarDizimista listar={listDizimista}></ListarDizimista>
          </div>
          <div
            style={{
              width: "100%",
              height: 60,
              display: "flex",
              flexDirection: "row-reverse",
              backgroundColor: "#1e222b",
            }}
          >
            <label style={{ fontSize: 24 }}>
              TOTAL R$ {converter.ConverterReal(valorTotalDizimistaString)}
            </label>
          </div>
          <hr // Linha de separação
            style={{ color: "yellow", fontWeight: "bold" }}
          ></hr>
        </Form>
      </div>


      <div /////////////////////////////////////////////////////////////////////////
        style={{ display: "none" }}

      >
        <style type="text/css" media="print">{"@page { size: portrait; }"}</style>
        <div style={{ margin: 0, padding: 0, width: 800, backgroundColor: "#FFF", }} ref={componentRef}

        >

          <div
            style={{
              display: "flex",
              width: "100%",
              height: 100,
              backgroundColor: "#c9c9c9",
            }}
          >
            <div
              style={{
                display: "flex",
                width: 220,
                height: 200,
                backgroundColor: "#fff",
                justifyContent: "center",
              }}
            >
              <img src={imgLogo} width="200" height="180" />
            </div>
            <div
              style={{ width: "100%", height: "100%", backgroundColor: "#FFF" }}
            >
              <div style={{ width: "100%", height: 40, backgroundColor: "#FFF" }}>

              </div>
              <div style={{ width: "100%", height: 50, backgroundColor: "#FFF" }}>
                <label
                  style={{
                    width: 500,
                    fontSize: 24,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  IGREJA EVANGÉLICA ASSEMBLÉIA DE DEUS
                </label>
              </div>
              <div style={{ width: "100%", height: 40, backgroundColor: "#FFF" }}>
                <label style={{ width: 500, textAlign: "center", fontSize: 18 }}>
                  MINISTÉRIO DE BELÉM - AMPARO - SP
                </label>
              </div>
              <div
                style={{ width: "100%", height: 70, backgroundColor: "#FFF" }}
              ></div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "#FFF",
              width: "100%",
              marginTop: 100,
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <label
              style={{
                width: "100%",
                fontSize: 20,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              DIZIMISTAS DO DIA {data.date}
            </label>
            <hr // Linha de separação
              style={{ color: "#000" }}
            ></hr>
            <div style={{ width: "100%", backgroundColor: "#FFF" }}>


              {listDizimista.map((list, index) => (
                <label key={index}
                  style={{
                    width: "100%",
                    fontSize: 18,
                    textAlign: "center",

                  }}
                >
                  {list.membro}
                </label>

              ))}

            </div>
          </div>

        </div>
      </div>

    </div>

  );
}

export default Dizimista;
